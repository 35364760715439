import { HTMLInputTypeAttribute, InputHTMLAttributes } from "react";
import { ErrorMessage } from "./ErrorMessage";
import {
  useController,
  FieldValues,
  UseControllerProps,
  Path,
  UseFormRegister,
  RegisterOptions,
} from "react-hook-form";

export type TAppRegisterSelectProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  registerOptions?: RegisterOptions<T>;
  options: {
    value: any;
    label: string;
    disabled?: boolean;
    selected?: boolean;
  }[];
  name: Path<T>;
  errorMessage?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

interface IAppInputProps<N extends string> {
  id?: string;
  label?: string;
  containerClassName?: string;
  isRequired?: boolean;
  type?: HTMLInputTypeAttribute;
  fieldName: N;
  value?: string | null;
  errorMessage?: string | null;
  placeholder?: string;
  onWheel?: React.WheelEventHandler<HTMLInputElement>;
  onChange: (fieldName: N, value: string) => void;
}

interface IAppControlInputProps {
  id?: string;
  label?: string;
  containerClassName?: string;
  type?: HTMLInputTypeAttribute;
  isRequired?: boolean;
}

interface IRegisterProps<K extends FieldValues> {
  name: Path<K>;
  register: UseFormRegister<K>;
  registerOptions?: RegisterOptions<K>;
  errorMessage?: string | null;
}

type TAppRegisterInputProps<K extends FieldValues> =
  InputHTMLAttributes<HTMLInputElement> & IRegisterProps<K>;

export const AppInput = <N extends string>(props: IAppInputProps<N>) => {
  return (
    <div className={props.containerClassName}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${props.isRequired ? "required" : ""}`}
        >
          {props.label}
        </label>
      )}
      <input
        id={props.id}
        onWheel={props.onWheel}
        name={props.fieldName}
        placeholder={props.placeholder}
        type={props.type}
        onChange={(e) => {
          props.onChange(props.fieldName, e.target.value);
        }}
        value={props.value || ""}
        className="form-control"
      />
      <ErrorMessage errorMessage={props.errorMessage} />
    </div>
  );
};

export const AppControlInput = <T extends FieldValues>(
  props: UseControllerProps<T> & IAppControlInputProps
) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    disabled: props.disabled,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  return (
    <div className={props.containerClassName}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${props.isRequired ? "required" : ""} small`}
        >
          {props.label}
        </label>
      )}
      <input
        id={props.id}
        {...field}
        type={props.type}
        className="form-control"
      />
      <ErrorMessage errorMessage={error?.message} />
    </div>
  );
};

export const AppRegisterInput = <T extends FieldValues>({
  register,
  registerOptions,
  errorMessage,
  ...inputAttributes
}: TAppRegisterInputProps<T>) => {
  return (
    <>
      <input
        className="form-control"
        {...inputAttributes}
        {...register(inputAttributes.name, registerOptions)}
      />
      <ErrorMessage errorMessage={errorMessage} />
    </>
  );
};

export const AppRegisterSelect = <T extends FieldValues>({
  register,
  registerOptions,
  options,
  name,
  onChange,
  errorMessage,
  ...selectAttributes
}: TAppRegisterSelectProps<T>) => {
  return (
    <>
      <select
        className="form-control"
        {...selectAttributes}
        {...register(name, registerOptions)}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
      >
        {options?.map((option) => (
          <option
            key={option.value}
            value={option.value.toString()}
            disabled={option.disabled}
            selected={option.selected}
          >
            {option.label}
          </option>
        ))}
      </select>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </>
  );
};

type TAppRegisterRadioProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  registerOptions?: RegisterOptions<T>;
  options: { value: any; label: string }[];
  name: Path<T>;
  errorMessage?: string | null;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const AppRegisterRadio = <T extends FieldValues>({
  register,
  registerOptions,
  options,
  value,
  name,
  errorMessage,
  ...inputAttributes
}: TAppRegisterRadioProps<T>) => {
  return (
    <div className="d-flex flex-wrap">
      {options.map((option) => (
        <div key={option.value} className="form-check me-4">
          <input
            type="radio"
            className="form-check-input"
            value={option.value?.toString()}
            checked={value === option.value}
            {...register(name, registerOptions)}
            {...inputAttributes}
          />
          <label
            className="form-check-label"
            htmlFor={`${name}_${option.value}`}
          >
            {option.label}
          </label>
        </div>
      ))}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </div>
  );
};

type TAppRegisterTextAreaProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  registerOptions?: RegisterOptions<T>;
  name: Path<T>;
  rows?: number;
  errorMessage?: string | null;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const AppRegisterTextArea = <T extends FieldValues>({
  register,
  registerOptions,
  name,
  rows = 3,
  errorMessage,
  ...inputAttributes
}: TAppRegisterTextAreaProps<T>) => {
  return (
    <div>
      <textarea
        className="form-control"
        rows={rows}
        {...register(name)}
        {...inputAttributes}
      />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
};
