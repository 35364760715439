import { z } from "zod";
import { ZodHelperService } from "./zodHelper.service";
import { EnumTourOptionTransferType } from "../types/tour.interface";

z.setErrorMap(ZodHelperService.errorMap);

export const editActivitySupplierUserZod = z.object({
  id: z.number(),
  isLoginBlocked: z.boolean(),
});

export const createActivitySupplierUserZod = z.object({
  isLoginBlocked: z.boolean(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  isAdmin: z.boolean(),
});

export type TCreateActivitySupplierUser = z.infer<
  typeof createActivitySupplierUserZod
>;

export type TEditActivitySupplierUser = z.infer<
  typeof editActivitySupplierUserZod
>;

export const SupplierPriceSchema = z.object({
  transferType: z.nativeEnum(EnumTourOptionTransferType),
  tourOptionId: z.number(),
  seasonId: z.number().int(),
  pricePerPax: z.number().optional(),
  adultPrice: z.number().optional(),
  childPrice: z.number().optional(),
  fromPax: z.number().optional(),
  toPax: z.number().optional(),
  priceSourceId: z.number(),
  remark: z.string().nullish(),
  tourId: z.number().optional(),
});

export type TSupplierPricesSchema = z.infer<typeof SupplierPriceSchema>;
