import React, { useEffect, useState } from "react";
import CeateEnquiryStyle from "./CreateEnquiryStyle.module.scss";
import { IntlTelInputWrapper } from "../../commonUi/IntlTelInputWrapper";
import { AppRegisterInput } from "../../commonUi/AppInput";
import { AppCkEditor } from "../../commonUi/AppCkEditor";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserSelect } from "../../components/common/Selects/UserSelect";
import { IUserData } from "../../activitiescommonfnb/types/User.interface";
import {
  TCreateOrEditEnquiry,
  createOrEditEnquirySchema,
} from "../../activitiescommonfnb/schemas/enquiry.schema";
import { TCity } from "../../activitiescommonfnb/schemas/master.schema";
import { CityMultiSelect } from "../../components/common/Selects/CityMultiSelect";
import { Modal } from "react-bootstrap";
import { EnumPaxType } from "../../activitiescommonfnb/schemas/booking.schema";
import { capitalize } from "lodash";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import { IEnquiry } from "../../activitiescommonfnb/types/enquiry.interface";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import { LoadingButton } from "../../commonUi/LoadingButton";

export const PaxCountPlusMinInput: React.FC<{
  paxCount: number;
  paxType: string;
  onIncrement: () => void;
  onDecrement: () => void;
}> = (props) => {
  return (
    <div className="col-md-4 col-12">
      <span className="small">{props.paxType}</span>
      <div className="d-flex">
        <button
          type="button"
          onClick={props.onDecrement}
          disabled={props.paxCount === 0}
          className=" btn-secondary w-25 border"
        >
          -
        </button>
        <div className="form-control w-50 text-center">{props.paxCount}</div>
        <button
          type="button"
          onClick={props.onIncrement}
          className=" btn-secondary w-25 border"
        >
          +
        </button>
      </div>
    </div>
  );
};

const CreateEnquiry: React.FC<{
  show: boolean;
  onClose?: () => void;
  onSave: (enquiryData: IEnquiry) => void;
  id?: number;
}> = (props) => {
  const [selectedUser, setSelectedUser] = useState<IUserData | null>(null);
  const [selectedCites, setSelectedCities] = useState<Required<TCity>[]>([]);

  const [createEnquiryLoading, setCreateEnquiryLoading] = useState(false);

  const {
    formState,
    getValues,
    setValue,
    control,
    handleSubmit,
    clearErrors,
    setError,
    register,
    reset,
  } = useForm<Partial<TCreateOrEditEnquiry>>({
    resolver: zodResolver(createOrEditEnquirySchema),
    defaultValues: {
      passengers: [
        {
          paxType: EnumPaxType.ADULT,
        },
      ],
    },
  });

  const passengerFields = useFieldArray({
    control,
    name: "passengers",
  });

  const getPaxCount = (paxType: EnumPaxType) => {
    let paxCount = getValues("passengers")?.filter((pax) => {
      return pax.paxType === paxType;
    }).length;

    return paxCount || 0;
  };

  const saveEnquiry = async (data: Partial<TCreateOrEditEnquiry>) => {
    setCreateEnquiryLoading(true);
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/enquiry/createOrEditPackageEnquiry",
      {
        enquiryData: data,
      }
    );

    if (apiRes.data.success) {
      toast.success("Enquiry created successfully.");
      props.onSave(apiRes.data.result);
    } else {
      toast.error(apiRes.data.errorMessage);
    }

    setCreateEnquiryLoading(false);
  };
  let chdIndex = 0;

  useEffect(() => {
    if (props.id) {
      setEnquiryData(props.id);
    }
  }, [props.id]);

  const setEnquiryData = async (enquiryId: number) => {
    const enquiryResponse = await getEnquiryByEnquiryId(enquiryId);
    const enquiryData: IEnquiry = enquiryResponse.data.result;
    if (enquiryResponse.data.success) {
      reset(enquiryData);
      const cities: Required<TCity>[] = [];

      enquiryData.destinations.forEach((d) => {
        if (d.city?.id) {
          cities.push({
            ...d.city,
            id: d.city.id,
          });
        }
      });

      setSelectedCities(cities);
      setSelectedUser(enquiryData.user);
      setValue("phoneCode", enquiryData.user.phoneCode);
      setValue("phoneNumber", enquiryData.user.phoneNumber);
      setValue(
        "userName",
        enquiryData.user.firstName + enquiryData.user.lastName
      );
    } else {
      toast.error(enquiryResponse.data.errorMessage || "enquiry not found");
    }
  };

  const getEnquiryByEnquiryId = async (enquiryId: number) => {
    const enquiryResponse = await activityAxios.post<IJSONResponse>(
      "/enquiry/getEnquiryByEnquiryId",
      {
        enquiryId,
      }
    );
    return enquiryResponse;
  };

  return (
    <Modal show={props.show} onHide={props.onClose} animation={false} centered>
      <div className={CeateEnquiryStyle.enquiryForm}>
        <div className="container">
          <form onSubmit={handleSubmit(saveEnquiry)}>
            <Modal.Header closeButton>
              <Modal.Title className="fs-6">Create Enquiry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <UserSelect
                label="Agent / Customer Search"
                labelClassName="small"
                containerClassName="col-12 mb-3"
                value={selectedUser || undefined}
                onChange={(user) => {
                  setValue("userId", user?.id || undefined);
                  setSelectedUser(user);

                  let userName = "";
                  if (user) {
                    if (user?.firstName) {
                      userName += user.firstName;
                    }
                    if (user?.lastName) {
                      userName += user.lastName;
                    }
                  }
                  setValue("userName", userName || undefined);
                  setValue("phoneCode", user?.phoneCode || undefined);
                  setValue("phoneNumber", user?.phoneNumber || undefined);
                }}
              />
              <div className="mb-3">
                <label className="required small">Title</label>
                <AppRegisterInput register={register} name="title" required />
              </div>

              {!selectedUser && (
                <>
                  <div className="mb-3">
                    <label className="required small">
                      Agent / Customer Name
                    </label>
                    <AppRegisterInput
                      register={register}
                      name="userName"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <IntlTelInputWrapper
                      label="Phone No."
                      isRequiredField
                      value={getValues("phoneNumber")}
                      onChange={(phoneNumber, phoneCode, isValid) => {
                        setValue("phoneNumber", phoneNumber);
                        setValue("phoneCode", phoneCode);

                        if (!isValid) {
                          setError("phoneNumber", {
                            message: "Please enter a valid phone number.",
                          });
                        } else {
                          clearErrors("phoneNumber");
                        }
                      }}
                      errorMessage={formState.errors.phoneNumber?.message}
                    />
                  </div>
                </>
              )}
              <div className="mb-3">
                <label className="required small">Lead Passenger Name</label>
                <AppRegisterInput
                  register={register}
                  name="leadPassengerName"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="destination" className="small required">
                  Select Destinations
                </label>
                <CityMultiSelect
                  id="destination"
                  onCityChange={(cities) => {
                    setSelectedCities(cities);
                    setValue(
                      "destinations",
                      cities.map((city) => {
                        return {
                          destinationId: city.id,
                        };
                      })
                    );
                  }}
                  selectedCites={selectedCites}
                />
                <ErrorMessage
                  errorMessage={formState.errors.destinations?.message}
                ></ErrorMessage>
              </div>

              <div className="row mb-3">
                {Object.values(EnumPaxType).map((paxType) => {
                  return (
                    <PaxCountPlusMinInput
                      key={paxType}
                      paxCount={getPaxCount(paxType)}
                      paxType={capitalize(paxType)}
                      onIncrement={() => {
                        passengerFields.append({
                          paxType: paxType,
                        });
                      }}
                      onDecrement={() => {
                        const passengers = getValues("passengers") || [];

                        let lastIndex = -1;

                        for (
                          let index = passengers.length - 1;
                          index >= 0;
                          index--
                        ) {
                          const pax = passengers[index];
                          if (pax.paxType === paxType) {
                            lastIndex = index;
                            break;
                          }
                        }

                        if (lastIndex) {
                          passengerFields.remove(lastIndex);
                        }
                      }}
                    />
                  );
                })}
              </div>

              <div className="row">
                {passengerFields.fields.map((pax, paxIndex) => {
                  if (pax.paxType !== EnumPaxType.CHILD) {
                    return null;
                  } else {
                    chdIndex++;
                  }

                  return (
                    <div className="col-md-6" key={pax.id}>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">
                            Chd {chdIndex}
                          </span>
                        </div>

                        <AppRegisterInput
                          type="number"
                          register={register}
                          registerOptions={{
                            valueAsNumber: true,
                          }}
                          name={`passengers.${paxIndex}.age`}
                          required
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="mb-3">
                <AppCkEditor
                  value={getValues("remark")}
                  isRequired
                  onChange={(rem) => {
                    setValue("remark", rem);
                  }}
                  label="Remark"
                  className="small"
                />
              </div>
              <div className="mb-3">
                <label className="small" htmlFor="">
                  Internal Remark
                </label>
                <AppRegisterInput register={register} name="internalRemark" />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton
                btnType="submit"
                btnClass="btn btn-primary btn-sm  px-4"
                loading={createEnquiryLoading}
                minWidth="35px"
              >
                {props.id ? "Edit" : "Save"}
              </LoadingButton>
            </Modal.Footer>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateEnquiry;
