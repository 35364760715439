import React, { useEffect, useState } from "react";
import { IDashBoardData } from "../../activitiescommonfnb/types/dashboard.interface";
import { activityAxios } from "../../axios/activityAxios";
import { toast } from "react-toastify";
import AppProgressBar from "../../commonUi/AppProgressbar";
import dashboardViewStyle from "./Dashboard.module.scss";
import { EnumEnquiryStatus } from "../../activitiescommonfnb/types/enquiry.interface";
import DashboardDateFilters from "./DashboardDateFilters";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export const Dashboard = () => {
  const [dashBoardData, setDashBoardData] = useState<
    IDashBoardData | undefined
  >(undefined);
  const navigate = useNavigate();
  const [pendingEnquiriesCount, setPendingEnquiriesCount] = useState(0);
  const [days, setDays] = useState<number>(1);
  const [staledEnquiriesLoader, setStaledEnquiriesLoader] = useState(false);

  const getModifiedEnquiryStatusLabel = (EnquiryLabel: EnumEnquiryStatus) => {
    switch (EnquiryLabel) {
      case EnumEnquiryStatus.Booked:
        return { color: "green", label: "Booked" };
      case EnumEnquiryStatus.Pending_Booking:
        return { color: "red", label: "Pending Booking" };
      case EnumEnquiryStatus.Final_Payment_Requested:
        return { color: "pink", label: "Final Payment Requested" };
      case EnumEnquiryStatus.Untouched:
        return { color: "grey", label: "Untouched" };
      case EnumEnquiryStatus.Rates_Requested:
        return { color: "yellowgreen", label: "Rates Requested" };
      case EnumEnquiryStatus.Rejected_By_Us:
        return { color: "aqua", label: "Rejected By Us" };
      case EnumEnquiryStatus.Quote_Sent_Feedback_Pending:
        return { color: "lightblue", label: "Quote Sent Feedback Pending" };
      case EnumEnquiryStatus.Rejected_Customer_Not_Interested:
        return { color: "blue", label: "Rejected Customer Not Interested" };
      case EnumEnquiryStatus.Rejected_Got_Better_Rates:
        return { color: "voilet", label: "Rejected Got Better Rates" };
      default:
        return { color: "gray", label: EnquiryLabel };
    }
  };

  const showPendingEnquiresCount = async (days: number) => {
    setStaledEnquiriesLoader(true);
    const response = await activityAxios.post(
      "/enquiry/dashboard/getStaledEnquiriesCount",
      { days: days }
    );
    if (response.data.success) {
      setPendingEnquiriesCount(response.data.result);
    } else {
      toast.error(response.data.errorMessage || "unable to get enquiry data");
    }
    setStaledEnquiriesLoader(false);
  };

  const showPendingEnquiriesData = () =>
    navigate(`/enquiries?staledEnquiriesDays=${days}`);

  const getAndSetAdminPanelEnquiryStats = async (filters?: any) => {
    const enquiryResponse = await activityAxios.post(
      "/enquiry/dashboard/getAdminEnquiryStats",
      filters
    );
    if (enquiryResponse.data.success) {
      setDashBoardData(enquiryResponse.data.result.data);
      setPendingEnquiriesCount(
        enquiryResponse.data.result.pendingEnquiriesCount
      );
    } else {
      toast.error(
        enquiryResponse.data.errorMessage || "unable to get enquiry data"
      );
    }
  };
  useEffect(() => {
    getAndSetAdminPanelEnquiryStats({
      from: moment().startOf("day").toDate(),
      to: moment().endOf("day").toDate(),
    });
  }, []);

  return (
    <div className={dashboardViewStyle.dashboard}>
      <div className="container">
        <div className="row justify-content-between align-items-end mb-3">
          <div className="col-4">
            <h5 className="text-primary fw-bold">DashBoard</h5>
          </div>
          <div className="col-4">
            <DashboardDateFilters
              onFilterChange={(filters: any) =>
                getAndSetAdminPanelEnquiryStats(filters)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            <div className={`${dashboardViewStyle.barContainer}`}>
              <i className="fa-solid fa-list-check p-2 bg-light me-3 rounded"></i>
              Total Enquiries
              <h1 className="mt-3 text-secondary">
                {dashBoardData?.totalRecords.totalEnquiries}
              </h1>
            </div>
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12 col-12">
            <div className={dashboardViewStyle.barContainer}>
              <div className="row">
                {dashBoardData?.totalRecords.enquiryStats.map(
                  (enquiryRecord, key) => (
                    <div className="col-lg-4" key={key}>
                      <span className="small">
                        {
                          getModifiedEnquiryStatusLabel(enquiryRecord.label)
                            .label
                        }{" "}
                        :{" "}
                        <span className="fw-bold">{enquiryRecord.value} </span>
                      </span>
                      <span>
                        <AppProgressBar
                          percentage={
                            (enquiryRecord.value /
                              dashBoardData?.totalRecords.totalEnquiries) *
                            100
                          }
                          color={
                            getModifiedEnquiryStatusLabel(enquiryRecord.label)
                              .color
                          }
                        />
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-9 col-sm-12 col-12">
            <div className={`${dashboardViewStyle.barContainer}`}>
              {/* <i className="fa-solid fa-list-check p-2 bg-light me-3 rounded"></i> */}
              Total Staled Enquires
              <h1 className="mt-3 text-secondary">
                {staledEnquiriesLoader ? (
                  <Spinner animation="border" />
                ) : (
                  pendingEnquiriesCount
                )}
              </h1>
              {/*  */}
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <label className="small">Staled Enquires by Days</label>
                  {pendingEnquiriesCount && (
                    <button
                      className="btn text-primary small badge"
                      onClick={() => showPendingEnquiriesData()}
                    >
                      See All{" "}
                    </button>
                  )}
                </div>
                <select
                  className="form-control small"
                  onChange={(e) => {
                    setDays(parseInt(e.target.value));
                    showPendingEnquiresCount(parseInt(e.target.value));
                  }}
                  value={days}
                >
                  <option value={1}> 1 Days </option>
                  <option value={2}>2 Days</option>
                  <option value={3}>3 Days</option>
                  <option value={5}>5 Days</option>
                  <option value={7}>7 Days</option>
                  <option value={10}>10 Days</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12 col-12">
            <h6>Staff List</h6>
            {dashBoardData?.staffWiseEnquiriesStats.map(
              (staffRecord, index) => (
                <div
                  className={`${dashboardViewStyle.staffListItem}`}
                  key={index}
                >
                  <div className={dashboardViewStyle.staffDetails}>
                    <div className="fw-bold">
                      <span className="fw-normal"> Name : </span>{" "}
                      {staffRecord.staffName
                        ? staffRecord.staffName
                        : "Unassigned"}{" "}
                      | <span className="fw-normal"> Phone : </span>{" "}
                      {staffRecord.phone}
                    </div>
                    <div>
                      {" "}
                      Total Enquiries : <b>{staffRecord.totalEnquiries}</b>
                    </div>
                  </div>

                  <div className={dashboardViewStyle.records}>
                    <div className="row">
                      {staffRecord.enquiryStats?.map((record, recordIndex) => (
                        <div key={recordIndex} className="col-lg-4">
                          <div className="small text-muted my-1">
                            <i className="fa-regular fa-circle-dot me-2 text-secondary"></i>{" "}
                            {getModifiedEnquiryStatusLabel(record.label).label}{" "}
                            : <b> {record.value}</b>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
