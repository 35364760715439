export const Ratings: React.FC<{
  rating: number;
  reviewCount: number;
}> = (props) => {
  return (
    <div className="small text-end">
      {[1, 2, 3, 4, 5].map((curRate) => {
        return (
          <span className="ms-1" key={curRate}>
            {props.rating >= curRate ? (
              <i className="fa-solid fa-star text-warning"></i>
            ) : (
              <i className="fa-solid fa-star text-muted"></i>
            )}
          </span>
        );
      })}

      <br />
      <span className="ms-2 ms-md-0">{props.reviewCount} Reviews</span>
    </div>
  );
};
