import { Collapse, Form, FormCheck, Modal, Spinner } from "react-bootstrap";
import { AppButton } from "../../../commonUi/AppButton";
import { CitySelect } from "./CitySelect";
import { Controller, useForm } from "react-hook-form";
import {
  TCreateOrEditTour,
  TTour,
  createOrEditTourZod,
} from "../../../activitiescommonfnb/schemas/master.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { AppInput } from "../../../commonUi/AppInput";
import { AppCkEditor } from "../../../commonUi/AppCkEditor";
import { useEffect, useState } from "react";
import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import {
  creatOrEditTourAdditionalFields,
  creatOrEditTourCkEditorFields,
  creatOrEditTourfields,
} from "../../../helper/tourHelper";
import { handleWheel } from "../../../helper/globalHelper";

export const CreateOrEditTourModal: React.FC<{
  id?: number;
  show: boolean;
  onSave: () => void;
  onClose: () => void;
}> = (props) => {
  const {
    watch,
    formState,
    setValue,
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
  } = useForm<Partial<TCreateOrEditTour>>({
    defaultValues: {
      isComboTour: false,
      isActive: true,
    },
    resolver: zodResolver(createOrEditTourZod),
  });
  const [loading, setLoading] = useState(false);

  const [additionalDetailsCollapse, setAdditionalDetailsCollapse] =
    useState(false);

  const tourState = watch();

  const getTourById = async (id: number) => {
    const apiRes = await activityAxios.post<IJSONResponse<TTour>>(
      "/master/getTourById",
      {
        tourId: id,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      reset(apiRes.data.result);
    }
  };

  const saveTourHandler = async (tourDetails: Partial<TCreateOrEditTour>) => {
    setLoading(true);
    if (props.id) {
      tourDetails.id = props.id;
    }
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/master/createOrEditTour",
      {
        tourData: tourDetails,
      }
    );

    if (apiRes.data.success) {
      toast.success(`Tour ${props.id ? "Edited" : "Created"} Successfully.`);
      props.onSave();
    } else {
      toast.error(
        apiRes.data.errorMessage ||
          `Unable to ${props.id ? "edit" : "create"} tour.`
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.id) {
      getTourById(props.id);
    }
  }, []);

  return (
    <Modal show={props.show} onHide={props.onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.id ? "Edit Tour" : "Add New Tour"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <label htmlFor="">Location</label>
          <CitySelect
            defaultCityId={tourState.cityId}
            onCityChange={(city) => {
              setValue("cityId", city?.id);
              setValue("countryId", city?.countryId);
              clearErrors("cityId");
            }}
            errorMessage={
              formState.errors.cityId?.message ? "Location is invalid" : ""
            }
          />

          {creatOrEditTourfields.map(({ name, label, isRequired, type }) => (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => (
                <AppInput
                  onWheel={handleWheel}
                  containerClassName="col-6 mt-2"
                  label={label}
                  fieldName={name}
                  isRequired={isRequired}
                  type={type}
                  onChange={(_, value) => {
                    onChange(type === "number" ? parseInt(value) : value);
                  }}
                  value={tourState?.[name]?.toString() || ""}
                  errorMessage={
                    formState.errors?.[name] ? `${label} is invalid` : ""
                  }
                />
              )}
            />
          ))}

          <div className="col-12 my-3 d-flex justify-content-between align-items-end">
            <div className="col-6">
              <label htmlFor="isComboTour" className="required">
                Is Combo Tour ?
              </label>
              <div className="d-flex justify-content-between">
                <div className="w-50 d-flex">
                  <FormCheck
                    checked={tourState.isComboTour}
                    type="radio"
                    name="isComboTour"
                    id="isComboTour_yes"
                    onChange={() => {
                      setValue("isComboTour", true);
                    }}
                  />
                  <label htmlFor="isComboTour_yes" className="ms-2">
                    Yes
                  </label>
                </div>
                <div className="w-50 d-flex">
                  <FormCheck
                    checked={!tourState.isComboTour}
                    type="radio"
                    name="isComboTour"
                    id="isComboTour_no"
                    onChange={() => {
                      setValue("isComboTour", false);
                    }}
                  />
                  <label htmlFor="isComboTour_no" className="ms-2">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="col-6 ms-2">
              <Form.Check
                type="checkbox"
                id="isActive"
                label="Is Active?"
                checked={tourState.isActive ? true : false}
                onChange={(e) => {
                  setValue("isActive", e.target.checked);
                }}
              />
            </div>
          </div>

          {creatOrEditTourCkEditorFields.map(({ name, label, isRequired }) => (
            <AppCkEditor
              key={name}
              className="mt-2"
              isRequired={isRequired}
              label={label}
              value={tourState[name]?.toString()}
              onChange={(value) => {
                setValue(name, value);
                !value?.trim()
                  ? setError(name, {
                      message: `${label} is required`,
                    })
                  : clearErrors(name);
              }}
              errorMessage={
                formState.errors[name]?.message ? `${label} is invalid` : ""
              }
            />
          ))}

          <AppInput
            containerClassName="col-12 mt-2"
            label="Remark"
            fieldName="remark"
            onChange={setValue}
            value={tourState.remark}
          />
          <div
            onClick={() => {
              setAdditionalDetailsCollapse((old) => !old);
            }}
            className="col-12 text-end text-primary mt-1"
            role="button"
          >
            additional details{" "}
            {additionalDetailsCollapse ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : (
              <i className="fa-solid fa-chevron-down"></i>
            )}
          </div>
          <Collapse in={additionalDetailsCollapse}>
            <div className="container-fluid">
              {creatOrEditTourAdditionalFields.map(({ name, label }) => (
                <AppCkEditor
                  key={name}
                  className="mt-2"
                  label={label}
                  value={tourState?.[name]?.toString()}
                  onChange={(value) => setValue(name, value)}
                />
              ))}
            </div>
          </Collapse>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AppButton onClick={props.onClose} variant="secondary">
          Close
        </AppButton>
        <AppButton onClick={handleSubmit(saveTourHandler)}>
          {" "}
          {loading ? <Spinner animation="border" size="sm" /> : "Save"}
        </AppButton>
      </Modal.Footer>
    </Modal>
  );
};
