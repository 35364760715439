import React from "react";

const Price: React.FC<{
  amount: number | undefined;
  currency: string;
  className?: string;
}> = (props) => {
  return (
    <div className={`${props.className} ms-1`}>
      {props.currency} {props.amount && Math.ceil(props.amount)}
    </div>
  );
};

export default Price;
