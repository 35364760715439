import { Form, Modal, Spinner } from "react-bootstrap";
import { AppButton } from "../../../../commonUi/AppButton";
import { Controller, useForm } from "react-hook-form";
import {
  TTourOption,
  tourOptionZod,
} from "../../../../activitiescommonfnb/schemas/tour.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { activityAxios } from "../../../../axios/activityAxios";
import { IJSONResponse } from "../../../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { AppControlInput, AppInput } from "../../../../commonUi/AppInput";
import { AppCkEditor } from "../../../../commonUi/AppCkEditor";
import {
  convertToTimeStamp,
  convertToTimeString,
  creatOrEditTourOptionsCheckBoxes,
  creatOrEditTourOptionsCkEditorFields,
  creatOrEditTourOptionsFormFields,
  defaultFormValues,
  weekDays,
} from "../../../../helper/tourHelper";

export const CreateOrEditTourOption: React.FC<{
  id?: number;
  tourId: number;
  show: boolean;
  onSave: () => void;
  onClose: () => void;
  updateTourOptionData: (data: TTourOption) => void;
}> = (props) => {
  const { watch, setValue, handleSubmit, control, reset, formState } = useForm({
    resolver: zodResolver(tourOptionZod),
    defaultValues: defaultFormValues,
  });

  const [loading, setLoading] = useState(false);
  const tourOptionState = watch();

  const getTourOptionById = async (id: number) => {
    const apiRes = await activityAxios.post<IJSONResponse<TTourOption>>(
      "/master/tour/getTourOptionByTourOptionId",
      {
        tourOptionId: id,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      reset(apiRes.data.result);
    }
  };

  const saveTourOptionHandler = async (
    tourOptionDetails: Partial<TTourOption>
  ) => {
    setLoading(true);
    if (props.id) {
      tourOptionDetails.id = props.id;
    }

    const apiRes = await activityAxios.post<IJSONResponse<TTourOption>>(
      "/master/tour/createOrEditTourOption",
      {
        tourOptionDetails: tourOptionDetails,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      toast.success(
        `Tour Option ${props.id ? "Edited" : "Created"} Successfully.`
      );
      props.updateTourOptionData(apiRes.data.result);
    } else {
      toast.error(
        apiRes.data.errorMessage ||
          `Unable to ${props.id ? "edit" : "create"} tour option.`
      );
    }
    props.onSave();
    setLoading(false);
  };

  useEffect(() => {
    if (props.id) {
      getTourOptionById(props.id);
    }
    setValue("tourId", props.tourId);
  }, []);

  return (
    <Modal centered size="lg" show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.id ? "Edit " : "Add New "} Tour Option</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <div>Week Days Availability: </div>
          <div className="d-flex">
            {weekDays.map((singleDay) => {
              const isChecked = tourOptionState[singleDay.day];
              return (
                <span
                  key={singleDay.day}
                  className={`border mx-1 rounded-pill flex-grow-1 text-center p-2 ${
                    isChecked ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => {
                    setValue(singleDay.day, !isChecked);
                  }}
                  role="button"
                >
                  {singleDay.short}
                </span>
              );
            })}
          </div>
        </div>

        <div className="row">
          {creatOrEditTourOptionsFormFields.map(
            ({ name, label, type, isRequired }) => (
              <Controller
                key={name}
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                  <AppInput
                    label={label}
                    type="string"
                    fieldName={name}
                    containerClassName={`${
                      name === "tourOptionName" ? "col-12" : "col-6"
                    } mt-2`}
                    isRequired={isRequired}
                    onChange={(_, value) => {
                      onChange(type === "number" ? parseInt(value) : value);
                    }}
                    value={value ? value.toString() : ""}
                    errorMessage={
                      formState.errors[name]?.message
                        ? `${label} is invalid`
                        : ""
                    }
                  />
                )}
              />
            )
          )}

          <Controller
            control={control}
            name="startTime"
            render={({ field: { onChange, value } }) => (
              <div className="d-flex align-items-center col-6 mt-2">
                <AppInput
                  label="Start Time"
                  type="time"
                  isRequired={true}
                  fieldName="startTime"
                  containerClassName="flex-grow-1"
                  onChange={(_, value) => {
                    const startTime = convertToTimeStamp(value);
                    onChange(startTime);
                  }}
                  value={value ? convertToTimeString(value) : ""}
                  errorMessage={
                    formState.errors.startTime?.message
                      ? "Start time is invalid"
                      : ""
                  }
                />
                {value && (
                  <button
                    type="button"
                    className="btn-close ms-2"
                    aria-label="Clear"
                    onClick={() => onChange(null)}
                  />
                )}
              </div>
            )}
          />

          <div className="col-12 mt-3">Tour Option</div>
          {creatOrEditTourOptionsCheckBoxes.map(({ id, label }) => (
            <div className="col-4" key={id}>
              <Form.Check
                type="checkbox"
                id={id}
                label={label}
                checked={tourOptionState[id] ? true : false}
                onChange={(e) => {
                  setValue(id, e.target.checked);
                }}
              />
            </div>
          ))}

          {creatOrEditTourOptionsCkEditorFields.map(({ name, label }) => (
            <AppCkEditor
              key={name}
              className="mt-2"
              label={label}
              value={tourOptionState[name]?.toString()}
              onChange={(value) => {
                setValue(name, value);
              }}
            />
          ))}

          <AppControlInput
            label="Remark"
            control={control}
            name="remark"
            containerClassName="col-12 mt-2"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AppButton onClick={props.onClose} variant="secondary">
          Close
        </AppButton>

        <button
          onClick={handleSubmit(saveTourOptionHandler)}
          className="btn btn-primary"
        >
          {loading ? <Spinner animation="border" size="sm" /> : "Save"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
