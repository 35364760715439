import moment from "moment";
import { ITourOptionWithTransfer } from "../activitiescommonfnb/types/activitySearch.interface";
import { IBookingTourOption } from "../activitiescommonfnb/types/booking.interface";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";
import {
  IOwnInventoryTourOptionTransferWithPrice,
  TGetSingleTourOptionWithTranferProps,
  TTourOptionWithTranferProps,
  TTranformTourOptionToBookingTourOptionProps,
} from "../activitiescommonfnb/types/tour.interface";
import { activityAxios } from "../axios/activityAxios";

export class TourOptionHelper {
  static tranformTourOptionToBookingTourOption = (
    props: TTranformTourOptionToBookingTourOptionProps
  ): IBookingTourOption => {
    return {
      tourId: props.tourOption.tourId,
      tourOptionId: props.tourOption.id,
      tourOption: props.tourOption,
      tourName: props.tourName,
      adultCount: props.searchDetails.paxInfo.adult,
      childCount: props.searchDetails.paxInfo.child,
      infantCount: props.searchDetails.paxInfo.infant,
      adultPrice: props.transfer.adultPrice,
      childPrice: props.transfer.childPrice,
      infantPrice: props.transfer.infantPrice,
      totalTransferPrice: props.transfer.totalTransferPrice,
      durationInMinutes: props.tourOption.durationInMinutes,
      startTime: props.tourOption.startTime,
      pickUpCoordinates: {
        x: 0,
        y: 0,
      },
      pickupLocation: "",
      buffer: props.tourOption.buffer,
      timeSlotId: props.timeSlotId,
      transferId: props.transfer.id,
      transferType: props.transfer.transferType,
      travelDate: moment(props.travelDate).format("YYYY-MM-DD"),
      uniqueResultId: props.transfer.uniqueResultId,
      countryId: props.tourOption.countryId,
      tourImages: props.tourImages,
    };
  };

  static getSingleTourOptionWithTransfer = async (
    props: TGetSingleTourOptionWithTranferProps
  ) => {
    const singleTourOptionPriceRes = await activityAxios.post<
      IJSONResponse<ITourOptionWithTransfer>
    >("/search/getSingleTourOptionPrice", {
      tourId: props.tourId,
      tourOptionId: props.tourOptionId,
      citySlug: props.citySlug,
      travelDate: props.travelDate,
      noOfAdult: props.adultCount,
      noOfChild: props.childCount,
      noOfInfant: props.infantCount,
    });

    return singleTourOptionPriceRes.data.result;
  };

  static getOwnInventoryTourOptionsPrice = async (
    bookngTourOptions: TTourOptionWithTranferProps[]
  ) => {
    const tourOptionsPriceRes = await activityAxios.post<
      IJSONResponse<IOwnInventoryTourOptionTransferWithPrice[]>
    >("/search/getOwnInventoryTourOptionsPrice", {
      bookngTourOptions,
    });

    return tourOptionsPriceRes.data.result;
  };
}
