import React from "react";
import { ITermsAndConditions } from "../../activitiescommonfnb/types/quotation.interface";

const QuotationTandC: React.FC<{
  termsAndConditions: ITermsAndConditions[] | undefined;
}> = (props) => {
  return (
    <div>
      <h4 className="mt-4 fw-bold">Terms And Conditions</h4>
      {props.termsAndConditions?.map((item, index) => (
        <div key={index}>
          <h6 className="fw-bold">{item.name} : </h6>
          <div
            dangerouslySetInnerHTML={{ __html: item.termsAndConditions }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default QuotationTandC;
