import { useEffect, useState } from "react";
import { TCity } from "../../../activitiescommonfnb/schemas/master.schema";
import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { CreateOrEditTourModal } from "./CreateOrEditTour";
import { SingleTourListItem } from "./SingleTourListItem";
import { CitySelect } from "./CitySelect";
import { EnumUser } from "../../../types/tourSearch.interface";
import { ITour } from "../../../activitiescommonfnb/types/tour.interface";
import { TourLoadingSkeleton } from "../../../helper/tourHelper";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";

const TourList: React.FC = () => {
  const [selectedCity, setSelectedCity] = useState<Required<TCity>>();
  const [tours, setTours] = useState<ITour[]>([]);
  const [loadingTours, setLoadingTours] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [results, setResults] = useState<ITour[]>([]);

  const getTours = async (slug: string) => {
    setTours([]);
    setLoadingTours(true);
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/master/getToursByCitySlug",
      {
        citySlug: slug,
      }
    );
    if (apiRes.data.success && apiRes.data.result) {
      setTours(apiRes.data.result);
    }
    setLoadingTours(false);
  };

  const filterResults = debounce(async (searchTerm: string) => {
    const filteredTour = tours.filter((obj) =>
      obj.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setResults(filteredTour);
  }, 1000);

  useEffect(() => {
    if (selectedCity) {
      getTours(selectedCity.slug);
    } else {
      setTours([]);
    }
  }, [selectedCity]);

  return (
    <div className="container-fluid px-0">
      <div className="row align-items-end justify-content-between py-4 px-3 bg-light">
        <div className="col-lg-6 ">
          <h5>Tours</h5>
        </div>
        <div className="col-lg-6 ">
          <div className="row align-items-end">
            <div className="col-lg-9">
              <CitySelect
                onCityChange={(city) => {
                  setSelectedCity(city);
                }}
              />
            </div>
            <div className="col-lg-3">
              <button
                className="btn btn-primary w-100"
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                + Tour
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 px-3">
        {selectedCity?.name && (
          <div className="mb-3">
            <span className="text-muted">{selectedCity?.name}</span>
            <span>
              <i className="fa-solid fa-angle-right mx-2 text-muted"></i>
            </span>
            <span className="fs-5">Tour List</span>
          </div>
        )}

        {tours.length > 0 && (
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              placeholder="Search Activity"
              className="my-4"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                filterResults(e.target.value);
              }}
            />
            <button
              className="btn btn-danger mx-3"
              onClick={() => setSearchQuery("")}
            >
              <i className="fa-solid fa-xmark mx-4"></i>
            </button>
          </div>
        )}

        {(searchQuery.length > 0 ? results : tours)
          .sort((a, b) => b.priority - a.priority)
          .map((singleTour, index) => {
            return (
              <SingleTourListItem
                userType={EnumUser.ADMIN}
                getTours={() => {
                  if (selectedCity) {
                    getTours(selectedCity.slug);
                  }
                }}
                key={singleTour.id}
                tourDetails={singleTour}
              />
            );
          })}

        {loadingTours && [...Array(4)].map(() => <TourLoadingSkeleton />)}
      </div>

      {showCreateModal && (
        <CreateOrEditTourModal
          show={showCreateModal}
          onClose={() => {
            setShowCreateModal(false);
          }}
          onSave={() => {
            setShowCreateModal(false);
            if (selectedCity) {
              getTours(selectedCity.slug);
            }
          }}
        />
      )}
    </div>
  );
};

export default TourList;
