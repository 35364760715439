import { useEffect, useState } from "react";
import { AppSelect } from "../../../commonUi/AppSelect";
import { TCity } from "../../../activitiescommonfnb/schemas/master.schema";
import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { capitaliseStr } from "../../../helper/globalHelper";
import { ErrorMessage } from "../../../commonUi/ErrorMessage";

export const CitySelect: React.FC<{
  className?: string;
  defaultCityId?: number;
  defaultCitySlug?: string;
  errorMessage?: string;
  onCityChange: (city?: Required<TCity>) => void;
  showOnlyHomepageCities?: boolean;
}> = (props) => {
  const [cities, setCities] = useState<Required<TCity>[]>([]);
  const [selectedCity, setSelectedCity] = useState<Required<TCity>>();

  const getAllCities = async () => {
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/search/getAllCities",
      {
        showOnlyHomepageCities: props.showOnlyHomepageCities,
      }
    );
    if (apiRes.data.success && apiRes.data.result) {
      setCities(apiRes.data.result);
    }
  };

  useEffect(() => {
    getAllCities();
  }, []);

  useEffect(() => {
    if (props.defaultCityId && !selectedCity) {
      setSelectedCity(cities.find((c) => c.id === props.defaultCityId));
    }

    if (props.defaultCitySlug && !selectedCity) {
      setSelectedCity(cities.find((c) => c.slug === props.defaultCitySlug));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultCityId, cities, props.defaultCitySlug]);

  return (
    <>
      <AppSelect
        containerClassName={props.className}
        value={
          selectedCity
            ? {
                label: capitaliseStr(selectedCity.name),
                value: selectedCity.id,
                city: selectedCity,
              }
            : undefined
        }
        onChange={(data) => {
          let selectedCity: Required<TCity> | undefined = undefined;

          if (data) {
            selectedCity = data.city;
          }

          setSelectedCity(selectedCity);

          props.onCityChange(selectedCity);
        }}
        options={cities.map((city) => {
          return {
            label: capitaliseStr(city.name),
            value: city.id,
            city: city,
          };
        })}
      />
      <ErrorMessage errorMessage={props.errorMessage} />
    </>
  );
};
