import moment from "moment";
import { useEffect, useState } from "react";
import { EnumEnquiryStatus } from "../activitiescommonfnb/types/enquiry.interface";
import { IEnquiryFollowUp } from "../activitiescommonfnb/schemas/enquiry.schema";
import _ from "lodash";

export const rejectionStatuses = [
  EnumEnquiryStatus.Rejected_Got_Better_Rates,
  EnumEnquiryStatus.Rejected_Customer_Not_Interested,
  EnumEnquiryStatus.Rejected_By_Us,
];

export const statusColor = (key: EnumEnquiryStatus) => {
  if (key === EnumEnquiryStatus.Rates_Requested) {
    return "alert alert-warning";
  } else if (key === EnumEnquiryStatus.Untouched) {
    return "alert alert-secondary";
  } else if (key === EnumEnquiryStatus.Booked) {
    return "alert alert-success";
  } else if (key === EnumEnquiryStatus.Final_Payment_Requested) {
    return "alert alert-danger";
  } else if (key === EnumEnquiryStatus.Pending_Booking) {
    return "alert alert-primary";
  } else if (key === EnumEnquiryStatus.Rejected_Customer_Not_Interested) {
    return "alert alert-info";
  } else if (key === EnumEnquiryStatus.Rejected_Got_Better_Rates) {
    return "alert alert-danger";
  } else if (key === EnumEnquiryStatus.Quote_Sent_Feedback_Pending) {
    return "alert alert-warning";
  } else if (key === EnumEnquiryStatus.Rejected_By_Us) {
    return "alert alert-danger";
  }
};

export const calculateTimeSinceStaled = (updatedAt: Date) => {
  const now = moment();
  const duration = moment.duration(now.diff(moment(updatedAt)));
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${days} days, ${hours} hours, ${minutes} minutes`;
};

export const getLastUpdatedFollowup = (
  enquiryFollowUps: IEnquiryFollowUp[]
) => {
  return _.maxBy(
    enquiryFollowUps,
    (obj) => obj.updatedAt && new Date(obj.updatedAt).getTime()
  );
};

export const staledDays = [0, 1, 2, 3, 5, 7, 10, 30];

export const days = [1, 2, 3, 4, 5, 6, 7];

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const filterKeys = [
  "status",
  "searchId",
  "searchTitle",
  "leadPassenger",
  "assignedToStaffId",
  "searchByUserId",
  "datefilter",
  "staledEnquiriesDays",
  "unassigned",
];

export const debounceFilterState = (
  filterState: any,
  keys: string[],
  delay: number
) => {
  const debouncedValues: { [key: string]: any } = {};
  keys.forEach((key) => {
    debouncedValues[key] = useDebounce(filterState[key], delay);
  });
  return debouncedValues;
};
