import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, Tab, Tabs } from "react-bootstrap";
import EnquiryListItem from "./EnquiryListItem";
import { IEnquiry } from "../../activitiescommonfnb/types/enquiry.interface";
import { activityAxios } from "../../axios/activityAxios";
import { toast } from "react-toastify";
import {
  IQuotationTabs,
  IQuotationVersion,
  quotationDataSchema,
  TQuotationDataZod,
  IQuotationPreview,
  ITermsAndConditions,
} from "../../activitiescommonfnb/types/quotation.interface";
import QuotationForm from "./QuotationForm";
import QuotationPreview from "../../components/Quotations/QuotationPreview";
import ReactToPrint from "react-to-print";
import { ZodHelperService } from "../../activitiescommonfnb/schemas/zodHelper.service";
import { Path } from "react-hook-form";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import _ from "lodash";
import FormSkeletonLoader from "../../commonUi/FormSkeletonLoader";
import { formatDate } from "../../functions/commonFx";
import { getQuotationDataTemplate } from "../../helper/quotationHelper";
import { THotelZod } from "../../activitiescommonfnb/schemas/hotel.schema";

const Quotation: React.FC<{
  selectedEnquiry: IEnquiry;
  showQuotationOffCanvas: boolean;
  isCustomerView?: boolean;
  setShowQuotationOffCanvas: (k: boolean) => void;
}> = (props) => {
  const [enquiryQuotationVersionList, setEnquiryQuotationVersionList] =
    useState<IQuotationVersion[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>("new");
  const [previewClicked, setPreviewClicked] = useState<boolean>(
    props.isCustomerView ? true : false
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [quotationPreview, setQuotationPreview] =
    useState<IQuotationPreview | null>(null);
  const [quotationTabs, setQuotationTabs] = useState<IQuotationTabs[]>([]);
  const emptyQuotationError:
    | { [key in Path<TQuotationDataZod>]: string }
    | null = null;
  const [quotationError, setQuotationError] = useState(emptyQuotationError);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const quotationDataTemplate = getQuotationDataTemplate(
    props.selectedEnquiry.id!
  );
  const cityIds = _.map(props.selectedEnquiry.destinations, "city.id");
  const [hotelData, setHotelData] = useState<THotelZod[]>();
  const [quotationData, setQuotationData] = useState<
    typeof quotationDataTemplate
  >(quotationDataTemplate);

  const saveQuotation = async (quotationData: Partial<TQuotationDataZod>) => {
    setLoading(true);

    const validatedData = quotationDataSchema.safeParse({
      ...quotationData,
      HTMLData: quotationPreview?.previewBody,
    });

    if (!validatedData.success) {
      const formattedError: any = ZodHelperService.formatZodErrors(
        validatedData.error,
        "Error"
      );
      setQuotationError(formattedError);
      toast.error("Invalid Data");
      setLoading(false);
      return;
    }
    const apiRes = await activityAxios.post(
      "/quotation/saveAndUpdateQuotation",
      validatedData.data
    );
    if (apiRes.data.success) {
      getQuotationTabs(quotationData.enquiryId!);

      setQuotationData(apiRes.data.result);
      setSelectedTab(apiRes.data.result?.id?.toString());
      await getQuotationVersions(quotationData.id!);
      toast.success("Quotation saved successfully");
    } else {
      toast.error("Failed to save Quotation");
    }
    setQuotationError(null);
    setLoading(false);
  };

  const getQuotationById = async (id: number) => {
    setLoading(true);
    try {
      const apiRes = await activityAxios.post("/search/getQuotationById", {
        quotationId: id,
      });
      const data = apiRes.data.result;
      if (data) {
        setQuotationData(data);
        getQuotationVersions(id);
      }
    } catch (error) {
      toast.error("Failed to fetch quotations");
    }
    setLoading(false);
  };

  const getQuotationTabs = async (enquiryId: number) => {
    const apiRes = await activityAxios.post("/search/getQuotationTabs", {
      enquiryId: enquiryId,
    });
    if (apiRes.data.success) {
      setQuotationTabs(apiRes.data.result);
      const length = apiRes.data.result?.length;
      if (length >= 1) {
        handleTabSelect(apiRes.data.result[length - 1].id.toString());
      } else {
        handleTabSelect("new");
      }
    }
  };

  const getQuotationVersions = async (id: number) => {
    try {
      const apiRes = await activityAxios.post("/search/getQuotationVersions", {
        quotationId: id,
      });

      setEnquiryQuotationVersionList(apiRes.data.result);
    } catch (error) {
      toast.error("Failed to fetch quotation versions");
    }
  };

  const getTermsAndConditions = async () => {
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/master/getTermsAndConditonsByCityIds",
      { cityIds }
    );
    if (apiRes.data.success) {
      const filteredTermsAndConditions = apiRes.data.result.filter(
        (item: ITermsAndConditions) => item.termsAndConditions !== ""
      );
      setQuotationPreview({
        ...quotationPreview,
        termsAndConditions: filteredTermsAndConditions,
        cartItems: "",
      });
    }
  };

  const getHotelData = async (cityIds: number[]) => {
    const apiRes = await activityAxios.post<IJSONResponse<THotelZod[]>>(
      "/hotel/getHotelByCityIds",
      cityIds
    );
    if (apiRes.data.success && apiRes.data.result) {
      setHotelData(apiRes.data.result);
    } else {
      toast.error("Failed to fetch hotel data");
    }
  };

  const handleTabSelect = (key: string) => {
    setQuotationError(null);
    if (key === "new") {
      setEnquiryQuotationVersionList([]);
      setQuotationData(quotationDataTemplate);
    } else if (key) {
      getQuotationById(parseInt(key));
    }
    setSelectedTab(key);
  };

  const handleVersionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVersion = enquiryQuotationVersionList?.find(
      (version) => version.id === parseInt(e.target.value)
    );

    setPreviewClicked(true);
    setQuotationPreview({
      ...quotationPreview,
      previewBody: selectedVersion?.HTMLData,
      cartItems: selectedVersion?.cartItems || "",
      quotationData: selectedVersion?.JsonData,
      versionId: selectedVersion?.version,
      staff: selectedVersion?.staff,
    });
  };

  const handleHideOffCanvas = () => {
    props.setShowQuotationOffCanvas(false);
    setQuotationError(null);
    setPreviewClicked(props.isCustomerView ? true : false);
    if (!props.isCustomerView) {
      setQuotationData(quotationDataTemplate);
      setSelectedTab("new");
    }
  };

  useEffect(() => {
    if (props.selectedEnquiry.id) {
      getQuotationTabs(props.selectedEnquiry.id);
      setQuotationData(quotationDataTemplate);
      getTermsAndConditions();
      getHotelData(cityIds);
    }
  }, [props.selectedEnquiry.id]);

  useEffect(() => {
    setQuotationPreview((old) => {
      return {
        ...old,
        previewBody: quotationData?.quotationBody || "",
        versionId: quotationData?.version,
        quotationData: quotationData,
        staff: quotationData?.staff,
        cartItems: quotationData?.cartItems || "",
      };
    });
  }, [quotationData]);

  useEffect(() => {
    if (props.isCustomerView)
      handleTabSelect(quotationTabs[quotationTabs.length - 1]?.id.toString());
  }, [quotationTabs]);

  return (
    <div className="overflow-hidden">
      <Offcanvas
        scroll
        show={props.showQuotationOffCanvas}
        onHide={handleHideOffCanvas}
        placement={"end"}
        id="pk-quotation-Offcanvas"
      >
        <Offcanvas.Header
          className="py-4 px-4 border-bottom shadow-sm"
          closeButton
        >
          <Offcanvas.Title className="w-100">
            {previewClicked ? (
              <div className="d-flex justify-content-between">
                <div>
                  <span> Quotation Preview </span>
                </div>
                <div>
                  {!props.isCustomerView && (
                    <button
                      className="btn btn-sm btn-primary px-4"
                      onClick={() => {
                        setPreviewClicked(false);
                        setQuotationPreview({
                          ...quotationPreview,
                          previewBody: quotationData.quotationBody || "",
                          cartItems: quotationData.cartItems || "",
                        });
                      }}
                    >
                      Back
                    </button>
                  )}
                  <ReactToPrint
                    trigger={() => (
                      <button className="btn btn-sm btn-success px-4 mx-3">
                        {" "}
                        <i className="fa-solid fa-download"></i> Download
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between">
                <div>
                  <span> Quotation </span>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-primary px-4"
                    onClick={() => setPreviewClicked(true)}
                  >
                    {" "}
                    Preview{" "}
                  </button>
                  <button
                    className="btn btn-sm btn-success px-4 mx-3"
                    onClick={() => saveQuotation(quotationData)}
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-4">
          {!previewClicked ? (
            <>
              <h6 className="fw-bold">Enquiry Details</h6>
              <EnquiryListItem
                key={props.selectedEnquiry.id}
                enquiryDetail={props.selectedEnquiry}
                setEnquiry={() => {}}
                getEnquiries={() => {}}
                toggleQuotationOffCanvas={() => {}}
                setSelectedEnquiry={() => {}}
                showActionButtons={false}
              />
              <Tabs
                id="quotation-tabs"
                activeKey={selectedTab}
                onSelect={(key) => handleTabSelect(key || "new")}
                className=" small"
              >
                {quotationTabs &&
                  quotationTabs.map((quotation) => (
                    <Tab
                      className="border border-top-0 p-3"
                      key={quotation.id}
                      eventKey={quotation.id}
                      title={
                        <span className="text-muted fw-bold px-4">
                          {quotation.title}
                        </span>
                      }
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="m-0 fw-bold">Quotation Details</h6>
                        <div className="d-flex">
                          <select
                            className="form-select object-fit-contain small"
                            onChange={handleVersionSelect}
                            defaultValue={""}
                          >
                            <option disabled value={""}>
                              {" "}
                              View Previous Versions{" "}
                            </option>
                            {enquiryQuotationVersionList &&
                              enquiryQuotationVersionList.map((version) => (
                                <option key={version.id} value={version.id}>
                                  {" "}
                                  Version {version.version}
                                  {" - "}
                                  {version.staff?.firstName} {" - "}
                                  {formatDate(version.createdAt)}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      {loading ? (
                        <FormSkeletonLoader />
                      ) : (
                        <QuotationForm
                          setQuotationData={setQuotationData}
                          quotationData={quotationData}
                          quotationError={quotationError}
                          hotelData={hotelData}
                        />
                      )}
                    </Tab>
                  ))}
                <Tab
                  eventKey="new"
                  title={
                    <span className="fw-bold text-secondary">
                      + New Quotation
                    </span>
                  }
                  className="border border-top-0 p-3"
                >
                  <h6 className="m-0 fw-bold">Quotation Details</h6>
                  <QuotationForm
                    hotelData={hotelData}
                    setQuotationData={setQuotationData}
                    quotationData={quotationData}
                    quotationError={quotationError}
                  />
                </Tab>
              </Tabs>
            </>
          ) : (
            <QuotationPreview
              ref={componentRef}
              hotelData={hotelData}
              quotationPreview={quotationPreview}
              enquiryId={props.selectedEnquiry.id}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Quotation;
