import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import activityAdminScreenStyle from "./activityAdminScreenStyle.module.scss";
import { Link, Outlet, useLocation } from "react-router-dom";

enum activityMasterTabEnums {
  Countries = "countries",
  Cities = "cities",
  Seasons = "seasons",
  Tours = "tours",
  Settings = "settings",
  DestinationGroup = "destinationGroup",
  Hotel = "hotel",
}

const MasterTab: React.FC = () => {
  const { pathname } = useLocation();

  const getDefaultActiveKey = () => {
    let defaultActiveKey = activityMasterTabEnums.Countries;

    if (pathname.includes(activityMasterTabEnums.Cities)) {
      defaultActiveKey = activityMasterTabEnums.Cities;
    }

    if (pathname.includes(activityMasterTabEnums.Seasons)) {
      defaultActiveKey = activityMasterTabEnums.Seasons;
    }

    if (pathname.includes(activityMasterTabEnums.Tours)) {
      defaultActiveKey = activityMasterTabEnums.Tours;
    }

    if (pathname.includes(activityMasterTabEnums.Settings)) {
      defaultActiveKey = activityMasterTabEnums.Settings;
    }

    if (pathname.includes(activityMasterTabEnums.DestinationGroup)) {
      defaultActiveKey = activityMasterTabEnums.DestinationGroup;
    }

    if (pathname.includes(activityMasterTabEnums.Hotel)) {
      defaultActiveKey = activityMasterTabEnums.Hotel;
    }

    return defaultActiveKey;
  };

  return (
    <div id="activity-admin-screen">
      <div className="container-fluid ps-0">
        <Tab.Container
          id="activitiesAdminMainTab"
          defaultActiveKey={getDefaultActiveKey()}
        >
          <div className="row">
            <div className="col-lg-2 pe-0">
              <div className={activityAdminScreenStyle.adminTab}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to=""
                      eventKey={activityMasterTabEnums.Countries}
                    >
                      Countries
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={activityMasterTabEnums.Cities}
                      eventKey={activityMasterTabEnums.Cities}
                    >
                      Cities
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={activityMasterTabEnums.Seasons}
                      eventKey={activityMasterTabEnums.Seasons}
                    >
                      Seasons
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={activityMasterTabEnums.Tours}
                      eventKey={activityMasterTabEnums.Tours}
                    >
                      Tours
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={activityMasterTabEnums.DestinationGroup}
                      eventKey={activityMasterTabEnums.DestinationGroup}
                    >
                      Destination Group
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={activityMasterTabEnums.Hotel}
                      eventKey={activityMasterTabEnums.Hotel}
                    >
                      Hotel
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={activityMasterTabEnums.Settings}
                      eventKey={activityMasterTabEnums.Settings}
                    >
                      Settings
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div className="col-lg-10">
              <div id="inside-tab">
                <Outlet />
              </div>
            </div>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default MasterTab;
