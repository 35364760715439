import React from "react";
import aboutUsStyle from "./AboutUsStyle.module.scss";
import why1 from "../../assets/images/aboutUs/why/why1.png";
import why2 from "../../assets/images/aboutUs/why/why2.png";
import why3 from "../../assets/images/aboutUs/why/why3.png";
import why4 from "../../assets/images/aboutUs/why/why4.png";
import flight from "../../assets/images/aboutUs/modules/flight.png";
import visa from "../../assets/images/aboutUs/modules/visa.png";
import tourPackage from "../../assets/images/aboutUs/modules/tour.png";
import hotel from "../../assets/images/aboutUs/modules/hotel.png";
import insurance from "../../assets/images/aboutUs/modules/insurance.png";
import "swiper/css";
import "swiper/css/pagination";

const AboutUs = ({ center, zoom }: any) => {
  const modulesIconsImage = [
    // { icon: flight, moduleName: "Flight Booking" },
    { icon: visa, moduleName: "Tourist Visa" },
    { icon: tourPackage, moduleName: "Tour Packages" },
    { icon: hotel, moduleName: "Hotel Booking" },
    { icon: insurance, moduleName: "Travel Insurance" },
  ];

  return (
    <>
      <div className={aboutUsStyle.aboutUs}>
        <div className={aboutUsStyle.officeImages}>
          <div className={aboutUsStyle.overlayGradient}>
            <div className="container d-flex justify-content-center">
              {/* <h5 className="fw-bold text-white">About Us</h5> */}

              <div className={aboutUsStyle.aboutHeading}>
                <h1>Welcome</h1>
                <h5 className="">Let's Make Your Best Trip Ever.</h5>
              </div>
            </div>
          </div>
        </div>

        <div className={aboutUsStyle.description}>
          <div className="container">
            <h4 className="fw-bold text-primary mb-3">About Us</h4>
            <p>
              At PackageKart.com, we harness over 20 years of expertise to
              deliver comprehensive tour packages, including essential
              components like attraction tickets and seamless transfer services,
              exclusively for B2B travel agents. With a focus on select
              destinations, we provide a meticulously crafted selection of
              services, allowing you to customize every aspect of your clients'
              travel experiences. Our commitment to transparency and reliability
              ensures that you receive the highest level of service, supported
              by a well-trained team available 24x7. Trust PackageKart.com to be
              your dependable partner, offering professionalism and excellence
              at every step of the journey.
            </p>
          </div>
        </div>

        <div className={aboutUsStyle.modules}>
          <div className="container">
            {/* <h5 className="fw-bold">Deals In</h5> */}
            <div className="row justify-content-evenly">
              {modulesIconsImage.map((moduleIcon) => {
                return (
                  <>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-12">
                      <div className={aboutUsStyle.moduleItem}>
                        <div>
                          <img
                            className={aboutUsStyle.icon}
                            src={moduleIcon.icon}
                            alt="maya travels"
                          />
                        </div>
                        <p className="m-0">{moduleIcon.moduleName}</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className={aboutUsStyle.sectionDivider}></div>

        <div className={aboutUsStyle.points}>
          <div className="container">
            <h4 className="fw-bold text-primary mb-5">Your Tour Your Way</h4>
            <div className="row">
              <div className="col-lg-3">
                <div className={aboutUsStyle.point}>
                  <img
                    className={`img-fluid ${aboutUsStyle.whyImg}`}
                    src={why1}
                    alt="Enjoy culture your way"
                  />
                  <h6>100% CUSTOMISATION</h6>
                  <p className="small">
                    Choose your own tours, transfers & activities with your own
                    choice of hotels and flights.
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className={aboutUsStyle.point}>
                  <img
                    className={`img-fluid ${aboutUsStyle.whyImg}`}
                    src={why2}
                    alt="Enjoy culture your way"
                  />
                  <h6>100% AUTOMATION</h6>
                  <p className="small">
                    From bookings to Payments. Everything is handled online.
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className={aboutUsStyle.point}>
                  <img
                    className={`img-fluid ${aboutUsStyle.whyImg}`}
                    src={why3}
                    alt="Enjoy culture your way"
                  />
                  <h6>QUALITY IS PRIORITY</h6>
                  <p className="small">
                    Selected and Tested transporters to serve our customer with
                    the best quality of service.
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className={aboutUsStyle.point}>
                  <img
                    className={`img-fluid ${aboutUsStyle.whyImg}`}
                    src={why4}
                    alt="Enjoy culture your way"
                  />
                  <h6>24 X 7 SUPPORT</h6>
                  <p className="small">
                    Dedicated support team, happy to help you 24 x 7.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
