import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import App from "./App";
import { persistStore } from "redux-persist";
import ReactDOM from "react-dom/client";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { MainLoader } from "./commonUi/MainLoader";
import { CountrySettings } from "./components/admin/Countries/CountrySettings";
import MasterTab from "./components/admin/MasterTab";
import TourList from "./components/admin/Tours/TourList";
import CitiesList from "./components/admin/Cities/CitiesList";
import SettingsList from "./components/admin/Settings/SettingsList";
import SeasonsListItem from "./components/admin/Seasons/SeasonsListItem";
import { GuestRoutes } from "./routes/guards/GuestRoutes";
import { SupplierLogin } from "./components/supplier/SupplierLogin";
import { SupplierDashboard } from "./components/supplier/SupplierDashboard";
import { CreateOrBlockSuppliers } from "./components/supplier/CreateOrBlockSuppliers";
import { GeneratePassword } from "./components/GeneratePassword";
import ActivitySinglePage from "./screens/ActivitySinglePage/ActivitySinglePage";
import ActivitySearchPage from "./screens/ActivitySearchPage/ActivitySearchPage";
import { SupplierTourOptionList } from "./components/supplier/SupplierTourOptionList";
import { ManagePrice } from "./components/supplier/ManagePrice";
import { TourResultPage } from "./screens/TourResultPage/TourResultPage";
import { QuotationsPage } from "./screens/QuotationPage/QuotationsPage";
import Login from "./screens/loginSignup/Login";
import EnquiryList from "./screens/Enquiry/EnquiryList";
import DubaiPage from "./screens/TourLandingPages/DubaiPage";
import { ManageStaff } from "./components/admin/ManageStaff";
import { ManageTasks } from "./screens/ManageTask/ManageTasks";
import { SupplierRoute } from "./routes/guards/SupplierRoute";
import { StaffRoute } from "./routes/guards/StaffRoute";
import { AdminRoute } from "./routes/guards/AdminRoute";
import AboutUs from "./screens/AboutPackageKart/AboutUs";
import { Dashboard } from "./screens/Dashboard/Dashboard";
import CheckoutPage from "./screens/CheckoutPage/CheckoutPage";
import { TourBookingsQueue } from "./screens/TourBookingQueue/TourBookingsQueue";
import DestinationGroup from "./components/admin/DestinationGroup/DestinationGroup";
import CartDetails from "./screens/CartDetails/CartDetails";
import CreateQuotation from "./screens/CartQuotation/CreateQuotation";
import SignUp from "./screens/SignUp/SignUp";
import CustomerCart from "./screens/CustomerCart/CustomerCart";
import { ProtectedRoute } from "./routes/guards/ProtectedRoutes";
import PaymentAndRefundPolicy from "./screens/AboutPackageKart/PaymentAndRefundPolicy";
import TermandConditions from "./screens/AboutPackageKart/TermandConditions";
import Hotels from "./components/admin/Hotels/Hotels";

const ErrorPage = React.lazy(() => import("./screens/ErrorPage/ErrorPage"));
const NotFoundPage = React.lazy(
  () => import("./screens/PageNotFound/NotFoundPage")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        children: [
          {
            index: true,
            element: <ActivitySearchPage />,
          },
        ],
      },
      {
        path: "/login",
        element: (
          <GuestRoutes>
            <Login />
          </GuestRoutes>
        ),
      },
      {
        path: "/signUp",
        element: (
          <GuestRoutes>
            <SignUp />
          </GuestRoutes>
        ),
      },
      {
        path: "enquiries",
        children: [
          {
            index: true,
            element: (
              <StaffRoute>
                <EnquiryList />,
              </StaffRoute>
            ),
          },
          {
            path: "dashboard",
            element: (
              <StaffRoute>
                <Dashboard />,
              </StaffRoute>
            ),
          },
        ],
      },
      {
        path: "/admin",
        children: [
          {
            path: "master",
            element: (
              <AdminRoute>
                <MasterTab />,
              </AdminRoute>
            ),

            children: [
              {
                index: true,
                element: <CountrySettings />,
              },
              {
                path: "cities",
                element: <CitiesList />,
              },
              {
                path: "seasons",
                element: <SeasonsListItem />,
              },
              {
                path: "tours",
                element: <TourList />,
              },
              {
                path: "settings",
                element: <SettingsList />,
              },
              {
                path: "destinationGroup",
                element: <DestinationGroup />,
              },
              {
                path: "hotel",
                element: <Hotels />,
              },
            ],
          },
          {
            path: "manageStaff",
            element: (
              <AdminRoute>
                <ManageStaff />,
              </AdminRoute>
            ),
          },
        ],
      },
      {
        path: "/checkout",
        children: [
          {
            path: ":sessionSlug",
            element: (
              <StaffRoute>
                <CheckoutPage />
              </StaffRoute>
            ),
          },
          {
            path: "cartDetails",
            element: (
              <ProtectedRoute>
                <CartDetails />
              </ProtectedRoute>
            ),
          },
        ],
      },

      {
        path: "/supplier",
        children: [
          {
            path: "login",
            element: (
              <AdminRoute>
                <SupplierLogin />
              </AdminRoute>
            ),
          },
          {
            path: "dashboard",
            element: (
              <SupplierRoute>
                <SupplierDashboard />
              </SupplierRoute>
            ),
          },
          {
            path: "createorblocksupplier",
            element: (
              <AdminRoute>
                <CreateOrBlockSuppliers />
              </AdminRoute>
            ),
          },
          {
            path: "manageprice",
            children: [
              {
                index: true,
                element: (
                  <StaffRoute>
                    <SupplierTourOptionList />
                  </StaffRoute>
                ),
              },
              {
                path: ":tourId",
                element: (
                  <ProtectedRoute>
                    <ManagePrice />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "cart",
            element: (
              <ProtectedRoute>
                <CustomerCart />,
              </ProtectedRoute>
            ),
          },
          {
            path: "generatepassword",
            element: (
              <GuestRoutes>
                <GeneratePassword />,
              </GuestRoutes>
            ),
          },
        ],
      },

      {
        path: "/search/:citySlug",
        children: [
          {
            index: true,
            element: <TourResultPage />,
          },
          {
            path: ":activitySlug",
            element: <ActivitySinglePage />,
          },
        ],
      },
      {
        path: "/quotations",
        element: (
          <StaffRoute>
            <QuotationsPage />
          </StaffRoute>
        ),
      },
      {
        path: "/createQuotation",
        element: (
          <StaffRoute>
            <CreateQuotation />
          </StaffRoute>
        ),
      },
      {
        path: "/tourbookingsqueue",
        element: (
          <StaffRoute>
            <TourBookingsQueue />
          </StaffRoute>
        ),
      },
      {
        path: "manageTasks",
        element: (
          <StaffRoute>
            <ManageTasks />,
          </StaffRoute>
        ),
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },

      {
        path: "/tour",
        children: [
          {
            path: "dubai",
            element: <DubaiPage />,
          },
        ],
      },

      {
        path: "/aboutUs",
        children: [
          {
            index: true,
            element: <AboutUs />,
          },
        ],
      },

      {
        path: "/terms_and_conditions",
        children: [
          {
            index: true,
            element: <TermandConditions />,
          },
        ],
      },

      {
        path: "/payment&refundpolicy",
        children: [
          {
            index: true,
            element: <PaymentAndRefundPolicy />,
          },
        ],
      },
    ],
  },
]);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <Suspense fallback={<MainLoader loading />}>
        <RouterProvider router={router} />
      </Suspense>
    </Provider>
  </PersistGate>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
