import {
  ITourOptionTransferWithPrice,
  ITourOptionWithTransfer,
} from "./activitySearch.interface";
import { IUserData } from "./User.interface";

export enum EnumTourOptionTransferType {
  WITHOUT_TRANSFER = "WITHOUT_TRANSFER",
  PRIVATE = "PRIVATE",
  SHARING = "SHARING",
}

export interface ITour {
  id: number;
  name: string;
  countryId: number;
  cityId: number;
  slug: string;
  pickupPoint: string;
  dropPoint: string | null;
  reportingTime: string | null;
  childAge: string;
  infantAge: string;
  childCancellationPolicyDescription: string | null;
  cancellationPolicyDescription: string;
  faqDetails: string | null;
  usefullInformation: string | null;
  importantInformation: string | null;
  whatsInThisTour: string | null;
  itenararyDescription: string | null;
  termsAndConditions: string | null;
  toursAdvantage: string | null;
  tourInclusion: string | null;
  tourDescription: string;
  tourShortDescription: string;
  onlyChild: boolean | null;
  startTime: number | null;
  isComboTour: boolean;
  rating: number;
  priority: number;
  createdByStaffDetail: IUserData;
  reviewCount: number;
  tourImages?: ITourImage[];
  tourOptions?: ITourOption[];
}

export interface ITourOption {
  id: number;
  tourOptionName: string;
  tourOptionDescription: string;
  tourId: number;
  childAge: string;
  infantAge: string;
  cancellationPolicyDescription: string;
  childPolicyDescription: string;
  durationInMinutes: number;
  duration: string;
  timezone: string;
  hasTourGuide: boolean;
  buffer: number;
  startTime: number;
  hasSlot: boolean;
  isHourly: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  countryId: number;
  remark?: string;
  isActive: boolean;
  createdByStaffDetail: IUserData;
  isTicketOnlySaleAllowed: boolean;
  transfers?: ITourOptionTransfer[];
}

export interface ITourOptionTransfer {
  id: number;
  tourOptionId: number;
  transferType: EnumTourOptionTransferType;
  transferTime: string;
  transferDurationInMinutes: number;
  disableChild: boolean;
  disableInfant: boolean;
  twoWayTransfer: boolean;
}

export interface ITourImage {
  id: number;
  tourId: number;
  fileUrl: string;
  createdAt: Date;
  updatedAt: Date;
}

export type TGetTourOptionWithTranferProps = {
  tourId: number;
  citySlug: string;
  travelDate: string;
  adultCount: number;
  childCount: number;
  infantCount: number;
  countryId: number;
};

export type TGetSingleTourOptionWithTranferProps =
  TGetTourOptionWithTranferProps & {
    tourOptionId: number;
  };

export type TTranformTourOptionToBookingTourOptionProps = {
  tourOption: ITourOption;
  transfer: ITourOptionTransferWithPrice;
  timeSlotId: string | null;
  travelDate: string;
  tourName: string;
  searchDetails: ISearchDetails;
  tourImages?: ITourImage[];
};

export type TTourOptionWithTranferProps = {
  tourId: number;
  citySlug: string;
  travelDate: string;
  adultCount: number;
  childCount: number;
  infantCount: number;
  countryId: number;
  tourOptionId: number;
  transferType: EnumTourOptionTransferType;
  uniqueResultId: string;
};

export interface IOwnInventoryTourOptionTransferWithPrice {
  // tourId: number;
  adultPrice: number;
  childPrice: number;
  infantPrice: number;
  uniqueResultId: string;
  totalTransferPrice: number;
  tourOptionId: number;
  transferType: EnumTourOptionTransferType;
}

export interface IPriceSource {
  id: number;
  nickName: string;
}

export interface ITourOptionsList {
  tourOption: ITourOptionWithTransfer;
  transferType: EnumTourOptionTransferType;
  tourDate: string;
  totalAmount: number;
  isCombo: boolean;
}

export interface ISearchDetails {
  paxInfo: {
    adult: number;
    child: number;
    infant: number;
  };
  fromDate: string;
  toDate: string;
}
