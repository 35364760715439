import moment from "moment";
import {
  IBookingTourOption,
  ICartItem,
} from "../activitiescommonfnb/types/booking.interface";

export const isTourOptionOverlapping = (
  tourOption: IBookingTourOption,
  cartItems: ICartItem[]
): boolean => {
  const tourOptionsArray =
    cartItems?.flatMap((cartItem) => cartItem.tourOptions) || [];

  for (const option of tourOptionsArray) {
    if (
      option?.startTime != null &&
      option.durationInMinutes != null &&
      option.buffer != null
    ) {
      const existingStartTime = moment(option?.startTime, "HHmm")
        .subtract(option?.buffer, "minutes")
        .toDate();

      const existingEndTime = moment(option?.startTime, "HHmm")
        .add(option?.buffer + option.durationInMinutes, "minutes")
        .toDate();

      const convertedStartTime = moment(tourOption.startTime, "HHmm")
        .subtract(tourOption?.buffer, "minutes")
        .toDate();

      if (
        (convertedStartTime > existingStartTime ||
          convertedStartTime < existingEndTime) &&
        option.travelDate === tourOption.travelDate
      ) {
        return true;
      }
    }
  }

  return false;
};
