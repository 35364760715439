import { useParams } from "react-router";
import TourResultStyle from "./TourResultPageStyle.module.scss";
import { useEffect, useState } from "react";
import { TourResultItem } from "../../components/Tour/TourResultItem";
import { CartWrapper } from "../../components/cart/CartWrapper";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ActivitySearch } from "../../components/common/Search/ActivitySearch";
import { useAppDispatch, useAppSelector } from "../../store";
import { getTourResults } from "../../slice/tourResult.slice";
import { EnumSessionType } from "../../activitiescommonfnb/schemas/booking.schema";
import { ISessionWithCartDetail } from "../../activitiescommonfnb/types/booking.interface";
import { MainLoader } from "../../commonUi/MainLoader";
import { ITourWithPrice } from "../../activitiescommonfnb/types/activitySearch.interface";
import _ from "lodash";
import "rc-slider/assets/index.css";
import moment from "moment";
import { Collapse, Offcanvas } from "react-bootstrap";
import FilterResultPage from "../../components/Tour/FilterResultPage";
import { getCityByCitySlug } from "../../functions/commonFx";
import { ActivitySessionActions } from "../../slice/session.slice";
import { TourResultLoadingSkeleton } from "../../helper/tourHelper";
import { TourOptionHelper } from "../../functions/TourOptionHelper";
import { TTourOptionWithTranferProps } from "../../activitiescommonfnb/types/tour.interface";
import { toast } from "react-toastify";
import CartOffcanvas from "./CartOffcanvas";
import ModifyCanvas from "./ModifyCanvas";

export const TourResultPage: React.FC<{}> = () => {
  const [searchParams] = useSearchParams();
  const fromDate = searchParams.get("from");
  const toDate = searchParams.get("to");
  const { citySlug } = useParams();
  const adult = parseInt(searchParams.get("adult") || "1");
  const child = parseInt(searchParams.get("child") || "0");
  const infant = parseInt(searchParams.get("infant") || "0");
  const resume = searchParams.get("resume") === "true";
  const activeSessionDetails = useAppSelector(
    (state) => state.activitySession.activeSession
  );
  const [openCartCollapse, setOpenCartCollapse] = useState(true);
  const navigate = useNavigate();
  const [showMobileCanvasCart, setShowMobileCanvasCart] = useState(false);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [openSortBy, setOpenSortBy] = useState(false);
  const [sortBy, setSortby] = useState<"amount" | "name" | undefined>(
    undefined
  );
  const [sortPriceInAscendingOrder, setSortPriceInAscendingOrder] =
    useState(true);
  const { data: tourSearchResults, isSearching } = useAppSelector(
    (state) => state.tourResult
  );
  const [tourResultsLocalState, setTourResultsLocalState] = useState<
    ITourWithPrice[]
  >([]);
  const [searchStr, setSearchStr] = useState("");
  const [cityName, setCityName] = useState("");
  const dispatch = useAppDispatch();
  const [expandedTourId, setExpandedTourId] = useState<number | null>(null);

  const saveCartDetails = async (from: string, to: string) => {
    const cartItems = activeSessionDetails?.cartItems.length
      ? activeSessionDetails.cartItems
      : [];

    if (!citySlug) {
      navigate("/");
      toast.error("City not found");
      return;
    }

    const filteredTourOptionsArray: TTourOptionWithTranferProps[] = cartItems
      .flatMap((item) => item.tourOptions)
      .map((data) => ({
        tourOptionId: data.tourOptionId!,
        tourId: data.tourId,
        travelDate: data.travelDate,
        adultCount: data.adultCount,
        childCount: data.childCount,
        infantCount: data.infantCount,
        countryId: data.countryId,
        transferType: data.transferType,
        uniqueResultId: data.uniqueResultId,
        citySlug: citySlug,
      }));

    const tourDetails = await TourOptionHelper.getOwnInventoryTourOptionsPrice(
      filteredTourOptionsArray
    );

    let updatedCartItems = cartItems.map((cartItem) => ({
      ...cartItem,
      tourOptions: cartItem.tourOptions.map((tourOption) => {
        const updatedOption = tourDetails?.find(
          (option) => option.tourOptionId === tourOption.tourOptionId
        );
        return updatedOption
          ? {
              ...tourOption,
              adultPrice: updatedOption.adultPrice,
              childPrice: updatedOption.childPrice,
              infantPrice: updatedOption.infantPrice,
              totalTransferPrice: updatedOption.totalTransferPrice,
            }
          : tourOption;
      }),
    }));

    const activeSessionData: ISessionWithCartDetail = {
      adultCount: adult,
      childCount: child,
      infantCount: infant,
      from: from,
      to: to,
      type: EnumSessionType.CART,
      cartItems: updatedCartItems,
    };
    dispatch(ActivitySessionActions.setActiveSessionData(activeSessionData));
  };

  const sortTourResults = () => {
    if (!tourResultsLocalState || !sortBy) return;
    const toursArr = _.cloneDeep(tourResultsLocalState);

    toursArr.sort((a, b) => {
      if (a[sortBy] > b[sortBy]) {
        return sortPriceInAscendingOrder ? 1 : -1;
      }
      if (a[sortBy] < b[sortBy]) {
        return sortPriceInAscendingOrder ? -1 : 1;
      }
      return 0;
    });

    setTourResultsLocalState(toursArr);
  };

  const getCityName = async () => {
    if (citySlug) {
      const apiRes = await getCityByCitySlug(citySlug);
      if (apiRes) setCityName(apiRes);
    }
  };

  const updateCartItems = async (tourIds: number[]) => {};

  useEffect(() => {
    if (citySlug) getCityName();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [citySlug]);

  useEffect(() => {
    if (citySlug && fromDate && toDate) {
      dispatch(
        getTourResults({
          citySlug: citySlug,
          from: fromDate,
          to: toDate,
          paxInfo: {
            adult: adult,
            child: child,
            infant: infant,
          },
        })
      );

      if (!resume) {
        saveCartDetails(fromDate, toDate);

        const tourIds = activeSessionDetails?.cartItems.map(
          (item) => item.tourId
        );

        if (tourIds) updateCartItems(tourIds);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    sortTourResults();
  }, [sortPriceInAscendingOrder, sortBy]);

  const cartLoading = useAppSelector((state) => state.activitySession.loading);

  const handleToggle = (id: number) => {
    if (expandedTourId === id) {
      setExpandedTourId(null);
    } else {
      setExpandedTourId(id);
    }
  };

  return (
    <div className={`${TourResultStyle.tourResultScreen}`}>
      {cartLoading ? (
        <MainLoader loading={cartLoading} height="80vh" />
      ) : (
        <>
          <div className={`${TourResultStyle.modifySearch}`}>
            <div className="bg-light border-bottom p-3 mb-3">
              <div className="container">
                <ActivitySearch
                  isModifySearch
                  isDisabled={true}
                  onSearch={(citySlug, from, to, paxInfo) => {
                    dispatch(
                      getTourResults({
                        citySlug: citySlug,
                        from: from,
                        to: to,
                        paxInfo: paxInfo,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${TourResultStyle.modifySearchMobile} sticky-top shadow-sm`}
          >
            <div className="container">
              <div className="d-flex alihn-items-center justify-content-between">
                <div>
                  <div className="text-capitalize fw-bold text-primary">
                    {citySlug}
                  </div>
                  <div>
                    {moment(fromDate).format("ll")}-
                    {moment(toDate).format("ll")}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <div
                    className={`${TourResultStyle.modifyButton} btn btn-sm btn-outline-primary px-3`}
                    onClick={handleShow}
                    role="button"
                  >
                    Modify
                  </div>
                  <button
                    className="btn btn-sm btn-outline-secondary ms-2"
                    onClick={() => {
                      setShowMobileCanvasCart(true);
                    }}
                  >
                    <i className="fa-solid fa-cart-shopping"></i>
                  </button>
                </div>

                <CartOffcanvas
                  show={showMobileCanvasCart}
                  onHide={() => {
                    setShowMobileCanvasCart(false);
                  }}
                />

                <ModifyCanvas
                  show={show}
                  onHide={handleClose}
                  citySlug={citySlug}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <div className={TourResultStyle.desktopTourFilters}>
                  <FilterResultPage
                    setTourResultsLocalState={setTourResultsLocalState}
                  />
                </div>
                <div className={TourResultStyle.sideCartButton}>
                  <div
                    className={`p-2 px-3 my-3 bg-light text-muted rounded border`}
                    role="button"
                    onClick={() => setOpenCartCollapse(!openCartCollapse)}
                  >
                    <div className="d-flex justify-content-between align-items-center py-1">
                      <div>
                        <i className="fa-solid fa-cart-shopping"></i> Kart (
                        {activeSessionDetails?.cartItems?.reduce(
                          (acc, item) => {
                            return acc + (item.tourOptions?.length || 0);
                          },
                          0
                        )}{" "}
                        items)
                      </div>
                      <div>
                        <i
                          className={`fa-solid ${
                            openCartCollapse ? "fa-angle-up" : "fa-angle-down"
                          } `}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <Collapse in={openCartCollapse}>
                    <div className="my-3">
                      <CartWrapper showCartItems={true} />
                    </div>
                  </Collapse>
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                <div className={`${TourResultStyle.tourResultHeader} `}>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                      {cityName && (
                        <div className={TourResultStyle.thingstoSee}>
                          {tourResultsLocalState &&
                            tourResultsLocalState.length}{" "}
                          Things to see in{" "}
                          <span className="text-capitalize text-secondary fw-bold">
                            {cityName}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                      <div className="row align-items-center justify-content-end">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 text-end pe-0">
                          <div
                            className={`${TourResultStyle.mobileFilter} small btn btn-sm w-100 btn-outline-secondary`}
                            onClick={() => setShowMobileFilter(true)}
                          >
                            Filter
                            <i className="fa-solid fa-filter ms-1 py-1"></i>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 text-end pe-0">
                          <div
                            className={`${
                              TourResultStyle.sortBy
                            } small btn btn-sm w-100 ${
                              openSortBy
                                ? "btn-secondary"
                                : "btn-outline-secondary"
                            }`}
                            onClick={() => setOpenSortBy(!openSortBy)}
                          >
                            Sort By{" "}
                            <i className="fa-solid fa-sort ms-1 py-1"></i>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control border-end-0 small"
                              value={searchStr}
                              onChange={(e) => {
                                setSearchStr(e.target.value);
                              }}
                              placeholder="Search by Tour"
                            />
                            <div className="border border-start-0 p-1 px-2 rounded-0 bg-white text-muted  rounded-end">
                              <i className="fa fa-search "></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Collapse in={openSortBy}>
                  <div className="mb-3 text-end bg-light py-2 px-3 rounded-bottom">
                    <button
                      className={`btn btn-sm py-0 small ms-2  ${
                        sortBy === "amount"
                          ? "btn-secondary"
                          : "btn-outline-secondary"
                      }`}
                      onClick={() => {
                        setSortPriceInAscendingOrder((old) => {
                          return !old;
                        });
                        setSortby("amount");
                      }}
                    >
                      <b> Price </b>
                      {sortBy === "amount" &&
                        (sortPriceInAscendingOrder ? (
                          <span> : Low to High</span>
                        ) : (
                          <span> : High to Low</span>
                        ))}
                    </button>
                    <button
                      className={`btn btn-sm py-0 small ms-2  ${
                        sortBy === "name"
                          ? "btn-secondary "
                          : "btn-outline-secondary"
                      }`}
                      onClick={() => {
                        setSortPriceInAscendingOrder((old) => {
                          return !old;
                        });
                        setSortby("name");
                      }}
                    >
                      <b> Tour Name </b>
                      {sortBy === "name" &&
                        (sortPriceInAscendingOrder ? (
                          <span> : A to Z</span>
                        ) : (
                          <span> : Z to A</span>
                        ))}
                    </button>
                  </div>
                </Collapse>

                {isSearching ? (
                  [...Array(4)].map((_, key) => (
                    <TourResultLoadingSkeleton key={key} />
                  ))
                ) : tourSearchResults.length > 0 ? (
                  tourResultsLocalState
                    .filter((tourItem) => {
                      return tourItem.name
                        .toLowerCase()
                        .includes(searchStr.toLowerCase());
                    })
                    .map((tourItem, key) => {
                      return (
                        <TourResultItem
                          key={key}
                          tourDetails={tourItem}
                          searchStr={searchStr}
                          handleToggle={handleToggle}
                          expandedTourId={expandedTourId}
                        />
                      );
                    })
                ) : (
                  <div>No Result Found.</div>
                )}
              </div>
            </div>

            {/* price filter mobile canvas */}
            <Offcanvas
              show={showMobileFilter}
              onHide={() => {
                setShowMobileFilter(false);
              }}
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <div className="">
                  <FilterResultPage
                    setTourResultsLocalState={setTourResultsLocalState}
                  />
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </>
      )}
    </div>
  );
};
