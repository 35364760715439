import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { IManagePriceForm } from "../../activitiescommonfnb/types/master.interface";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import { useEffect, useState } from "react";
import {
  EnumTourOptionTransferType,
  IPriceSource,
  ITourOptionTransfer,
} from "../../activitiescommonfnb/types/tour.interface";
import { activityAxios } from "../../axios/activityAxios";
import { capitalize } from "lodash";

const ManagePriceForm: React.FC<{
  getTourPrice: (data: IManagePriceForm) => void;
  selectedTourOption: number | undefined;
  selectedTourOptionTransfers: ITourOptionTransfer[] | undefined;
}> = (props) => {
  const [priceSources, setPriceSources] = useState<IPriceSource[]>([]);

  const { register, handleSubmit, watch, setValue, formState, clearErrors } =
    useForm<IManagePriceForm>({
      defaultValues: {
        transferType:
          props?.selectedTourOptionTransfers?.[0]?.transferType ||
          EnumTourOptionTransferType.PRIVATE,
      },
    });

  const getPriceSource = async (tourOptionId: number) => {
    const priceSourceRes = await activityAxios.post("/search/getPriceSource", {
      tourOptionId,
    });
    if (priceSourceRes.data.success) {
      setPriceSources(priceSourceRes?.data?.result || []);
    }
  };

  useEffect(() => {
    if (props.selectedTourOption) {
      getPriceSource(props.selectedTourOption);
    }
  }, [props.selectedTourOption]);

  const watchTransferType = watch("transferType");

  useEffect(() => {
    if (props?.selectedTourOptionTransfers) {
      setValue(
        "transferType",
        props.selectedTourOptionTransfers[0]?.transferType
      );
    }
  }, [props?.selectedTourOptionTransfers]);

  useEffect(() => {
    setValue("adultPrice", undefined);
    setValue("childPrice", undefined);
    setValue("pricePerPax", undefined);
    setValue("fromPax", undefined);
    setValue("toPax", undefined);
    clearErrors();
  }, [watchTransferType]);

  const onSubmit = (data: IManagePriceForm) => props.getTourPrice(data);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="border p-3 my-4 rounded">
      <Form.Group className="d-flex align-items-center">
        <label className="text-nowrap me-1">Transfer Type : </label>
        <Form.Control
          as="select"
          {...register("transferType", {
            required: "Please select a transfer type",
          })}
        >
          {props?.selectedTourOptionTransfers &&
            props.selectedTourOptionTransfers.map((transfer) => (
              <option value={transfer.transferType}>
                {" "}
                {transfer.transferType.replace(/_/g, " ").toLowerCase()}
              </option>
            ))}

          {/* <option value={EnumTransferType.SHARING_TRANSFER}>Sharing</option>
          <option value={EnumTransferType.PRIVATE_TRANSFER} selected>
            Private
          </option>
          <option value={EnumTransferType.WITHOUT_TRANSFER}>
            Without Transfer
          </option> */}
        </Form.Control>
      </Form.Group>

      {!props.selectedTourOptionTransfers?.length && (
        <span className="text-danger small">
          No transfers found. Please add a transfer
        </span>
      )}

      {watchTransferType === EnumTourOptionTransferType.PRIVATE && (
        <Form.Group className="d-flex mt-4 align-items-center">
          <div className="text-nowrap me-1">Price for :</div>
          <Form.Control
            type="number"
            {...register("fromPax", {
              required: "Price for is invalid",
              valueAsNumber: true,
              minLength: {
                value: 1,
                message: "",
              },
            })}
          />

          <label className="text-nowrap mx-1"> passenger </label>
          <label className="text-nowrap mx-1"> to : </label>
          <Form.Control
            type="string"
            {...register("toPax", {
              required: "Price for is invalid",
              valueAsNumber: true,
              minLength: {
                value: 1,
                message: "",
              },
            })}
          />
          <label className="ms-1">passenger </label>
        </Form.Group>
      )}

      {watchTransferType === EnumTourOptionTransferType.WITHOUT_TRANSFER && (
        <Form.Group className="d-flex my-4 align-items-center">
          <div className="text-nowrap me-1">Price for adult:</div>
          <Form.Control
            type="number"
            {...register("adultPrice", {
              required: "Price for adult is invalid",
              valueAsNumber: true,
              minLength: {
                value: 1,
                message: "",
              },
            })}
          />

          <label className="text-nowrap mx-1"> Price for Child : </label>
          <Form.Control
            type="number"
            {...register("childPrice", {
              required: "Price for infant is invalid",
              valueAsNumber: true,
              minLength: {
                value: 1,
                message: "",
              },
            })}
          />
        </Form.Group>
      )}

      {watchTransferType !== EnumTourOptionTransferType.WITHOUT_TRANSFER && (
        <div>
          <Form.Group className="d-flex col-6  align-items-center my-4 w-100">
            <label className="text-nowrap me-1">
              Price
              {watchTransferType === EnumTourOptionTransferType.PRIVATE
                ? ""
                : " per Pax :"}
            </label>
            <Form.Control
              type="number"
              {...register("pricePerPax", {
                required: "Price per pax is required",
                valueAsNumber: true,
              })}
            />
          </Form.Group>
        </div>
      )}

      <div className=" pb-2 ">
        <Form.Group className="d-flex w-100  align-items-center">
          <label className="text-nowrap me-1">Price Source : </label>
          <Form.Control
            as="select"
            {...register("priceSourceId", {
              required: "Please select a price source",
              valueAsNumber: true,
            })}
          >
            <option value={""} disabled selected>
              Select
            </option>
            {priceSources.map((priceSource) => (
              <option value={priceSource.id}>
                {capitalize(priceSource.nickName)}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="d-flex mt-4">
          <label htmlFor="" className="text-nowrap me-1">
            Remark :
          </label>
          <Form.Control
            as="textarea"
            rows={2}
            type="string"
            {...register("remark")}
          />
        </Form.Group>
      </div>

      {Object.keys(formState.errors).length ? (
        <div className="my-3">
          <p className="text-danger fw-bold"> Errors: </p>
          {Object.entries(formState.errors).map(([key, child]) => (
            <ErrorMessage errorMessage={child.message} />
          ))}
        </div>
      ) : (
        ""
      )}

      <div className="d-flex justify-content-center pb-2 mt-4">
        <Button variant="primary" type="submit">
          Update Price
        </Button>
      </div>
    </Form>
  );
};

export default ManagePriceForm;
