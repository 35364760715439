import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ITourOptionWithTransfer } from "../../activitiescommonfnb/types/activitySearch.interface";
import TourOptionItemStyle from "./TourOptionItemStyle.module.scss";
import Price from "../../commonUi/Price";
import moment from "moment";
import TourOptionTimeSlot from "./TourOptionTimeSlot";
import _, { upperCase } from "lodash";
import {
  EnumTourOptionTransferType,
  ISearchDetails,
  ITourOptionsList,
} from "../../activitiescommonfnb/types/tour.interface";
import {
  findTourOptionFalseDays,
  generateDateOptions,
  isTourOptionInCart,
} from "../../helper/tourHelper";
import { useAppSelector } from "../../store";
import { ActivitySessionActions } from "../../slice/session.slice";
import { useDispatch } from "react-redux";

export const TourOption: React.FC<{
  handleUpdateTourOption: (
    tourOptionId: number,
    date: Date,
    transferType: EnumTourOptionTransferType
  ) => void;
  handleAddToCart: (tourOptionId: number, timeSlotId?: string | null) => void;
  tourOptionDetails: ITourOptionsList;
  searchDetails: ISearchDetails;
  loading: number | null;
  handleTourOptionLoading: (tourOptionId: number) => void;
}> = (props) => {
  const [showTourOptionTimeSlots, setShowTourOptionTimeSlots] = useState(false);
  const dispatch = useDispatch();
  const cartDetails = useAppSelector(
    (state) => state.activitySession.activeSession?.cartItems
  );
  const dateOptions = generateDateOptions(
    props.searchDetails.fromDate,
    props.searchDetails.toDate
  );
  const [tourOptionFalseDays, setTourOptionFalseDays] = useState<string[]>([]);
  const selectedTranfer = props.tourOptionDetails.tourOption.transfers.find(
    (obj) => obj.transferType === props.tourOptionDetails.transferType
  )!;
  const tourOptionId = props.tourOptionDetails.tourOption.id;
  const existsInCart = isTourOptionInCart(cartDetails, tourOptionId);

  const handleAddToCart = (timeSlotId: string | null) => {
    props.handleAddToCart(tourOptionId, timeSlotId);
    setShowTourOptionTimeSlots(false);
  };

  const selectedDay = moment(props.tourOptionDetails.tourDate)
    .format("dddd")
    .toLowerCase();

  const isTourOptionAvailableOnSelectedDay =
    props.tourOptionDetails.tourOption[
      selectedDay as keyof ITourOptionWithTransfer
    ];

  useEffect(() => {
    const falseDays = findTourOptionFalseDays(
      props.tourOptionDetails.tourOption
    );
    setTourOptionFalseDays(falseDays);
  }, []);

  return (
    <div className={TourOptionItemStyle.TourOptionItem}>
      {props.loading === tourOptionId && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.5)", zIndex: 9999 }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="row align-items-center">
        <div className="col-md-3 col-12">
          <div>{props.tourOptionDetails.tourOption.tourOptionName}</div>
        </div>
        <div className="col-md-3 col-sm-5 col-6">
          <select
            className="form-select small"
            value={props.tourOptionDetails.transferType}
            name="transferOption"
            onChange={(e) => {
              props.handleUpdateTourOption(
                tourOptionId,
                moment(props.tourOptionDetails.tourDate).toDate(),
                e.target.value as EnumTourOptionTransferType
              );
              props.handleTourOptionLoading(tourOptionId);
            }}
          >
            {props.tourOptionDetails.tourOption?.transfers.map((transfer) => {
              return (
                <option value={transfer.transferType} key={transfer.id}>
                  {transfer.transferType ===
                  EnumTourOptionTransferType.WITHOUT_TRANSFER
                    ? "Ticket Only (No Transfer)"
                    : transfer.transferType ===
                      EnumTourOptionTransferType.PRIVATE
                    ? "With Private Transfer"
                    : "With Sharing Transfer"}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-2 col-sm-3 col-6">
          <select
            className="form-control py-2 small w-100"
            value={moment(props.tourOptionDetails.tourDate).format(
              "YYYY-MM-DD"
            )}
            onChange={(e) => {
              props.handleUpdateTourOption(
                tourOptionId,
                moment(e.target.value).toDate(),
                props.tourOptionDetails.transferType
              );
              props.handleTourOptionLoading(tourOptionId);
            }}
          >
            {dateOptions.map(({ day, date }) => (
              <option key={day} value={date}>
                Day {day}: {moment(date).format("ddd, DD MMM, YYYY")}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-2 col-sm-3 col-6">
          <div className={TourOptionItemStyle.TourOptionItemPrice}>
            {props.loading === tourOptionId ? (
              "loading..."
            ) : isTourOptionAvailableOnSelectedDay ? (
              <Price
                amount={props.tourOptionDetails.totalAmount}
                currency={"₹"}
              />
            ) : (
              "unavailable"
            )}
          </div>
        </div>
        {!props.tourOptionDetails.isCombo && (
          <div className="col-sm-2 col-6 text-end d-flex justify-content-center">
            {existsInCart ? (
              <button
                className="btn"
                onClick={() => {
                  dispatch(
                    ActivitySessionActions.deleteTourOption({
                      tourId: props.tourOptionDetails.tourOption.tourId,
                      tourOptionId: tourOptionId,
                    })
                  );
                }}
              >
                <i
                  className="fa-solid fa-trash-can fa-lg"
                  style={{ color: "#ec0909" }}
                ></i>
              </button>
            ) : (
              <button
                onClick={() => {
                  // This condition is temporarily commented out.
                  // if (props.tourOptionDetails.hasSlot) {
                  if (false) {
                    setShowTourOptionTimeSlots(true);
                  } else {
                    handleAddToCart(null);
                  }
                }}
                disabled={
                  props.loading === tourOptionId ||
                  (!isTourOptionAvailableOnSelectedDay as boolean)
                }
                className={`btn text-white w-100  btn-sm bg-secondary`}
              >
                <i className="fa-solid fa-cart-plus"></i> Add
              </button>
            )}
          </div>
        )}

        {!isTourOptionAvailableOnSelectedDay && tourOptionFalseDays.length && (
          <div className="alert alert-primary p-1 mt-3 mb-1" role="alert">
            This tour option is not operational on the following days:
            <b> {upperCase(tourOptionFalseDays?.join(", "))} . </b> Please
            select another date.
          </div>
        )}
      </div>
      {showTourOptionTimeSlots && (
        <TourOptionTimeSlot
          show={showTourOptionTimeSlots}
          onClose={() => {
            setShowTourOptionTimeSlots(false);
          }}
          addToCart={handleAddToCart}
          selectedTransferDetail={selectedTranfer}
          paxInfo={props.searchDetails.paxInfo}
          travelDate={props.tourOptionDetails.tourDate}
          tourId={props.tourOptionDetails.tourOption.tourId}
        />
      )}
    </div>
  );
};
