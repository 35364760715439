import { z } from "zod";
import { EnumTourOptionTransferType } from "../types/tour.interface";
import { ZodHelperService } from "./zodHelper.service";
import { EnumUserType } from "../types/User.interface";

z.setErrorMap(ZodHelperService.errorMap);

export const userDataZod = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().nullable(),
  alias: z.string().nullish(),
  userType: z.nativeEnum(EnumUserType),
  phoneNumber: z.string(),
  phoneCode: z.string(),
  isAdmin: z.boolean(),
  isPhoneVerified: z.boolean(),
  panNumber: z.string().nullable(),
  createdAt: ZodHelperService.getZodDate(),
  updatedAt: ZodHelperService.getZodDate(),
  isSupervisor: z.boolean().optional(),
});

export const tourOptionZod = z.object({
  id: z.number().optional(),
  tourOptionName: z.string().min(1),
  tourOptionDescription: z.string().nullish(),
  tourId: z.number(),
  startTime: z.coerce.number().nullable(),
  buffer: z.coerce.number(),
  childAge: z.string().nullish(),
  infantAge: z.string().nullish(),
  cancellationPolicyDescription: z.string().nullish(),
  childPolicyDescription: z.string().nullish(),
  durationInMinutes: z.coerce.number(),
  duration: z.string().min(1),
  timezone: z.string().nullish(),
  hasTourGuide: z.boolean(),
  hasSlot: z.boolean(),
  isHourly: z.boolean(),
  monday: z.boolean(),
  tuesday: z.boolean(),
  wednesday: z.boolean(),
  thursday: z.boolean(),
  friday: z.boolean(),
  saturday: z.boolean(),
  sunday: z.boolean(),
  isActive: z.boolean(),
  remark: z.string().nullish(),
  countryId: z.number().nullish(),
  createdByStaffDetail: userDataZod.nullish(),
  isTicketOnlySaleAllowed: z.boolean(),
});

export const createOrEditTourOptionTransfer = z.object({
  id: z.number().optional(),
  tourOptionId: z.number(),
  transferType: z.nativeEnum(EnumTourOptionTransferType),
  transferTime: z.string().min(1),
  transferDurationInMinutes: z.coerce.number(),
  disableChild: z.boolean(),
  disableInfant: z.boolean(),
  twoWayTransfer: z.boolean(),
});

export type TCreateOrEditTourOptionTransfer = z.infer<
  typeof createOrEditTourOptionTransfer
>;

export type TTourOption = z.infer<typeof tourOptionZod>;

// export enum EnumTransferType {
//   WITHOUT_TRANSFER = "withoutTransfer",
//   PRIVATE_TRANSFER = "privateTransfer",
//   SHARING_TRANSFER = "sharingTransfer",
// }
