import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { TSeason } from "../../../activitiescommonfnb/schemas/master.schema";
import { ReactDatePicker } from "../../../commonUi/ReactDatePicker";
import { activityAxios } from "../../../axios/activityAxios";
import { toast } from "react-toastify";
import moment from "moment";

export const AddSeasonModal: React.FC<{
  refresh: () => void;
  onHide: () => void;
  show: boolean;
  countryName: string;
  seasonWithCountry: {
    countryId: number;
    priority: number;
    fromDate: Date;
    toDate: Date;
    name: string;
    id?: number;
  };
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [newSeason, setNewSeason] = useState<TSeason>(props.seasonWithCountry);
  const saveSeason = async () => {
    try {
      if (loading) return;
      if (newSeason.fromDate > newSeason.toDate) {
        toast.error("Start date must be less than or equal to end date");
        return;
      }
      setLoading(true);
      const saveSeasonRes = await activityAxios.post(
        "/master/createOrEditSeason",
        { seasonData: newSeason }
      );
      if (saveSeasonRes.data.success) {
        toast.success("Season saved");
        props.refresh();
      }
    } catch (error: any) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Season</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Country</label>
          <input
            type="text"
            className="form-control"
            disabled
            value={props.countryName}
          />
        </div>
        <div className="mt-3 border p-2 rounded">
          <label htmlFor="">From date</label>
          <ReactDatePicker
            className=""
            selected={moment(newSeason.fromDate).toDate()}
            handleDateChange={(date: Date) => {
              setNewSeason((old) => {
                return { ...old, fromDate: date };
              });
            }}
          />
        </div>
        <div className="mt-3 border p-2 rounded">
          <label htmlFor="">to date</label>
          <ReactDatePicker
            className=""
            selected={newSeason.toDate}
            handleDateChange={(date: Date) => {
              setNewSeason((old) => {
                return { ...old, toDate: date };
              });
            }}
          />
        </div>
        <div className="mt-3">
          <label htmlFor="">Priority</label>
          <input
            value={newSeason.priority}
            onChange={(e) =>
              setNewSeason((old) => {
                return { ...old, priority: parseInt(e.target.value) || 0 };
              })
            }
            type="text"
            placeholder="Season name"
            className="form-control"
          />
        </div>
        <div className="mt-3">
          <label htmlFor="">Season Name</label>
          <input
            value={newSeason.name}
            onChange={(e) =>
              setNewSeason((old) => {
                return { ...old, name: e.target.value };
              })
            }
            type="text"
            placeholder="Season name"
            className="form-control"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <Button onClick={saveSeason}>
          Submit
          {loading ? <Spinner animation="border" size="sm" /> : ""}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
