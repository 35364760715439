import React from "react";
import { Link } from "react-router-dom";
import { IMenuItemDetail } from "./MainHeader";

const SideMenuItems: React.FC<{
  menuItemDetails: IMenuItemDetail;
  onClick: () => void;
}> = (props) => {
  return (
    <>
      <Link
        className="nav-link"
        aria-disabled="true"
        to={props.menuItemDetails.url}
        onClick={props.onClick}
      >
        <li className="nav-item">
          <img src={props.menuItemDetails.icon} className="img-fluid" alt="" />{" "}
          {props.menuItemDetails.name}
        </li>
      </Link>
    </>
  );
};

export default SideMenuItems;
