import { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { IHotelTabs } from "../../../activitiescommonfnb/types/master.interface";
import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import CreateOrEditHotel from "./CreateOrEditHotel";
import { hotelFields } from "../../../helper/adminHelper";
import {
  THotelRoomZod,
  THotelZod,
} from "../../../activitiescommonfnb/schemas/hotel.schema";
import CreateOrEditHotelRoom from "./CreateOrEditHotelRoom";
import { CitySelect } from "../Tours/CitySelect";

const Hotels = () => {
  const [hotelTabs, setHotelTabs] = useState<IHotelTabs[]>([]);
  const [showHotelModal, setShowHotelModal] = useState<boolean>(false);
  const [roomModal, setRoomModal] = useState<boolean>(false);
  const [hotelData, setHotelData] = useState<THotelZod>();
  const [selectedRoom, setSelectedRoom] = useState<THotelRoomZod>();
  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<string>();
  const [cityId, setCityId] = useState<number>();

  const getHotelTabsByCityId = async (cityId: number | undefined) => {
    const apiRes = await activityAxios.post<IJSONResponse<IHotelTabs[]>>(
      "/hotel/getHotelTabsByCityId",
      { cityId }
    );
    if (apiRes.data.success && apiRes.data.result) {
      setHotelTabs(apiRes.data.result);
      !selectedKey && setSelectedKey(apiRes.data.result[0].id.toString());
      if (selectedKey) {
        getHotelById(parseInt(selectedKey));
      } else {
        getHotelById(apiRes.data.result[0].id);
      }
    }
  };

  const getHotelById = async (hotelId: number) => {
    const apiRes = await activityAxios.post<IJSONResponse<THotelZod>>(
      "/hotel/getHotelById",
      { hotelId }
    );
    if (apiRes.data.success && apiRes.data.result) {
      setHotelData(apiRes.data.result);
    }
  };

  const updatePage = () => {
    setSelectedKey(hotelData?.id?.toString());
    if (selectedKey) getHotelById(parseInt(selectedKey));
    if (isAddModal) getHotelTabsByCityId(cityId);
  };

  useEffect(() => {
    getHotelTabsByCityId(cityId);
  }, [cityId]);

  return (
    <>
      <Tab.Container
        id="HotelTabs"
        activeKey={selectedKey}
        onSelect={(key) => {
          key && setSelectedKey(key);
          key && getHotelById(parseInt(key));
        }}
      >
        <div className="row">
          <div className="d-flex justify-content-between py-3 px-4 bg-success p-2 text-dark bg-opacity-25">
            <div>
              Choose Hotel <i className="fa-solid fa-angle-down ms-2"></i>
            </div>
            <div className="d-flex w-100 justify-content-end align-items-center">
              <CitySelect
                onCityChange={(city) => {
                  setCityId(city?.id!);
                }}
                className="mx-2 w-50"
              />
              <button
                className="text-white small  btn btn-primary ms-3"
                onClick={() => {
                  setShowHotelModal(!showHotelModal);
                  setIsAddModal(true);
                }}
              >
                Add Hotel
              </button>
            </div>
          </div>
          <div className={`col-lg-3 ps-0 `}>
            <div className="">
              <Nav variant="pills" className="flex-column">
                {hotelTabs?.map((tab) => (
                  <Nav.Item key={tab.id}>
                    <Nav.Link eventKey={tab.id}>{tab.name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </div>
          <div className="col-lg-9">
            {hotelTabs.length > 0 && (
              <Tab.Content>
                <div className="p-4 ">
                  <button
                    className="btn border text-end mb-3"
                    onClick={() => {
                      setShowHotelModal(!showHotelModal);
                      setIsAddModal(false);
                    }}
                  >
                    <i className="fa-solid fa-pen-to-square"></i> Edit Hotel
                  </button>
                  {hotelFields.map(({ name, label }) => {
                    const value = hotelData?.[name as keyof THotelZod];
                    return (
                      <div className="mb-1" key={name}>
                        <div className="rounded">
                          <label className="form-label me-2 fw-bold">
                            {label}:{" "}
                          </label>
                          {Array.isArray(value) ? null : value}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mx-4 border-top pt-3">
                  <div className="d-flex justify-content-between align-items-center ">
                    <h4>Rooms:</h4>
                    <button
                      className="text-white small btn btn-primary btn-sm"
                      onClick={() => {
                        setRoomModal(!roomModal);
                        setIsAddModal(true);
                      }}
                    >
                      Add Rooms
                    </button>
                  </div>

                  {hotelData?.rooms?.map((room, index: number) => (
                    <div key={room.id}>
                      <label className="me-2">{index + 1}.</label>
                      <label>{room.category}</label>
                      <button
                        className="  btn "
                        onClick={() => {
                          setSelectedRoom(room);
                          setRoomModal(!roomModal);
                          setIsAddModal(false);
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>{" "}
                      </button>
                    </div>
                  ))}
                </div>
              </Tab.Content>
            )}
          </div>
        </div>
      </Tab.Container>
      <CreateOrEditHotel
        showHotelModal={showHotelModal}
        onClose={() => setShowHotelModal(!showHotelModal)}
        hotelData={hotelData}
        isAddModal={isAddModal}
        updatePage={updatePage}
      />
      <CreateOrEditHotelRoom
        isAddModal={isAddModal}
        updatePage={updatePage}
        hotelId={hotelData?.id}
        selectedRoom={selectedRoom}
        handleRoomModal={() => setRoomModal(!roomModal)}
        roomModal={roomModal}
      />
    </>
  );
};

export default Hotels;
