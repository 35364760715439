import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  BlockQuote,
  Bold,
  ClassicEditor,
  CodeBlock,
  Essentials,
  FontSize,
  Heading,
  Italic,
  Link,
  List,
  Paragraph,
  TodoList,
  Underline,
  Table,
  TableToolbar,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { ErrorMessage } from "./ErrorMessage";
import { Controller } from "react-hook-form";

const defaultToolbar = [
  "undo",
  "redo",
  "|",
  "heading",
  "bold",
  "fontsize",
  "italic",
  "underline",
  "|",
  "numberedList",
  "bulletedList",
  "|",
  "link",
  "todoList",
  "codeBlock",
  "blockQuote",
  "insertTable",
  "tableColumn",
  "tableRow",
  "mergeTableCells",
];

const defaultPlugin = [
  Essentials,
  Bold,
  Italic,
  Paragraph,
  Underline,
  List,
  Link,
  TodoList,
  FontSize,
  CodeBlock,
  BlockQuote,
  Heading,
  Table,
  TableToolbar,
];

export const AppCkEditor: React.FC<{
  label?: string;
  value?: string | null;
  className?: string;
  isRequired?: boolean;
  placeHolder?: string;
  errorMessage?: string;
  onChange: (value?: string) => void;
}> = (props) => {
  return (
    <div className={props.className}>
      <label htmlFor="">
        {props.label}
        {props.isRequired && <span className="text-danger"> *</span>}
      </label>
      <CKEditor
        editor={ClassicEditor}
        data={props.value || ""}
        onChange={(event, editor) => {
          const data = editor.getData();
          props.onChange(data || undefined);
        }}
        config={{
          plugins: defaultPlugin,
          toolbar: defaultToolbar,
          placeholder: props.placeHolder,
        }}
      />
      <ErrorMessage errorMessage={props.errorMessage} />
    </div>
  );
};

interface AppCkEditorProps {
  name: string;
  control: any;
  className?: string;
  isRequired?: boolean;
  placeHolder?: string;
  errorMessage?: string;
}

export const AppRegisterCkEditor: React.FC<AppCkEditorProps> = ({
  name,
  control,
  className,
  isRequired,
  placeHolder,
  errorMessage,
}) => {
  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <CKEditor
            editor={ClassicEditor}
            data={value || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChange(data);
            }}
            config={{
              plugins: defaultPlugin,
              toolbar: defaultToolbar,
              placeholder: placeHolder,
            }}
          />
        )}
      />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
};
