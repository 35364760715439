import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  hotelSchema,
  THotelZod,
} from "../../../activitiescommonfnb/schemas/hotel.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  AppRegisterInput,
  AppRegisterSelect,
} from "../../../commonUi/AppInput";
import { hotelInputFields, starOptions } from "../../../helper/adminHelper";
import { CitySelect } from "../Tours/CitySelect";
import { activityAxios } from "../../../axios/activityAxios";
import { toast } from "react-toastify";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { useEffect, useState } from "react";

const CreateOrEditHotel: React.FC<{
  showHotelModal: boolean;
  onClose: () => void;
  updatePage: () => void;
  hotelData: THotelZod | undefined;
  isAddModal: boolean;
}> = (props) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    formState,
  } = useForm<THotelZod>({
    resolver: zodResolver(hotelSchema),
  });

  const onSubmit = async (data: THotelZod) => {
    setLoading(true);

    const apiRes = await activityAxios.post<IJSONResponse>(
      "/hotel/createOrEditHotel",
      {
        hotelData: data,
      }
    );
    if (apiRes.data.success && apiRes.data.result) {
      toast.success("Hotel saved sucessfully");
      handelModelClose();
      props.updatePage();
    } else {
      toast.error("Failed to saved hotel");
    }
    setLoading(false);
  };

  const handelModelClose = () => {
    props.onClose();
    reset();
  };

  useEffect(() => {
    if (!props.isAddModal && props.hotelData) {
      Object.entries(props.hotelData).forEach(([key, value]) => {
        setValue(key as keyof THotelZod, value);
      });
    } else {
      reset();
    }
  }, [props.showHotelModal]);

  return (
    <>
      <Modal show={props.showHotelModal} onHide={handelModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Hotel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            {hotelInputFields.map(({ name, label, type }) => (
              <div className="mb-3" key={name}>
                <label className="form-label">{label}</label>
                <AppRegisterInput
                  register={register}
                  name={name}
                  required
                  errorMessage={formState.errors[name]?.message}
                  type={type}
                />
              </div>
            ))}
            <label className="form-label">Star</label>
            <AppRegisterSelect
              name="star"
              register={register}
              registerOptions={{
                valueAsNumber: true,
              }}
              options={starOptions}
            />
            <label className="form-label mt-3">Location</label>
            <CitySelect
              defaultCityId={watch("cityId")}
              onCityChange={(city) => {
                setValue("cityId", city?.id!);
                setValue("countryId", city?.countryId!);
                clearErrors("cityId");
              }}
              errorMessage={
                formState.errors.cityId?.message ? "Location is invalid" : ""
              }
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handelModelClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            {loading ? <Spinner animation="border" size="sm" /> : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateOrEditHotel;
