import { Button, Form, Modal } from "react-bootstrap";
import { UserSelect } from "../common/Selects/UserSelect";
import { AppRegisterInput } from "../../commonUi/AppInput";
import {
  TCreateOrEditTaskZod,
  createOrEditTaskSchema,
} from "../../activitiescommonfnb/schemas/tasks.schema";
import { ReactDatePicker } from "../../commonUi/ReactDatePicker";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import { capitalize } from "lodash";
import { toast } from "react-toastify";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { useEffect, useState } from "react";
import {
  EnumReminderFrequency,
  ITask,
} from "../../activitiescommonfnb/types/task.interface";
import {
  EnumUserType,
  IUserData,
} from "../../activitiescommonfnb/types/User.interface";
import { OverlaySpinner } from "../../commonUi/OverlaySpinner";
import { LoadingButton } from "../../commonUi/LoadingButton";
import { DateService } from "../../services/Date.service";
import { StaffStaticSelect } from "../common/Selects/StaffStaticSelect";

export const CreateOrEditTaskPopup = (props: {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  id?: number;
}) => {
  const { formState, watch, setValue, register, handleSubmit, reset } = useForm<
    Partial<TCreateOrEditTaskZod>
  >({
    resolver: zodResolver(createOrEditTaskSchema),
    defaultValues: {
      isPhotoProofRequired: false,
      reminderFrequency: EnumReminderFrequency.ONCE,
      description: null,
      deadline: null,
    },
  });

  const [isTaskDataLoading, setIsTaskDataLoading] = useState(false);
  const [isSavingTaskDataLoading, setIsSavingTaskDataLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState<IUserData | null>(null);
  const [isStaffSearch, setIsStaffSearch] = useState(true);

  const taskReminderFrequency = watch("reminderFrequency");

  const submitTask = async (taskDetails: Partial<TCreateOrEditTaskZod>) => {
    setIsSavingTaskDataLoading(true);
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/task/createOrEditTask",
      {
        taskDetails,
      }
    );
    setIsSavingTaskDataLoading(false);
    if (apiRes.data.success) {
      toast.success("Task saved");
      props.onSave();
    } else {
      toast.error(apiRes.data.errorMessage);
    }
  };

  const getTaskById = async (taskId: number) => {
    setIsTaskDataLoading(true);
    const apiRes = await activityAxios.post<IJSONResponse<ITask>>(
      "/task/getTaskByTaskId",
      {
        taskId: taskId,
      }
    );
    setIsTaskDataLoading(false);
    const taskData = apiRes.data.result;
    if (apiRes.data.success && taskData) {
      reset({
        ...taskData,
        reminderStartTime: DateService.convertUtcTimeToLocalDateTime(
          taskData.reminderStartTime
        ),
        reminderEndTime: DateService.convertUtcTimeToLocalDateTime(
          taskData.reminderEndTime
        ),
      });

      setSelectedUser(taskData.user || null);

      if (taskData.user?.userType !== EnumUserType.STAFF) {
        setIsStaffSearch(false);
      }
    }
  };

  useEffect(() => {
    if (props.id) {
      getTaskById(props.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <OverlaySpinner loading={isTaskDataLoading}>
        <Modal.Header closeButton>
          {props.id ? <b>Edit Task</b> : <b>Create new task</b>}
        </Modal.Header>
        <form onSubmit={handleSubmit(submitTask)}>
          <Modal.Body>
            <div className="row">
              <div className="col-6">
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Show Only Staff"
                    checked={isStaffSearch}
                    onChange={(e) => {
                      setIsStaffSearch(e.target.checked);
                      setSelectedUser(null);
                      setValue("userId", undefined);
                    }}
                    className="small"
                  />
                </Form>
                {isStaffSearch ? (
                  <StaffStaticSelect
                    label="Select Staff"
                    labelClassName="small"
                    containerClassName="mb-3"
                    value={selectedUser || undefined}
                    onChange={(user) => {
                      setSelectedUser(user);
                      setValue("userId", user?.id);
                    }}
                  />
                ) : (
                  <UserSelect
                    label="Search User"
                    labelClassName="small required"
                    containerClassName="col-12 mb-3"
                    value={selectedUser || undefined}
                    onChange={(user) => {
                      setSelectedUser(user);
                      setValue("userId", user?.id);
                    }}
                  />
                )}

                <ErrorMessage errorMessage={formState.errors.userId?.message} />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <label htmlFor="title" className="small required">
                  Title
                </label>
                <AppRegisterInput
                  register={register}
                  name="title"
                  id="title"
                  required
                />
                <ErrorMessage errorMessage={formState.errors.title?.message} />
              </div>
              <div className="col-6">
                <label className="small p-0 m-0">Deadline</label>
                <ReactDatePicker
                  className="small"
                  selected={watch("deadline")}
                  minDate={new Date()}
                  handleDateChange={(date) => {
                    setValue("deadline", date);
                  }}
                />
                <ErrorMessage
                  errorMessage={formState.errors.deadline?.message}
                />
              </div>
              <div className="col-12 p-2">
                <label htmlFor="description" className="small">
                  Description
                </label>
                <textarea
                  {...register("description")}
                  className="form-control"
                  rows={3}
                ></textarea>
                <ErrorMessage
                  errorMessage={formState.errors.description?.message}
                />
              </div>
              <div className="col-6">
                <label htmlFor="reminderFrequency" className="small">
                  Reminder Frequency
                </label>
                <br />
                <select
                  id="reminderFrequency"
                  className="form-select"
                  value={taskReminderFrequency || ""}
                  onChange={(e) => {
                    // Will either be enum or empty string.
                    const selectedFrequency = e.target
                      .value as EnumReminderFrequency;

                    setValue("reminderFrequency", selectedFrequency || null);
                  }}
                >
                  <option value={""}>None</option>
                  {Object.values(EnumReminderFrequency).map((val) => {
                    return (
                      <option key={val} value={val}>
                        {capitalize(val.replaceAll("_", " "))}
                      </option>
                    );
                  })}
                </select>
              </div>
              {taskReminderFrequency && (
                <div className="col-6">
                  <label className="small required">Reminder Start Date</label>
                  <ReactDatePicker
                    selected={watch("reminderStartDate")}
                    minDate={new Date()}
                    maxDate={watch("deadline")}
                    handleDateChange={(date) => {
                      setValue("reminderStartDate", date);
                    }}
                  />
                  <ErrorMessage
                    errorMessage={formState.errors.reminderStartDate?.message}
                  />
                </div>
              )}
              {taskReminderFrequency && (
                <div className="col-6 small my-2">
                  <label className="small required">Start time</label>
                  <ReactDatePicker
                    selected={watch("reminderStartTime")}
                    handleDateChange={(time) => {
                      setValue("reminderStartTime", time);
                    }}
                    timeOnly={true}
                    timeInterval={30} //minutes
                  />
                  <ErrorMessage
                    errorMessage={formState.errors.reminderStartTime?.message}
                  />
                </div>
              )}
              {(taskReminderFrequency === EnumReminderFrequency.HOURLY ||
                taskReminderFrequency ===
                  EnumReminderFrequency.EVERY_SIX_HOUR) && (
                <div className="col-6 ">
                  <label className="small required">End time</label>
                  <ReactDatePicker
                    selected={watch("reminderEndTime")}
                    handleDateChange={(time) => {
                      setValue("reminderEndTime", time);
                    }}
                    timeOnly={true}
                    timeInterval={30}
                  />
                  <ErrorMessage
                    errorMessage={formState.errors.reminderEndTime?.message}
                  />
                </div>
              )}

              <div className="form-check col-12 p-3">
                <div className="border rounded p-2 d-flex align-items-center">
                  <span>
                    <AppRegisterInput
                      register={register}
                      className="form-check-input p-0 m-0"
                      name="isPhotoProofRequired"
                      type="checkbox"
                      id="isPhotoProofRequired"
                    />
                  </span>
                  <span>
                    <label
                      className="form-check-label ps-1 m-0"
                      htmlFor="isPhotoProofRequired"
                    >
                      Photo Proof Required
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} variant="secondary">
              Close
            </Button>
            <LoadingButton
              btnClass="btn-primary"
              loading={isSavingTaskDataLoading}
              onClick={handleSubmit(submitTask)}
              btnType="submit"
            >
              Save Task
            </LoadingButton>
          </Modal.Footer>
        </form>
      </OverlaySpinner>
    </Modal>
  );
};
