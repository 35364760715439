import moment from "moment";
import { Placeholder } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { TTourOption } from "../activitiescommonfnb/schemas/tour.schema";
import { TCreateOrEditTour } from "../activitiescommonfnb/schemas/master.schema";
import {
  IBookingTourOption,
  ICartItem,
} from "../activitiescommonfnb/types/booking.interface";
import _ from "lodash";
import {
  ITourOptionTransferWithPrice,
  ITourOptionWithTransfer,
} from "../activitiescommonfnb/types/activitySearch.interface";
import { ISearchDetails } from "../activitiescommonfnb/types/tour.interface";

export const TourResultItemLoadingSkeleton = () => {
  return (
    <div>
      <div className="border rounded  p-3 mb-2 ">
        <div className="row">
          <div className="col-lg-4">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className="col-lg-3">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className="col-lg-2">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className="col-lg-2">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className="col-lg-1">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TourResultLoadingSkeleton = () => {
  return (
    <div className="border rounded  mt-3 mb-3">
      <div className="row">
        <div className="col-lg-4 pe-0" style={{ height: "12rem" }}>
          {" "}
          <div className="bg-light w-100 h-100 rounded"></div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 h-100 d-flex flex-column justify-content-between">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={10} />
              <Placeholder xs={6} size="xs" />
            </Placeholder>
            <div>
              <Placeholder.Button variant="light" xs={8} />
            </div>
          </div>
        </div>
        <div className="col-lg-2 ps-0">
          <div className="p-3 text-end h-100 d-flex flex-column justify-content-between">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={6} size="xs" />
            </Placeholder>
            <div>
              <Placeholder.Button variant="success" xs={12} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const convertToTimeStamp = (time: string | undefined) => {
  const timestamp = moment(time, "HH:mm").format("HHmm");
  return timestamp;
};

export const convertToTimeString = (timestamp: number) => {
  const time = moment(timestamp, "HHmm").format("HH:mm");
  return time;
};

export const defaultDate = {
  from: moment().add(5, "days").toDate(),
  to: moment().add(9, "days").toDate(),
};

export type TWeekDay =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

export const weekDays: Array<{
  day: TWeekDay;
  short: string;
}> = [
  { day: "sunday", short: "Sun" },
  { day: "monday", short: "Mon" },
  { day: "tuesday", short: "Tue" },
  { day: "wednesday", short: "Wed" },
  { day: "thursday", short: "Thu" },
  { day: "friday", short: "Fri" },
  { day: "saturday", short: "Sat" },
];

export const defaultFormValues: Partial<TTourOption> = {
  isHourly: false,
  hasTourGuide: false,
  isActive: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
  hasSlot: false,
  buffer: 20,
  remark: "",
  isTicketOnlySaleAllowed: true,
};

export const creatOrEditTourfields: Array<{
  name: keyof TCreateOrEditTour;
  label: string;
  isRequired?: boolean;
  type?: string;
}> = [
  { name: "name", label: "Tour Name", isRequired: true },
  { name: "slug", label: "Tour Slug", isRequired: true },
  { name: "pickupPoint", label: "Pickup Point" },
  { name: "dropPoint", label: "Drop Point" },
  { name: "reportingTime", label: "Reporting Time" },
  { name: "priority", label: "Priority", type: "number" },
  { name: "childAge", label: "Child Age" },
  { name: "infantAge", label: "Infant Age" },
  { name: "rating", label: "Ratings", type: "number" },
  { name: "reviewCount", label: "Reviews", type: "number" },
];

export const creatOrEditTourCkEditorFields: Array<{
  name: keyof TCreateOrEditTour;
  label: string;
  isRequired?: boolean;
}> = [
  {
    name: "tourShortDescription",
    label: "Tour Short Description",
    isRequired: true,
  },
  { name: "tourDescription", label: "Tour Description" },
  {
    name: "cancellationPolicyDescription",
    label: "Cancellation Policy",
    isRequired: true,
  },
];

export const creatOrEditTourAdditionalFields: Array<{
  name: keyof TCreateOrEditTour;
  label: string;
}> = [
  {
    name: "childCancellationPolicyDescription",
    label: "Child Cancellation Policy",
  },
  { name: "itenararyDescription", label: "Itinerary Description" },
  { name: "termsAndConditions", label: "Terms And Conditions" },
  { name: "faqDetails", label: "FAQ Details" },
  { name: "usefullInformation", label: "Useful Information" },
  { name: "importantInformation", label: "Important Information" },
  { name: "whatsInThisTour", label: "What's in this Tour?" },
  { name: "toursAdvantage", label: "Tours Advantage" },
  { name: "tourInclusion", label: "Tour Inclusion" },
];

export const creatOrEditTourOptionsCkEditorFields: Array<{
  name: keyof TTourOption;
  label: string;
}> = [
  {
    name: "tourOptionDescription",
    label: "Tour Option Description",
  },
  {
    name: "cancellationPolicyDescription",
    label: "Cancellation Policy Description",
  },
  {
    name: "childPolicyDescription",
    label: "Child Policy Description",
  },
];

export const creatOrEditTourOptionsFormFields: Array<{
  name: keyof TTourOption;
  label: string;
  type?: string;
  isRequired?: boolean;
}> = [
  {
    name: "tourOptionName",
    label: "Tour Option Name",
    isRequired: true,
  },
  {
    name: "duration",
    label: "Duration (In String)",
    isRequired: true,
  },
  {
    name: "durationInMinutes",
    label: "Duration (In Minutes)",
    type: "number",
    isRequired: true,
  },
  {
    name: "childAge",
    label: "Child Age",
  },
  {
    name: "infantAge",
    label: "Infant Age",
  },
  {
    name: "buffer",
    label: "Buffer (In Minutes)",
    isRequired: true,
  },
];

export const creatOrEditTourOptionsCheckBoxes: Array<{
  id: keyof TTourOption;
  label: string;
}> = [
  {
    id: "hasTourGuide",
    label: "Has Tour Guide?",
  },
  {
    id: "isHourly",
    label: "Hourly Tour?",
  },
  {
    id: "hasSlot",
    label: "Has Slot?",
  },
  {
    id: "isActive",
    label: "Is Active?",
  },
  {
    id: "isTicketOnlySaleAllowed",
    label: "Ticket Only Sale Allowed?",
  },
];

export const TourLoadingSkeleton = () => {
  return (
    <div className="border rounded  mb-3">
      <div className="row">
        <div className="col-lg-3 pe-0" style={{ height: "12rem" }}>
          {" "}
          <div className="bg-light w-100 h-100 rounded"></div>
        </div>
        <div className="col-lg-9">
          <div className="p-3 h-100 d-flex flex-column justify-content-between">
            <Placeholder animation="glow">
              <Placeholder xs={10} />
              <Placeholder xs={6} size="xs" />
            </Placeholder>

            <div>
              <Placeholder.Button variant="light" xs={3} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const tourOptionTotalPrice = (tourOptionDetail: IBookingTourOption) => {
  const totalPrice =
    tourOptionDetail.adultPrice +
    tourOptionDetail.childPrice +
    tourOptionDetail.infantPrice +
    tourOptionDetail.totalTransferPrice;
  return totalPrice;
};

export const tourOptionWeekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const generateDateOptions = (from: string | null, to: string | null) => {
  const startDate = moment(from);
  const endDate = moment(to);
  const daysDifference = endDate.diff(startDate, "days");

  return _.range(0, daysDifference + 1).map((day) => {
    const date = startDate.clone().add(day, "days");
    return {
      day: day + 1,
      date: date.format("YYYY-MM-DD"),
    };
  });
};

export const getTourOptionPrice = (
  selectedTranfer: ITourOptionTransferWithPrice
) => {
  const totalPrice =
    selectedTranfer.adultPrice +
    selectedTranfer.childPrice +
    selectedTranfer.infantPrice +
    selectedTranfer.totalTransferPrice;
  return totalPrice;
};

export const findTourOptionFalseDays = (
  tourOptionDetail: ITourOptionWithTransfer
) => {
  const falseDays = [];
  for (const [day, isActive] of Object.entries(tourOptionDetail)) {
    if (!isActive && tourOptionWeekDays.includes(day)) {
      falseDays.push(day);
    }
  }
  return falseDays;
};

export const isTourOptionInCart = (
  cartDetails: ICartItem[] | undefined,
  tourOptionId: number
) => {
  const tourOptionIds = cartDetails?.flatMap((cartItem) =>
    cartItem.tourOptions.map((option) => option.tourOptionId)
  );

  return tourOptionIds?.includes(tourOptionId);
};

export const getSearchUrl = (
  searchDetails: ISearchDetails,
  citySlug: string | undefined,
  tourDetailSlug: string
) => {
  const searchUrl = `/search/${citySlug}/${tourDetailSlug}?from=${searchDetails.fromDate}&to=${searchDetails.toDate}&adult=${searchDetails.paxInfo.adult}&child=${searchDetails.paxInfo.child}&infant=${searchDetails.paxInfo.infant}`;

  return searchUrl;
};

export const transformTourOption = (
  tourOptions: ITourOptionWithTransfer[],
  searchDetails: ISearchDetails,
  isComboTour: boolean
) => {
  const transformData = tourOptions.map((tourOption) => {
    return {
      tourOption: tourOption,
      transferType: tourOption.transfers[0].transferType,
      tourDate: searchDetails.fromDate,
      totalAmount: getTourOptionPrice(tourOption.transfers[0]),
      fromDate: searchDetails.fromDate,
      toDate: searchDetails.toDate,
      isCombo: isComboTour,
    };
  });

  return transformData;
};

export const getSearchDetails = (params: URLSearchParams) => {
  const searchDetails = {
    paxInfo: {
      adult: parseInt(params.get("adult") || "0"),
      child: parseInt(params.get("child") || "0"),
      infant: parseInt(params.get("infant") || "0"),
    },
    fromDate: params.get("from") || moment().format("YYYY-MM-DD"),
    toDate: params.get("to") || moment().format("YYYY-MM-DD"),
  };

  return searchDetails;
};
