import React from "react";
import quotationHeaderStyle from "./QuotationHeaderStyle.module.scss";
import logo from "../../assets/images/packageKartLogo.png";
import { IQuotationPreview } from "../../activitiescommonfnb/types/quotation.interface";
import moment from "moment";
import _ from "lodash";

const QuotationHeader: React.FC<{
  quotationPreview: IQuotationPreview | null;
  enquiryId: number | undefined;
}> = (props) => {
  const currentDate = moment().format("MMMM Do YYYY, hh:mm a");

  return (
    <>
      <div className={quotationHeaderStyle.quotationHeader}>
        <div className={`${quotationHeaderStyle.quotationCompanyHeader}`}>
          <div className="px-4">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <img
                  className={quotationHeaderStyle.quotationHeaderLogo}
                  src={logo}
                  alt=""
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div className={quotationHeaderStyle.quotationHeaderDetails}>
                  <span className="text-secondary">Packagekart.com</span> <br />
                  <span>+91 99961 55563</span> <br />
                  <span>support@packagekart.com</span> <br />
                  <span>290/1, SK Road, Near Bus Stand, Ladwa 136132</span>{" "}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={quotationHeaderStyle.quotationSubHeader}>
          <div className="px-4">
            <span className="text-muted">Enquiry ID : </span>{" "}
            <span className="fw-bold">{props.enquiryId}</span>
            <h5 className="fw-bold text-primary">
              {props.quotationPreview?.quotationData?.title}
            </h5>{" "}
            <p className="text-secondary fw-bold">
              {props.quotationPreview?.quotationData?.updatedAt
                ? moment(
                    props.quotationPreview?.quotationData?.updatedAt
                  ).format("MMMM Do YYYY, h:mm a")
                : currentDate}{" "}
              (IST)
            </p>
            {/* <div className="py-1">1 Adult - 1 Child - 1 Infant</div> */}
            <div className="small">
              <span className="text-muted">Staff Name : </span>{" "}
              <span className="fw-bold">
                {props.quotationPreview?.staff
                  ? _.startCase(props.quotationPreview.staff.firstName) +
                    " " +
                    _.startCase(props.quotationPreview.staff.lastName)
                  : "Not Saved Yet"}{" "}
              </span>
              <span className="text-muted">Quotation No : </span>{" "}
              <span className="fw-bold">
                {props.quotationPreview?.quotationData?.id
                  ? `S${props.quotationPreview?.quotationData?.staffId}Q${props.quotationPreview?.quotationData?.id}V${props.quotationPreview?.versionId}`
                  : "Not Saved Yet"}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotationHeader;
