import React, { useState } from "react";
import TourOptionItem from "../../../common/TourOptionItem/TourOptionItem";
import { CreateOrEditTourOption } from "./CreateOrEditTourOption";
import { TTourOption } from "../../../../activitiescommonfnb/schemas/tour.schema";
import { EnumUser } from "../../../../types/tourSearch.interface";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const AdminTourOption: React.FC<{
  tourOptionDetails: TTourOption;
  userType?: EnumUser;
  showOptions?: boolean;
}> = (props) => {
  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = useState(false);
  const [tourOption, setTourOption] = useState<TTourOption>(
    props.tourOptionDetails
  );
  const updateTourOptionData = (data: TTourOption) => {
    setTourOption(data);
  };

  return (
    <div className="container-fluid bg-white border border-top-0">
      <div className="row align-items-center">
        <div className={props.showOptions ? "col-lg-11" : "col-lg-12"}>
          <TourOptionItem
            TourOptionDetails={tourOption}
            showOptions={props.showOptions}
          />
        </div>

        {props.showOptions && (
          <div className="col-lg-1 text-end">
            {props.userType === EnumUser.SUPPLIER && (
              <button
                className="btn btn-warning text-nowrap"
                onClick={() => {
                  navigate(`/supplier/manageprice/${tourOption.tourId}`);
                }}
              >
                Manage Price
              </button>
            )}

            {props.userType === EnumUser.ADMIN && (
              <button
                className="btn btn-info btn-sm"
                onClick={() => setShowEditModal(true)}
              >
                <i className="fa-solid fa-pencil"></i>
              </button>
            )}
          </div>
        )}
      </div>
      {showEditModal && (
        <CreateOrEditTourOption
          id={tourOption.id}
          tourId={tourOption.tourId}
          show={showEditModal}
          onClose={() => {
            setShowEditModal(false);
          }}
          onSave={() => {
            setShowEditModal(false);
          }}
          updateTourOptionData={(data) => updateTourOptionData(data)}
        />
      )}
    </div>
  );
};

export default AdminTourOption;
