import { useDispatch } from "react-redux";
import { IBookingTourOption } from "../../activitiescommonfnb/types/booking.interface";
import cartItemStyle from "./CartItemStyle.module.scss";
import { ActivitySessionActions } from "../../slice/session.slice";
import { formatDate } from "../../functions/commonFx";
import moment from "moment";
import { tourOptionTotalPrice } from "../../helper/tourHelper";
import { capitalize, upperCase } from "lodash";

export const CartItem: React.FC<{
  showMoreDetails: boolean;
  tourOptionDetail: IBookingTourOption;
}> = (props) => {
  const dispatch = useDispatch();
  const totalPrice = tourOptionTotalPrice(props.tourOptionDetail);

  return (
    <div className={`${cartItemStyle.cartItem} my-3`}>
      <div>
        <div className="row">
          <div className="col-lg-4">
            <img
              src={
                props.tourOptionDetail.tourImages?.[0]?.fileUrl ||
                "https://plus.unsplash.com/premium_photo-1668116307088-583ee0d4aaf7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8dGh1bWJuYWlsfGVufDB8fDB8fHww"
              }
              className={`img-fluid ${cartItemStyle.image} w-100`}
              alt=""
            />
          </div>
          <div className="col-lg-8 ">
            <div className={`${cartItemStyle.tourName}`}>
              <div className="fw-bold">
                {upperCase(props.tourOptionDetail.tourName)}
              </div>
              <div>
                {capitalize(
                  props?.tourOptionDetail?.tourOption?.tourOptionName
                )}
              </div>
              <div className={cartItemStyle.transferType}>
                {typeof props.tourOptionDetail.startTime === "number" && (
                  <div>
                    <span className="text-muted"> Start Time: </span>
                    {moment(props.tourOptionDetail.startTime, "HHmm").format(
                      "HH:mm"
                    )}
                  </div>
                )}
                <div>
                  <span className="text-muted">Travel Date: </span>
                  {formatDate(props.tourOptionDetail.travelDate)}
                </div>

                {props.showMoreDetails ? (
                  <>
                    <div>
                      <span className="text-muted">Transfer Type: </span>
                      {props.tourOptionDetail.transferType}
                    </div>
                    <div>
                      <span className="text-muted">Adult Price: </span>
                      {props.tourOptionDetail.adultPrice /
                        props.tourOptionDetail.adultCount}{" "}
                      X {props.tourOptionDetail.adultCount} = ₹
                      {props.tourOptionDetail.adultPrice}
                    </div>
                    {props.tourOptionDetail.childCount > 0 && (
                      <div>
                        <span className="text-muted">Child Price: </span>
                        {props.tourOptionDetail.childPrice /
                          props.tourOptionDetail.childCount || 0}{" "}
                        X {props.tourOptionDetail.childCount} = ₹
                        {props.tourOptionDetail.childPrice},
                      </div>
                    )}
                    {props.tourOptionDetail.infantCount > 0 && (
                      <div>
                        <span className="text-muted">Infant Price: </span>
                        {props.tourOptionDetail.infantPrice /
                          props.tourOptionDetail.infantCount || 0}{" "}
                        X {props.tourOptionDetail.infantCount} = ₹
                        {
                          <span className={cartItemStyle.transferType}>
                            {props.tourOptionDetail.infantPrice}{" "}
                          </span>
                        }
                      </div>
                    )}
                  </>
                ) : null}
              </div>
              {props.showMoreDetails && (
                <>
                  <span className="fw-bold text-muted">Sub Total: </span>₹{" "}
                  {totalPrice}
                </>
              )}{" "}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`z-0 ${cartItemStyle.deleteItemBtn}`}
        onClick={() => {
          dispatch(
            ActivitySessionActions.deleteTourOption({
              tourId: props.tourOptionDetail.tourId,
              tourOptionId: props.tourOptionDetail.tourOptionId,
            })
          );
        }}
      >
        <i className="fa-solid fa-trash"></i>
      </div>
    </div>
  );
};
