import React from "react";
import { Offcanvas } from "react-bootstrap";
import { CartWrapper } from "../../components/cart/CartWrapper";

const CartOffcanvas: React.FC<{
  show: boolean;
  onHide: () => void;
}> = ({ show, onHide }) => {
  return (
    <Offcanvas show={show} onHide={onHide}>
      <Offcanvas.Header closeButton />
      <Offcanvas.Body>
        <div className="my-3">
          <CartWrapper showCartItems={true} />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CartOffcanvas;
