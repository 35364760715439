import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import singleActivitiPageStyle from "./SingleActivityPageStyle.module.scss";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { Ratings } from "../../commonUi/Ratings";
import {
  ITourOptionWithTransfer,
  ITourWithPrice,
} from "../../activitiescommonfnb/types/activitySearch.interface";
import Price from "../../commonUi/Price";
import Carousel from "react-bootstrap/Carousel";
import { MainLoader } from "../../commonUi/MainLoader";
import { TourOption } from "../../components/Tour/TourOption";
import moment from "moment";
import {
  EnumTourOptionTransferType,
  ISearchDetails,
  ITourOptionsList,
} from "../../activitiescommonfnb/types/tour.interface";
import { IBookingTourOption } from "../../activitiescommonfnb/types/booking.interface";
import { TourOptionHelper } from "../../functions/TourOptionHelper";
import { ActivitySessionActions } from "../../slice/session.slice";
import { useAppDispatch } from "../../store";
import { toast } from "react-toastify";
import {
  getSearchDetails,
  getTourOptionPrice,
  TourResultItemLoadingSkeleton,
  transformTourOption,
} from "../../helper/tourHelper";
import { tourSections } from "../../helper/quotationHelper";

const ActivitySinglePage = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const searchParams = useParams<{
    citySlug: string;
    activitySlug: string;
  }>();
  const [tourDetail, setTourDetail] = useState<ITourWithPrice>();
  const [loadTourSinglePage, setLoadTourSinglePage] = useState(false);
  const searchDetails: ISearchDetails = getSearchDetails(params);
  const [tourOptionList, setTourOptionList] = useState<ITourOptionsList[]>([]);
  const [loading, setLoading] = useState(false);
  const [tourOptionLoadingId, setTourOptionLoadingId] = useState<number | null>(
    null
  );

  const getTourBySlug = async () => {
    setLoadTourSinglePage(true);
    const apiRes = await activityAxios.post<IJSONResponse<ITourWithPrice>>(
      "/tour/getTourDetailsWithPrice",
      {
        tourSlug: searchParams.activitySlug,
        citySlug: searchParams.citySlug,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      setTourDetail(apiRes.data.result);
      await getTourOptions(apiRes.data.result.id);
    } else {
      navigate("/screens/PageNotFound/NotFoundPage");
      return;
    }
    setLoadTourSinglePage(false);
  };

  const getTourOptions = async (tourId: number) => {
    setLoading(true);
    const apiRes = await activityAxios.post<
      IJSONResponse<ITourOptionWithTransfer[]>
    >("/search/getTourOptions", {
      tourId,
      citySlug: searchParams.citySlug,
      travelDate: searchDetails.fromDate,
      noOfAdult: searchDetails.paxInfo.adult,
      noOfChild: searchDetails.paxInfo.child,
      noOfInfant: searchDetails.paxInfo.infant,
    });
    if (apiRes?.data?.success && apiRes?.data?.result) {
      const transformTourOptionData = transformTourOption(
        apiRes.data?.result,
        searchDetails,
        tourDetail?.isComboTour!
      );
      setTourOptionList(transformTourOptionData);
    } else {
      toast.error(apiRes.data.errorMessage || "Unable to find Tour Option");
    }
    setLoading(false);
  };

  const handleAddToCart = (
    tourOptionId?: number,
    timeSlotId?: string | null
  ) => {
    const cartItems: IBookingTourOption[] = tourOptionList.map((obj1) => {
      const selectedTranfer = obj1.tourOption.transfers.find(
        (obj2) => obj2.transferType === obj1.transferType
      )!;
      return TourOptionHelper.tranformTourOptionToBookingTourOption({
        tourOption: obj1.tourOption,
        transfer: selectedTranfer,
        tourName: tourDetail?.name || "",
        travelDate: obj1.tourDate,
        searchDetails: searchDetails,
        timeSlotId: timeSlotId || null,
        tourImages: tourDetail?.tourImages,
      });
    });
    if (!tourDetail) {
      toast.error("Tour Details not found");
      return;
    }
    dispatch(
      ActivitySessionActions.addTourOptionInActiveSession({
        isComboTour: tourDetail?.isComboTour,
        tourId: tourDetail?.id,
        tourOptions: tourDetail?.isComboTour
          ? [...cartItems]
          : [cartItems.find((obj) => obj.tourOptionId === tourOptionId)!],
      })
    );
  };

  const handleUpdateTourOption = async (
    tourOptionId: number,
    date: Date,
    transferType: EnumTourOptionTransferType
  ) => {
    const tourOption = await TourOptionHelper.getSingleTourOptionWithTransfer({
      tourId: tourDetail?.id!,
      tourOptionId,
      citySlug: searchParams.citySlug || "",
      adultCount: searchDetails.paxInfo.adult,
      childCount: searchDetails.paxInfo.child,
      infantCount: searchDetails.paxInfo.infant,
      travelDate: moment(date).format("YYYY-MM-DD"),
      countryId: tourDetail?.countryId!,
    });

    if (tourOption) {
      const index = tourOptionList.findIndex(
        (item) => item.tourOption.id === tourOptionId
      );

      const tourOptionTransfer = tourOptionList[
        index
      ].tourOption.transfers.find((obj) => obj.transferType === transferType);

      if (index !== -1 && tourOptionTransfer) {
        const updatedTourOptionList = [...tourOptionList];
        updatedTourOptionList[index] = {
          tourOption: tourOption,
          transferType: transferType,
          tourDate: moment(date).format("YYYY-MM-DD"),
          totalAmount: getTourOptionPrice(tourOptionTransfer),
          isCombo: tourDetail?.isComboTour!,
        };
        setTourOptionList(updatedTourOptionList);
      }
    } else {
      toast.error("Unable to get tour option details");
    }
    setTourOptionLoadingId(null);
  };

  const handleTourOptionLoading = (id: number) => {
    if (tourOptionLoadingId === id) {
      setTourOptionLoadingId(null);
    } else {
      setTourOptionLoadingId(id);
    }
  };

  useEffect(() => {
    getTourBySlug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loadTourSinglePage && tourDetail ? (
        <div className={singleActivitiPageStyle.singleActivityPage}>
          <div className={singleActivitiPageStyle.activityImageHeaderContainer}>
            <div className="">
              <Carousel slide interval={2000}>
                {tourDetail.tourImages?.map((tourImage) => {
                  return (
                    <Carousel.Item>
                      <img
                        className={`img-fluid ${singleActivitiPageStyle.carouselImage}`}
                        src={tourImage.fileUrl}
                        alt=""
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <div className="container mt-5">
            <div className={singleActivitiPageStyle.tourDetailsOption}>
              <div className="row align-items-center">
                <div className="col-lg-10">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-bold text-secondary">
                      {tourDetail?.name}
                    </h5>
                    <Ratings
                      rating={tourDetail?.rating || 0}
                      reviewCount={tourDetail?.reviewCount || 0}
                      key={tourDetail?.id}
                    />
                  </div>
                  <div
                    className={singleActivitiPageStyle.shortDescription}
                    dangerouslySetInnerHTML={{
                      __html: tourDetail?.tourShortDescription || "",
                    }}
                  ></div>
                </div>
                <div className="col-lg-2">
                  <div className={singleActivitiPageStyle.TourPrice}>
                    <span className="small">Starts from</span> <br />
                    <h5 className="text-success">
                      <Price amount={tourDetail?.amount} currency={"₹"} />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className={singleActivitiPageStyle.tourDetailsOption}>
              <h6 className="fw-bold pb-3 text-muted">Tour Option</h6>
              <div>
                {loading
                  ? [...Array(3)].map((_, key) => (
                      <TourResultItemLoadingSkeleton key={key} />
                    ))
                  : tourOptionList.map((tourOption, key) => {
                      return (
                        <TourOption
                          loading={tourOptionLoadingId}
                          handleUpdateTourOption={handleUpdateTourOption}
                          handleAddToCart={handleAddToCart}
                          tourOptionDetails={tourOption}
                          key={key}
                          searchDetails={searchDetails}
                          handleTourOptionLoading={handleTourOptionLoading}
                        />
                      );
                    })}
              </div>
            </div>
          </div>

          <div className="container">
            <div className={singleActivitiPageStyle.tourDetailsParagraph}>
              {tourSections(tourDetail).map((section, index) => (
                <div
                  key={index}
                  className={singleActivitiPageStyle.paragraphBox}
                >
                  <h5>{section.title}</h5>
                  <div
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <MainLoader loading={true} height="80vh" />
      )}
    </>
  );
};

export default ActivitySinglePage;
