import { Carousel, Collapse } from "react-bootstrap";
import TourResultItemStyle from "./TourResultItemStyle.module.scss";
import React, { useState } from "react";
import { activityAxios } from "../../axios/activityAxios";
import { Ratings } from "../../commonUi/Ratings";
import { TourOptionDetailsModal } from "./TourOptionDetailsModal";
import {
  ITourOptionWithTransfer,
  ITourWithPrice,
} from "../../activitiescommonfnb/types/activitySearch.interface";
import { useParams } from "react-router";
import Price from "../../commonUi/Price";
import { useSearchParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { IBookingTourOption } from "../../activitiescommonfnb/types/booking.interface";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { TourOption } from "./TourOption";
import { ActivitySessionActions } from "../../slice/session.slice";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  getSearchDetails,
  getSearchUrl,
  getTourOptionPrice,
  TourResultItemLoadingSkeleton,
  transformTourOption,
} from "../../helper/tourHelper";
import { TourOptionHelper } from "../../functions/TourOptionHelper";
import {
  EnumTourOptionTransferType,
  ISearchDetails,
  ITourOptionsList,
} from "../../activitiescommonfnb/types/tour.interface";
import _ from "lodash";

export const TourResultItem: React.FC<{
  tourDetails: ITourWithPrice;
  searchStr: string;
  handleToggle: (id: number) => void;
  expandedTourId: number | null;
}> = (props) => {
  const { citySlug } = useParams();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const cartDetails = useAppSelector(
    (state) => state.activitySession.activeSession?.cartItems
  );
  const existsInCart = cartDetails?.find(
    (obj) => obj.tourId === props.tourDetails.id
  )?.isComboTour;
  const navigate = useNavigate();
  const [tourOptionList, setTourOptionList] = useState<ITourOptionsList[]>([]);
  const searchDetails: ISearchDetails = getSearchDetails(params);
  const [loading, setLoading] = useState(false);
  const [tourOptionLoadingId, setTourOptionLoadingId] = useState<number | null>(
    null
  );
  const [tourOptionDetailsModalShow, setTourOptionDetailsModalShow] =
    useState(false);

  const handleAddToCart = (
    tourOptionId?: number,
    timeSlotId?: string | null
  ) => {
    const cartItems: IBookingTourOption[] = tourOptionList.map((obj1) => {
      const selectedTranfer = obj1.tourOption.transfers.find(
        (obj2) => obj2.transferType === obj1.transferType
      )!;
      return TourOptionHelper.tranformTourOptionToBookingTourOption({
        tourOption: obj1.tourOption,
        tourName: props.tourDetails.name,
        transfer: selectedTranfer,
        travelDate: obj1.tourDate,
        searchDetails: searchDetails,
        timeSlotId: timeSlotId || null,
        tourImages: props.tourDetails.tourImages,
      });
    });
    dispatch(
      ActivitySessionActions.addTourOptionInActiveSession({
        isComboTour: props.tourDetails.isComboTour,
        tourId: props.tourDetails.id,
        tourOptions: props.tourDetails.isComboTour
          ? [...cartItems]
          : [cartItems.find((obj) => obj.tourOptionId === tourOptionId)!],
      })
    );
  };

  const getTourOptions = async (tourId: number) => {
    setLoading(true);
    const apiRes = await activityAxios.post<
      IJSONResponse<ITourOptionWithTransfer[]>
    >("/search/getTourOptions", {
      tourId,
      citySlug,
      travelDate: searchDetails.fromDate,
      noOfAdult: searchDetails.paxInfo.adult,
      noOfChild: searchDetails.paxInfo.child,
      noOfInfant: searchDetails.paxInfo.infant,
    });
    if (apiRes?.data?.success && apiRes?.data?.result) {
      const transformTourOptionData = transformTourOption(
        apiRes.data?.result,
        searchDetails,
        props.tourDetails.isComboTour
      );
      setTourOptionList(transformTourOptionData);
    } else {
      toast.error(apiRes.data.errorMessage || "Unable to find Tour Option");
    }
    setLoading(false);
  };

  const handleUpdateTourOption = async (
    tourOptionId: number,
    date: Date,
    transferType: EnumTourOptionTransferType
  ) => {
    const tourOption = await TourOptionHelper.getSingleTourOptionWithTransfer({
      tourId: props.tourDetails.id,
      tourOptionId,
      citySlug: citySlug || "",
      adultCount: searchDetails.paxInfo.adult,
      childCount: searchDetails.paxInfo.child,
      infantCount: searchDetails.paxInfo.infant,
      travelDate: moment(date).format("YYYY-MM-DD"),
      countryId: props.tourDetails.countryId,
    });

    if (tourOption) {
      const index = tourOptionList.findIndex(
        (item) => item.tourOption.id === tourOptionId
      );

      const tourOptionTransfer = tourOptionList[
        index
      ].tourOption.transfers.find((obj) => obj.transferType === transferType);

      if (index !== -1 && tourOptionTransfer) {
        const updatedTourOptionList = [...tourOptionList];
        updatedTourOptionList[index] = {
          tourOption: tourOption,
          transferType: transferType,
          tourDate: moment(date).format("YYYY-MM-DD"),
          totalAmount: getTourOptionPrice(tourOptionTransfer),
          isCombo: props.tourDetails.isComboTour,
        };
        setTourOptionList(updatedTourOptionList);
      }
    } else {
      toast.error("Unable to get tour option details");
    }
    setTourOptionLoadingId(null);
  };

  const handleTourOptionLoading = (id: number) => {
    if (tourOptionLoadingId === id) {
      setTourOptionLoadingId(null);
    } else {
      setTourOptionLoadingId(id);
    }
  };

  const handleTourClick = () => {
    navigate(getSearchUrl(searchDetails, citySlug, props.tourDetails.slug));
  };

  return (
    <div className={`${TourResultItemStyle.tourResultItem}`}>
      {tourOptionDetailsModalShow && (
        <TourOptionDetailsModal
          tourDetails={props.tourDetails}
          show={tourOptionDetailsModalShow}
          onHide={() => {
            setTourOptionDetailsModalShow(false);
          }}
        />
      )}
      <div className="container-fluid">
        <div className="row">
          <div className={`col-lg-4 col-md-4 col-sm-12 col-12 p-0`}>
            {Boolean(props.tourDetails?.tourImages?.length) ? (
              <Carousel fade interval={2000} indicators={false} className="z-0">
                {props.tourDetails.tourImages?.map((singleImage, key) => (
                  <Carousel.Item key={key}>
                    <img
                      className={`img-fluid ${TourResultItemStyle.ItemImage}`}
                      src={singleImage.fileUrl}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <img
                className={`img-fluid ${TourResultItemStyle.ItemImage}`}
                src="https://images.unsplash.com/photo-1634148551170-d37d021e0cc9?q=80&w=2084&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              />
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div
              className={`${TourResultItemStyle.tourResultShortDetails} d-flex flex-column align-content-between justify-content-between h-100 `}
            >
              <div>
                <h6
                  className="fw-bold text-muted"
                  role="button"
                  onClick={handleTourClick}
                >
                  {props.tourDetails.name}
                </h6>
                <div className={TourResultItemStyle.shortDesicription}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.tourDetails.tourShortDescription,
                    }}
                  ></span>
                </div>
              </div>
              <div className="mt-3">
                <div
                  role="button"
                  className={TourResultItemStyle.importantInfoBtn}
                  onClick={() => {
                    setTourOptionDetailsModalShow(true);
                  }}
                >
                  <i
                    className={`fa-solid fa-exclamation small ${TourResultItemStyle.icon} me-2`}
                  ></i>{" "}
                  <span>Important Info</span> <br />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-12 px-0">
            <div
              className={`${TourResultItemStyle.tourResultPriceCol} d-flex align-content-between flex-wrap h-100 justify-content-end`}
            >
              <div className={TourResultItemStyle.review}>
                <Ratings
                  rating={props.tourDetails.rating}
                  reviewCount={props.tourDetails.reviewCount}
                />
              </div>
              <div className="w-100">
                <span className="small">starts from</span>
                <div className="d-flex align-items-end justify-content-end">
                  <Price
                    className={TourResultItemStyle.price}
                    amount={props.tourDetails.amount}
                    currency={"₹"}
                  />
                  <span className="small">/ Adult</span>
                </div>
                <button
                  className="btn btn-sm w-100 btn-primary"
                  onClick={() => {
                    if (props.expandedTourId !== props.tourDetails.id) {
                      setTourOptionList([]);
                    }
                    getTourOptions(props.tourDetails.id);
                    props.handleToggle(props.tourDetails.id);
                  }}
                >
                  Select
                  {props.expandedTourId === props.tourDetails.id ? (
                    <i className="fa-solid fa-angle-up ms-1 pt-1"></i>
                  ) : (
                    <i className="fa-solid fa-angle-down ms-1 pt-1"></i>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Collapse in={props.expandedTourId === props.tourDetails.id}>
          <div id="example-collapse-text" className="py-3">
            <div className={TourResultItemStyle.TourOptionsLabelHeader}>
              <div className="row ">
                <div className="col-3">Tour Options</div>
                <div className="col-3">Transfer Options</div>
                <div className="col-2">Tour Day</div>
                <div className="col-2 text-end">Total Amount</div>
              </div>
            </div>

            {loading
              ? [...Array(3)].map((_, key) => (
                  <TourResultItemLoadingSkeleton key={key} />
                ))
              : tourOptionList.map((tourOption, key) => {
                  return (
                    <TourOption
                      loading={tourOptionLoadingId}
                      handleUpdateTourOption={handleUpdateTourOption}
                      handleAddToCart={handleAddToCart}
                      tourOptionDetails={tourOption}
                      key={key}
                      searchDetails={searchDetails}
                      handleTourOptionLoading={handleTourOptionLoading}
                    />
                  );
                })}

            {props.tourDetails.isComboTour && (
              <div className="text-end ">
                {existsInCart ? (
                  <button
                    className="btn"
                    onClick={() => {
                      tourOptionList.forEach((obj) => {
                        dispatch(
                          ActivitySessionActions.deleteTourOption({
                            tourId: props.tourDetails.id,
                            tourOptionId: obj.tourOption.id,
                          })
                        );
                      });
                    }}
                  >
                    <i
                      className="fa-solid fa-trash-can fa-lg"
                      style={{ color: "#ec0909" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={() => handleAddToCart()}
                    className={`btn text-white btn-sm px-3 bg-secondary`}
                  >
                    <i className="fa-solid fa-cart-plus"></i> Add
                  </button>
                )}
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
