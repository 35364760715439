import {
  ICartItem,
  ISessionWithCartDetail,
} from "../activitiescommonfnb/types/booking.interface";

export const getPaxCount = (
  activeSessionData: ISessionWithCartDetail | null
): { adultCount: number; infantCount: number; childCount: number } => {
  const adultCount =
    activeSessionData?.cartItems[0]?.tourOptions[0]?.adultCount || 0;
  const infantCount =
    activeSessionData?.cartItems[0]?.tourOptions[0]?.infantCount || 0;
  const childCount =
    activeSessionData?.cartItems[0]?.tourOptions[0]?.childCount || 0;
  return { adultCount, infantCount, childCount };
};
