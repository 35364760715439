import React from "react";
import { ReactDatePicker } from "../../commonUi/ReactDatePicker";
import { AppCkEditor } from "../../commonUi/AppCkEditor";
import {
  roomsOptions,
  markupPercentageOptions,
  mealPlanOptions,
} from "../../helper/quotationHelper";
import { fields } from "../../helper/quotationHelper";
import { IQuotationFormRC } from "../../activitiescommonfnb/types/quotation.interface";
import { handleWheel } from "../../helper/globalHelper";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import moment from "moment";

const QuotationForm: React.FC<IQuotationFormRC> = ({
  quotationData,
  setQuotationData,
  ...props
}) => {
  const hotelOptions = props.hotelData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleRoomSelect = (hotelIndex: number, roomCount: number) => {
    setQuotationData((old) => {
      const updatedHotels = [...old.hotels];
      const currentHotel = updatedHotels[hotelIndex];
      const currentRoomCount = currentHotel?.paxConfigurationJson?.length || 0;

      if (currentRoomCount < roomCount) {
        for (let i = currentRoomCount; i < roomCount; i++) {
          currentHotel?.paxConfigurationJson?.push({
            adultCount: 0,
            childCount: 0,
            cost: 0,
            roomId: null,
            mealPlan: "",
          });
        }
      } else {
        currentHotel.paxConfigurationJson?.splice(roomCount);
      }

      return {
        ...old,
        hotels: updatedHotels,
      };
    });
  };

  const handleAddHotel = () => {
    const newHotel = {
      hotelId: null,
      checkIn: "",
      checkOut: "",
      roomCount: 0,
      paxConfigurationJson: [],
    };
    setQuotationData((old) => ({
      ...old,
      hotels: [...old.hotels, newHotel],
    }));
  };

  const getRoomOptions = (hotelId: number | undefined) => {
    const hotel = props.hotelData?.find((option) => option.id === hotelId);

    const roomOptions = hotel?.rooms?.map((item) => ({
      value: item.id || "",
      label: item.category || "",
    })) || [{ value: "", label: "" }];
    return roomOptions;
  };

  const handleDeleteHotel = (targetIndex: number) => {
    setQuotationData((old) => ({
      ...old,
      hotels: old.hotels.filter((_, index) => index !== targetIndex),
    }));
  };

  return (
    <form className="mt-4">
      <AppCkEditor
        label="Quotation Body"
        className="my-2 small"
        value={quotationData.quotationBody}
        onChange={(e) => {
          setQuotationData((old) => ({
            ...old,
            quotationBody: e || "",
          }));
        }}
        errorMessage={props.quotationError?.quotationBody}
      />

      <div className="row">
        {fields?.map((field) => {
          const value = quotationData?.[field?.name];
          return (
            <div
              key={field.name}
              className={`${
                field.name === "title" ? "col-xl-4" : "col-xl-2"
              } col-lg-4 col-md-4 col-sm-4 col-12`}
            >
              <div className="form-group">
                <label className={`small ${field.required ? "required " : ""}`}>
                  {field.label}
                </label>
                <input
                  onWheel={handleWheel}
                  value={String(value === 0 ? "" : value)}
                  className="form-control"
                  required={field.required}
                  type={field.type}
                  onChange={(e) => {
                    setQuotationData((old) => ({
                      ...old,
                      [field.name]:
                        field.type === "number"
                          ? parseInt(e.target.value)
                          : e.target.value,
                    }));
                  }}
                />
              </div>
              <ErrorMessage errorMessage={props.quotationError?.[field.name]} />
            </div>
          );
        })}

        <div className="col-xl-2 col-lg-4 col-md-4 col-12">
          <div className="form-group mb-2">
            <label className="small required" htmlFor="travelDate">
              Travel Date:
            </label>
            <ReactDatePicker
              handleDateChange={(date) =>
                setQuotationData((old) => ({
                  ...old,
                  travelDate: date,
                }))
              }
              selected={quotationData.travelDate}
              className="form-control"
            />
          </div>
        </div>
      </div>

      <div className="border container-fluid bg-light rounded my-3 ">
        {quotationData.hotels?.map((hotel, index) => (
          <div className="border container-fluid bg-white shadow rounded my-3 py-3">
            <div key={hotel.hotelId}>
              <div className="d-flex justify-content-between align-items-center my-2">
                <div className="fw-bold">{index + 1}. Hotel Details</div>
                {quotationData.hotels.length > 1 && (
                  <button
                    type="button"
                    className="btn d-flex flex-row-reverse btn-danger me-2 py-2 px-3 small rounded btn-sm"
                    onClick={() => handleDeleteHotel(index)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                )}
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-4">
                  <label className="small">Hotel Name:</label>

                  <select
                    className="form-select"
                    value={hotel.hotelId || ""}
                    onChange={(e) => {
                      const selectedHotelId = parseInt(e.target.value);
                      setQuotationData((old) => {
                        const updatedHotels = [...old.hotels];
                        updatedHotels[index].hotelId = selectedHotelId;
                        return {
                          ...old,
                          hotels: updatedHotels,
                        };
                      });
                    }}
                  >
                    <option value="">Select Hotel</option>
                    {hotelOptions?.map((obj) => (
                      <option
                        value={obj.value}
                        selected={hotel.hotelId === obj.value}
                      >
                        {obj.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-2">
                  <label className="small">Check In:</label>
                  <ReactDatePicker
                    handleDateChange={(date) => {
                      setQuotationData((old) => {
                        const updatedHotels = [...old.hotels];
                        updatedHotels[index].checkIn =
                          moment(date).format("YYYY-MM-DD");
                        return {
                          ...old,
                          hotels: updatedHotels,
                        };
                      });
                    }}
                    selected={
                      hotel.checkIn
                        ? moment(hotel.checkIn)?.toDate()
                        : new Date()
                    }
                    className="form-control"
                  />
                </div>
                <div className="col-lg-2">
                  <label className="small">Check Out:</label>
                  <ReactDatePicker
                    handleDateChange={(date) => {
                      setQuotationData((old) => {
                        const updatedHotels = [...old.hotels];
                        updatedHotels[index].checkOut =
                          moment(date).format("YYYY-MM-DD");
                        return {
                          ...old,
                          hotels: updatedHotels,
                        };
                      });
                    }}
                    selected={
                      hotel.checkOut
                        ? moment(hotel.checkOut).toDate()
                        : new Date()
                    }
                    className="form-control"
                  />
                </div>

                <div className="form-group col-lg-2">
                  <label className="small" htmlFor="rooms">
                    Rooms Count:
                  </label>

                  <select
                    value={hotel.roomCount || ""}
                    className="form-select"
                    onChange={(e) => {
                      setQuotationData((old) => {
                        const updatedHotels = [...old.hotels];
                        updatedHotels[index].roomCount = parseInt(
                          e.target.value
                        );
                        return {
                          ...old,
                          hotels: updatedHotels,
                        };
                      });
                      handleRoomSelect(index, parseInt(e.target.value));
                    }}
                  >
                    <option value="" disabled selected>
                      Select Rooms
                    </option>
                    {roomsOptions?.map((obj) => (
                      <option value={obj.value}>{obj.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              {hotel?.paxConfigurationJson?.map((obj, roomIndex: number) => {
                return (
                  <div
                    key={roomIndex}
                    className="d-flex justify-content-between mt-3"
                  >
                    <div className="d-flex align-items-center fw-bold">
                      Room {roomIndex + 1}:
                    </div>
                    <div className="form-group col-1">
                      <label className="small" htmlFor="adultCount">
                        Adults:
                      </label>
                      <select
                        className="form-select"
                        value={obj.adultCount || ""}
                        onChange={(e) => {
                          setQuotationData((old) => {
                            const updatedHotels = [...old.hotels];
                            const updatedPaxConfiguration = [
                              ...updatedHotels[index]?.paxConfigurationJson!,
                            ];

                            if (updatedPaxConfiguration[roomIndex]) {
                              updatedPaxConfiguration[roomIndex].adultCount =
                                parseInt(e.target.value);
                            }

                            updatedHotels[index].paxConfigurationJson =
                              updatedPaxConfiguration;

                            return {
                              ...old,
                              hotels: updatedHotels,
                            };
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>{" "}
                        {roomsOptions?.map((obj) => (
                          <option value={obj.value}>{obj.label}</option>
                        ))}{" "}
                      </select>
                    </div>
                    <div className="form-group col-1">
                      <label className="small" htmlFor="childCount">
                        Child:
                      </label>
                      <select
                        value={obj.childCount || ""}
                        className="form-select"
                        onChange={(e) => {
                          setQuotationData((old) => {
                            const updatedHotels = [...old.hotels];
                            const updatedPaxConfiguration = [
                              ...updatedHotels[index]?.paxConfigurationJson!,
                            ];

                            if (updatedPaxConfiguration[roomIndex]) {
                              updatedPaxConfiguration[roomIndex].childCount =
                                parseInt(e.target.value);
                            }

                            updatedHotels[index].paxConfigurationJson =
                              updatedPaxConfiguration;

                            return {
                              ...old,
                              hotels: updatedHotels,
                            };
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {roomsOptions?.map((obj) => (
                          <option value={obj.value}>{obj.label}</option>
                        ))}{" "}
                      </select>
                    </div>
                    <div className="form-group col-3">
                      <label className="small">Meal Plan:</label>
                      <select
                        className="form-control"
                        value={obj.mealPlan || ""}
                        onChange={(e) => {
                          const updatedMealPlan = e.target.value;
                          setQuotationData((old) => {
                            const updatedHotels = [...old.hotels];
                            const updatedPaxConfiguration = [
                              ...updatedHotels[index]?.paxConfigurationJson!,
                            ];
                            if (updatedPaxConfiguration[roomIndex]) {
                              updatedPaxConfiguration[roomIndex].mealPlan =
                                updatedMealPlan;
                            }

                            updatedHotels[index].paxConfigurationJson =
                              updatedPaxConfiguration;

                            return {
                              ...old,
                              hotels: updatedHotels,
                            };
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {mealPlanOptions.map((obj) => (
                          <option value={obj.value}>{obj.label}</option>
                        ))}{" "}
                      </select>
                    </div>

                    <div className="form-group col-2">
                      <label className="small" htmlFor="category">
                        Category:
                      </label>
                      <select
                        className="form-select"
                        value={obj.roomId || ""}
                        onChange={(e) => {
                          setQuotationData((old) => {
                            const updatedHotels = [...old.hotels];
                            const updatedPaxConfiguration = [
                              ...updatedHotels[index]?.paxConfigurationJson!,
                            ];

                            if (updatedPaxConfiguration[roomIndex]) {
                              updatedPaxConfiguration[roomIndex].roomId =
                                parseInt(e.target.value);
                            }

                            updatedHotels[index].paxConfigurationJson =
                              updatedPaxConfiguration;

                            return {
                              ...old,
                              hotels: updatedHotels,
                            };
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {getRoomOptions(hotel.hotelId!)?.map((obj) => (
                          <option value={obj.value}>{obj.label}</option>
                        ))}{" "}
                      </select>
                    </div>
                    <div className="form-group col-2">
                      <label className="small">Cost:</label>

                      <input
                        className="form-control"
                        type="number"
                        value={obj.cost || ""}
                        onChange={(e) => {
                          setQuotationData((old) => {
                            const updatedHotels = [...old.hotels];
                            const updatedPaxConfiguration = [
                              ...updatedHotels[index]?.paxConfigurationJson!,
                            ];

                            if (updatedPaxConfiguration[roomIndex]) {
                              updatedPaxConfiguration[roomIndex].cost =
                                parseInt(e.target.value);
                            }

                            updatedHotels[index].paxConfigurationJson =
                              updatedPaxConfiguration;

                            return {
                              ...old,
                              hotels: updatedHotels,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary mb-3"
            onClick={() => handleAddHotel()}
          >
            Add Hotel
          </button>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-lg-4">
          <label className="small required" htmlFor="markupPercentage">
            Markup Percentage:
          </label>
          <select
            onChange={(e) =>
              setQuotationData((old) => ({
                ...old,
                markupPercentage: parseInt(e.target.value),
              }))
            }
            className="form-select"
            value={quotationData.markupPercentage}
          >
            {markupPercentageOptions?.map((obj) => (
              <option value={obj.value}>{obj.label}</option>
            ))}
          </select>
          <ErrorMessage errorMessage={props.quotationError?.markupPercentage} />
        </div>

        <div className="form-group col-lg-2">
          <label className="small required">Show Breakup:</label>
          <div className="d-flex mt-2">
            <div>
              <label className="me-3">
                <input
                  type="radio"
                  value="true"
                  checked={quotationData.showBreakup === true}
                  onChange={(e) =>
                    setQuotationData((old) => ({
                      ...old,
                      showBreakup: e.target.checked,
                    }))
                  }
                />
                Yes{" "}
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="false"
                  checked={quotationData.showBreakup === false}
                  onChange={(e) =>
                    setQuotationData((old) => ({
                      ...old,
                      showBreakup: !e.target.checked,
                    }))
                  }
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-lg-6">
          <label className="small required" htmlFor="internalRemark">
            Internal Remark:
          </label>
          <textarea
            onChange={(e) =>
              setQuotationData((old) => ({
                ...old,
                internalRemark: e.target.value,
              }))
            }
            value={quotationData.internalRemark}
            className="form-control"
          ></textarea>
          <ErrorMessage errorMessage={props.quotationError?.internalRemark} />
        </div>
      </div>
    </form>
  );
};

export default QuotationForm;
