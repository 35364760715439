export const staticTourData = {
  dubai: [
    {
      image:
        "https://www.dubaidesertsafaris.com/wp-content/uploads/2024/04/Untitled-design-1.png",
      title: "Desert Safari",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Embark on an enchanting Arabian voyage, deep into the heart of the spellbinding desert, leaving the hustle and bustle of city life behind.",
    },
    {
      image:
        "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/25/c6/2a/51/caption.jpg?w=500&h=400&s=1",
      title: "Sky Diving",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Experience a thrilling free-fall and a parachute ride with Skydive Dubai, which is one of the top attractions in Dubai. ",
    },
    {
      image:
        "https://media.tacdn.com/media/attractions-splice-spp-720x480/12/50/b9/9d.jpg",
      title: "Burj Khalifa",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "At over 828 metres, Burj Khalifa is the tallest building in the world with an observation deck that offers panoramic city, desert and ocean views.",
    },
    {
      image:
        "https://www.visitdubai.com/-/media/images/leisure/campaigns/dubai-presents/itineraries/spy/spy-media-museum-of-the-future.jpg",
      title: "Museum of the Future",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Situated on Sheikh Zayed Road, the Museum of the Future is an architectural marvel that showcases publications, exhibitions, theatre, and attractions that talk about future possibilities",
    },
  ],
  singapore: [
    {
      image:
        "https://ik.imagekit.io/tvlk/xpe-asset/AyJ40ZAo1DOyPyKLZ9c3RGQHTP2oT4ZXW+QmPVVkFQiXFSv42UaHGzSmaSzQ8DO5QIbWPZuF+VkYVRk6gh-Vg4ECbfuQRQ4pHjWJ5Rmbtkk=/4575897507406/Universal-Studios-Singapore-cbeb8c0f-40f3-467f-bd96-a9280a8ab1c3.jpeg?tr=q-60,c-at_max,w-1280,h-720&_src=imagekit",
      title: "Universal Studio ",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Enjoy a delicious breakfast at the hotel and gear up to spend the day touring the amazing Universal Studio. ",
    },
    {
      image:
        "https://www.trawell.in/admin/images/upload/930772574Sentosa_Island_Singapore.jpg",
      title: "Sentosa Island",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Immerse yourself in an exciting Sentosa experience and make unforgettable memories with your loved ones.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1552415274-73ad7198cb93?q=80&w=1934&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Gardens by the Bay",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "A 101-hectare nature park, Gardens by the Bay houses attractions like Bay Central, Bay East and Bay South gardens, Flower Dome and Cloud Gate conservatories.",
    },
    {
      image:
        "https://images.unsplash.com/flagged/photo-1562503542-2a1e6f03b16b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Singapore City Tour",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Enjoy exploring the wonders of Singapore on this guided city tour that will take you through Merlion, China Town, Little India and more.",
    },
  ],
  bangkok: [
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/62f1cb15a2cb083186ccd6d1/1694077391883-63PTOUL0L2G0UUMF522B/image-asset.jpeg",
      title: "Temple Tour",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "This private tour covers the three most prominent temples in Bangkok. Highly recommended for first-time visitors in the Land of Smiles.",
    },
    {
      image:
        "https://cdn-imgix.headout.com/media/images/5b884c3bbcd927434f8aad7f42584dd9-10466-Bangkok-ChaoPhrayaPrincessCruisewithLiveMusic-Dinner-11.jpg?auto=format&q=90&fit=crop&crop=faces",
      title: "Chao Phraya River Cruise",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "A dinner cruise along the beautiful Chao Phraya River is an unmatched experience in Bangkok. ",
    },
    {
      image:
        "https://res.cloudinary.com/travools1/image/upload/t_highlights1/v1722482780/Thailand%20Collection/Tiger%20park/ym5zaytte1k671mjsidq.jpg",
      title: "Tiger Park",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Tigers always attract human attention. These striped predators at one moment can cause fear, and at another - delight and tenderness. ",
    },
    {
      image:
        "https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_1295,h_861/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/activities/iutiudg46emlwyv6oatn/DamnoenSaduakFloatingMarket,MaeklongRailwayAmphawaDayTour.jpg",
      title: "Shopping Tour",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Take a tour at Bangkok's most famous floating markets & Maeklong train market.",
    },
  ],
  pattaya: [
    {
      image: "https://online-pattaya.com/_ld/1/55497814.jpg",
      title: "Coral Island Pattaya",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "The Coral Island Tour makes for an ideal getaway. Relax on the white sand, go for a swim, or board a glass-bottom boat and watch the aquatic animals underneath your feet.",
    },
    {
      image:
        "https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_1200,h_630/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/activities/ljxzhwoccqwmdsxoas8v/Pattaya%20Floating%20Market.jpg",
      title: "Pattaya Floating Market",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "The Pattaya Floating Market is a traditional Thai water market where vendors in wooden longtail boats sell everything from fruits and vegetables to clothes and souvenirs. ",
    },
    {
      image:
        "https://buycheaptrip.com/assets/images/package/alcazar-show-1.jpg",
      title: "Alcazar Cabaret",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Dazzling performances by artists in glittering evening gowns thrill the audience, as the performers mime and dance against a backdrop of surround-sound and computerized lighting. ",
    },
    {
      image:
        "https://media-cdn.tripadvisor.com/media/attractions-splice-spp-674x446/08/3a/e6/f2.jpg",
      title: "Mini Siam",
      // price: "$465 / Pax",
      // days: "5 Nights 6 Days",
      details:
        "Mini Sim features two zones, one with miniature replicas of famous structures from Thailand and the other one with miniature replicas of structures from cities across the world like Sydney, Paris and New York.",
    },
  ],
};
