import React, { useEffect, useState } from "react";
import EnquiryPageStyle from "./EnquiryPageStyle.module.scss";
import EnquiryListItem from "./EnquiryListItem";
import { activityAxios } from "../../axios/activityAxios";
import CreateEnquiry from "./CreateEnquiry";
import { IEnquiry } from "../../activitiescommonfnb/types/enquiry.interface";
import { IPagination } from "../../activitiescommonfnb/types/common.interface";
import { PaginationButtons } from "../../commonUi/PaginationButtons";
import { EnquiryListFilter } from "./EnquiryListFilter";
import { useAppSelector } from "../../store";
import { useSearchParams } from "react-router-dom";
import FloatingButton from "../../components/common/FloatButton/FloatingButton";
import { Offcanvas } from "react-bootstrap";
import { EnquiryListLoadingSkeleton } from "../../components/Enquiries/EnquiryListLoadingSkeleton";
import Quotation from "./Quotation";
import { TFilterState } from "../../activitiescommonfnb/schemas/enquiry.schema";

const EnquiryList: React.FC<{
  isCustomerView?: boolean;
  kartTotalPrice?: number;
}> = ({ isCustomerView, kartTotalPrice }) => {
  const userData = useAppSelector((state) => state.authData.data);
  const [params, setParams] = useSearchParams();
  const staledEnquiriesDays = params.get("staledEnquiriesDays") || "0";
  const [shouldShowCreateModal, setShouldShowCreateModal] = useState(false);
  const [isEnquiryListLoading, setEnquiryListLoading] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState<IEnquiry>();
  const [showQuotationOffCanvas, setShowQuotationOffCanvas] = useState(false);
  const [enquiries, setEnquiries] = useState<IPagination<IEnquiry[]>>({
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    result: [],
    totalRecords: 0,
    currentPage: 1,
  });
  const [enquiryFilter, setEnquiryFilter] = useState<TFilterState>({
    assignedToStaffId: userData?.id,
    staledEnquiriesDays: staledEnquiriesDays
      ? parseInt(staledEnquiriesDays)
      : undefined,
  });

  const [showEnquiryOffCanvas, setShowEnquiryOffCanvas] = useState(false);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(params);
    if (enquiryFilter.staledEnquiriesDays !== undefined) {
      newSearchParams.set(
        "staledEnquiriesDays",
        enquiryFilter.staledEnquiriesDays.toString()
      );
    }
    setParams(newSearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiryFilter.staledEnquiriesDays, enquiryFilter]);

  const getEnquiries = async (props?: TFilterState) => {
    setEnquiryListLoading(true);

    console.log(props);

    const apiRes = await activityAxios.post("/search/getEnquiries", {
      pageNumber: props?.pageNumber,
      filters: isCustomerView
        ? {
            searchByUserId: userData?.id,
            unassigned: false,
            status: "all",
            staledEnquiriesDays: 0,
            searchTitle: props?.searchTitle,
            leadPassenger: props?.leadPassenger,
          }
        : props,
      orderBy: props?.orderBy,
      order: props?.order,
    });
    if (apiRes.data.success) {
      setEnquiries(apiRes.data.result);
    }
    setEnquiryListLoading(false);
  };

  useEffect(() => {
    getEnquiries(enquiryFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staledEnquiriesDays, enquiryFilter]);

  const toggleSortingOrder = (column: TFilterState["orderBy"]) => {
    setEnquiryListLoading(true);

    setEnquiryFilter((old) => {
      let order = old.order;

      if (old.orderBy === column) {
        order = old.order === "ASC" ? "DESC" : "ASC";
      } else {
        order = "ASC";
      }
      return {
        ...old,
        orderBy: column,
        order: order,
      };
    });
  };

  const toggleQuotationOffCanvas = (k: boolean) => setShowQuotationOffCanvas(k);

  return (
    <div className={EnquiryPageStyle.enquiryPage}>
      <div className={EnquiryPageStyle.enquiryPageHeader}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="m-0">{!isCustomerView ? "Enquiry List" : "Kart"}</h5>
            {!isCustomerView && (
              <button
                className="btn btn-primary btn-sm py-2 px-4"
                onClick={() => {
                  setShouldShowCreateModal(true);
                }}
              >
                + Create Enquiry
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className={`${EnquiryPageStyle.enquiryFilter} sticky-top`}>
              <EnquiryListFilter
                onFilterChange={setEnquiryFilter}
                filters={enquiryFilter}
                staledEnquiriesDays={parseInt(staledEnquiriesDays)}
                isCustomerView={isCustomerView}
              />
            </div>
          </div>
          <div className="col-lg-9">
            <div className="mt-5">
              <div className="d-flex justify-content-between align-items-center mb-3 bg-white p-3 border rounded">
                <h6 className="text-muted mb-0">
                  {!isCustomerView ? "Total Enquiries :" : "Total Kart Items :"}
                  <b className="text-dark"> {enquiries.totalRecords}</b>
                </h6>
                <div className="d-flex flex-row ">
                  <div
                    role="button"
                    className={`btn ms-2 small border  ${
                      enquiryFilter.orderBy === "id" && "bg-primary text-light"
                    }`}
                    onClick={() => {
                      toggleSortingOrder("id");
                    }}
                  >
                    Id{" "}
                    <i
                      className={`fa-solid fa-caret-${
                        enquiryFilter.orderBy === "id" &&
                        enquiryFilter.order === "ASC"
                          ? "up"
                          : "down"
                      }`}
                    ></i>
                  </div>
                  {!isCustomerView && (
                    <div
                      role="button"
                      className={`btn ms-2 small border  ${
                        enquiryFilter.orderBy === "updatedAt" &&
                        "bg-primary text-light"
                      }`}
                      onClick={() => {
                        toggleSortingOrder("updatedAt");
                      }}
                    >
                      Staled Since{" "}
                      <i
                        className={`fa-solid fa-caret-${
                          enquiryFilter.orderBy === "updatedAt" &&
                          enquiryFilter.order === "ASC"
                            ? "up"
                            : "down"
                        }`}
                      ></i>
                    </div>
                  )}
                  <div
                    role="button"
                    className={`btn ms-2 small border  ${
                      enquiryFilter.orderBy === "createdAt" &&
                      "bg-primary text-light"
                    }`}
                    onClick={() => {
                      toggleSortingOrder("createdAt");
                    }}
                  >
                    Created At{" "}
                    <i
                      className={`fa-solid fa-caret-${
                        enquiryFilter.orderBy === "createdAt" &&
                        enquiryFilter.order === "ASC"
                          ? "up"
                          : "down"
                      }`}
                    ></i>
                  </div>
                </div>
              </div>
              <div className={EnquiryPageStyle.enquiryListContainer}>
                {isEnquiryListLoading ? (
                  [...Array(4)].map((_, key) => (
                    <EnquiryListLoadingSkeleton key={key} />
                  ))
                ) : enquiries.result.length ? (
                  enquiries.result.map((enquiry, index) => {
                    return (
                      <EnquiryListItem
                        enquiryFilter={enquiryFilter}
                        key={index}
                        enquiryDetail={enquiry}
                        setEnquiry={(enquiry) => {
                          setEnquiries((old) => {
                            if (old.result[index]) {
                              old.result[index] = enquiry;
                            }
                            return { ...old };
                          });
                        }}
                        getEnquiries={getEnquiries}
                        toggleQuotationOffCanvas={toggleQuotationOffCanvas}
                        setSelectedEnquiry={(enquiry) =>
                          setSelectedEnquiry(enquiry)
                        }
                        showActionButtons={true}
                        isCustomerView={isCustomerView}
                        kartTotalPrice={kartTotalPrice}
                      />
                    );
                  })
                ) : (
                  <div className="border small my-5 py-5 text-center text-danger rounded">
                    {" "}
                    No Enquiry{" "}
                  </div>
                )}
              </div>

              <PaginationButtons
                currentPage={enquiries.currentPage}
                hasNextPage={enquiries.hasNextPage}
                hasPreviousPage={enquiries.hasPreviousPage}
                totalPages={enquiries.totalPages}
                onPageChange={(pageNumber) => {
                  setEnquiryFilter((old) => ({
                    ...old,
                    pageNumber: pageNumber,
                  }));
                  getEnquiries({ ...enquiryFilter, pageNumber: pageNumber });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <FloatingButton
        iconClassName="fa fa-filter"
        onClick={() => {
          setShowEnquiryOffCanvas(true);
        }}
      />

      <Offcanvas
        show={showEnquiryOffCanvas}
        onHide={() => setShowEnquiryOffCanvas(false)}
        id="sp-filter-offcanvas"
      >
        <Offcanvas.Body>
          <EnquiryListFilter
            onFilterChange={(filters) => {
              setEnquiryFilter(filters);
            }}
            filters={enquiryFilter}
            isCustomerView={isCustomerView}
            staledEnquiriesDays={parseInt(staledEnquiriesDays)}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {selectedEnquiry && (
        <Quotation
          selectedEnquiry={selectedEnquiry}
          showQuotationOffCanvas={showQuotationOffCanvas}
          setShowQuotationOffCanvas={setShowQuotationOffCanvas}
          isCustomerView={isCustomerView}
        />
      )}

      {shouldShowCreateModal && (
        <CreateEnquiry
          show={shouldShowCreateModal}
          onClose={() => {
            setShouldShowCreateModal(false);
          }}
          onSave={() => {
            getEnquiries(enquiryFilter);
            setShouldShowCreateModal(false);
          }}
        />
      )}
    </div>
  );
};

export default EnquiryList;
