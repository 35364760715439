import { getTotalPrice } from "../../slice/session.slice";
import { useAppSelector } from "../../store";
import EnquiryList from "../Enquiry/EnquiryList";

const CustomerCart = () => {
  const totalPrice = useAppSelector(getTotalPrice);

  return (
    <div>
      <EnquiryList isCustomerView={true} kartTotalPrice={totalPrice} />
    </div>
  );
};

export default CustomerCart;
