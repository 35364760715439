import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { activityAxios } from "../axios/activityAxios";
import {
  IBookingPassenger,
  ICartItem,
  ISession,
  ISessionWithCartDetail,
} from "../activitiescommonfnb/types/booking.interface";
import { toast } from "react-toastify";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";
import { isTourOptionOverlapping } from "../helper/reduxHelper";
import { RootState } from "../store";

const initialState: {
  activeSession: ISessionWithCartDetail | null;
  loading: boolean;
} = {
  activeSession: null,
  loading: false,
};

export const generateNewSession = createAsyncThunk(
  "cart/generateNewSession",
  async (sessionData: ISession, thunkAPI) => {
    const apiRes = await activityAxios.post<IJSONResponse<ISession>>(
      "/booking/generateSession",
      {
        sessionData,
      }
    );
    if (apiRes.data.success && apiRes.data.result) {
      return apiRes.data.result;
    }
    throw new Error(apiRes.data.errorMessage);
  }
);

export const activitySessionSlice = createSlice({
  name: "activitySession",
  initialState,
  reducers: {
    setActiveSessionData: (
      state,
      action: PayloadAction<ISessionWithCartDetail | null>
    ) => {
      state.activeSession = action.payload;
    },

    addTourOptionInActiveSession: (state, action: PayloadAction<ICartItem>) => {
      if (!state.activeSession) return;

      const { isComboTour, tourId, tourOptions } = action.payload;
      const existingTourIndex = state.activeSession.cartItems.findIndex(
        (cartItem) => cartItem.tourId === tourId
      );

      let tourOptionOverlaps = false;
      action.payload.tourOptions.forEach((tourOption) => {
        if (tourOption.startTime && state.activeSession?.cartItems.length) {
          tourOptionOverlaps = isTourOptionOverlapping(
            tourOption,
            state.activeSession?.cartItems
          );
        }
      });
      if (tourOptionOverlaps) {
        toast.warning("Tour overlaps with an existing activity.");
        return;
      }
      if (isComboTour) {
        if (existingTourIndex > -1) {
          toast.warning("Tour already exists in the cart.");
          return;
        }
        state.activeSession.cartItems.push(action.payload);
        return;
      }

      const existingCartItem = state.activeSession.cartItems[existingTourIndex];

      if (existingCartItem) {
        const existingTourOption = existingCartItem.tourOptions.find(
          (option) => option.tourOptionId === tourOptions[0].tourOptionId
        );

        if (existingTourOption) {
          toast.warning("Item already exists in the cart.");
        } else {
          existingCartItem.tourOptions.push(tourOptions[0]);
        }
      } else {
        state.activeSession.cartItems.push({
          tourId,
          isComboTour: false,
          tourOptions: [tourOptions[0]],
        });
      }
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    deleteTourOption: (
      state,
      action: PayloadAction<{
        tourId: number;
        tourOptionId: number;
      }>
    ) => {
      if (state.activeSession) {
        const cartItemIndex = state.activeSession.cartItems.findIndex(
          (cartItem) => cartItem.tourId === action.payload.tourId
        );

        const tourOptionIndexToDelete = state.activeSession.cartItems[
          cartItemIndex
        ].tourOptions.findIndex(
          (bookingTourOption) =>
            bookingTourOption.tourOptionId === action.payload.tourOptionId
        );

        if (tourOptionIndexToDelete >= 0) {
          state.activeSession.cartItems[cartItemIndex].tourOptions.splice(
            tourOptionIndexToDelete,
            1
          );

          if (
            !state.activeSession.cartItems[cartItemIndex].tourOptions.length
          ) {
            state.activeSession.cartItems.splice(cartItemIndex, 1);
          }
        }
      }
    },

    setPassangers: (state, action: PayloadAction<IBookingPassenger[]>) => {
      if (state.activeSession) {
        state.activeSession.bookingPassengers = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      generateNewSession.fulfilled,
      (state, action: PayloadAction<any>) => {
        // state = initialState;
      }
    );
  },
});

export const ActivitySessionActions = activitySessionSlice.actions;

// export const getTotalPrice = createSelector)) => {
//   let x = 0;
//   state.activitySession.activeSession?.cartItems.forEach((x) => {
//     // x+=
//   });
//   return x;
// };

export const getTotalPrice = createSelector(
  (state: RootState) => state.activitySession,
  (activitySession) => {
    let totalPrice = 0;

    activitySession.activeSession?.cartItems
      ?.flatMap((carItem) => carItem.tourOptions)
      .forEach((tourOption) => {
        totalPrice +=
          Math.ceil(tourOption.adultPrice) +
          Math.ceil(tourOption.childPrice) +
          Math.ceil(tourOption.infantPrice) +
          Math.ceil(tourOption.totalTransferPrice);
      });

    return totalPrice;
  }
);

export default activitySessionSlice.reducer;
