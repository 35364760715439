import React, { useState } from "react";
import { TTourOption } from "../../../activitiescommonfnb/schemas/tour.schema";
import tourOptionItemStyle from "./TourOptionItemStyle.module.scss";
import { ManageTransferModal } from "../../admin/Tours/AdminTourOptions/ManageTransfer";

type TWeekDay =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

const weekDays: Array<{
  day: TWeekDay;
  short: string;
}> = [
  { day: "sunday", short: "S" },
  { day: "monday", short: "M" },
  { day: "tuesday", short: "T" },
  { day: "wednesday", short: "W" },
  { day: "thursday", short: "T" },
  { day: "friday", short: "F" },
  { day: "saturday", short: "S" },
];

const TourOptionItem: React.FC<{
  TourOptionDetails: TTourOption;
  showOptions?: boolean;
}> = (props) => {
  const [showTransferModal, setShowTransferModal] = useState(false);

  return (
    <div className={tourOptionItemStyle.TourOptionItem}>
      <div className="row align-items-center">
        <div className={props.showOptions ? "col-lg-8" : ""}>
          <div className="">{props.TourOptionDetails.tourOptionName}</div>
          {props.showOptions && (
            <span
              className="small"
              dangerouslySetInnerHTML={{
                __html: props.TourOptionDetails.tourOptionDescription || "",
              }}
            ></span>
          )}
          <div className={` ${tourOptionItemStyle.tourOptionWeek} small`}>
            {weekDays.map((day) => {
              return (
                <span
                  className={`${tourOptionItemStyle.tourOptionweekDays} ${
                    props.TourOptionDetails[day.day]
                      ? `${tourOptionItemStyle.tourOptionweekDaysActive}`
                      : `${tourOptionItemStyle.tourOptionweekDaysNotActive}`
                  }`}
                >
                  {day.short}
                </span>
              );
            })}
          </div>
          <span className="small">
            Ticket Only Sale Allowed :{" "}
            <b>
              {props.TourOptionDetails.isTicketOnlySaleAllowed ? "Yes" : "No"}
            </b>
          </span>
        </div>
        {props.showOptions && (
          <>
            <div className="col-lg-2">
              <div>
                <span className="small text-muted"> Child Age : </span>
                {props.TourOptionDetails.childAge}
              </div>
              <div>
                <span className="small text-muted"> Infant Age : </span>
                {props.TourOptionDetails.infantAge}
              </div>
              <div>
                <span className="small text-muted">
                  {" "}
                  Created By :
                  <b>
                    {" "}
                    {props.TourOptionDetails.createdByStaffDetail?.firstName ||
                      "NA"}
                  </b>
                </span>
              </div>
            </div>
            <div className="col-lg-2">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setShowTransferModal(true);
                }}
              >
                Manage Transfers
              </button>
            </div>
          </>
        )}
      </div>

      {showTransferModal && props.TourOptionDetails.id && (
        <ManageTransferModal
          tourOptionId={props.TourOptionDetails.id}
          onClose={() => {
            setShowTransferModal(false);
          }}
        />
      )}
    </div>
  );
};

export default TourOptionItem;
