import { TDestinationGroupSchema } from "../schemas/master.schema";
import { EnumTourOptionTransferType } from "./tour.interface";

export interface ICity {
  id: number | null;
  name: string;
}

export interface IDestinationGroup
  extends Omit<TDestinationGroupSchema, "cityIds" | "id"> {
  id: number | null;
  cities: ICity[];
}

export interface IDestinationGroupTabs {
  id: number;
  title: string;
}

export const destinationGroupTemplate = {
  id: null,
  title: "",
  remark: "",
  termsAndConditions: "",
  cities: [{ id: null, name: "" }],
};

export interface IGaps {
  startDay: number;
  endDay: number;
}

export interface ILines {
  endDay: number;
  bars: JSX.Element[];
}

export enum EnumPassengerType {
  ADULT = "adult",
  CHILD = "child",
}

export interface IManagePriceForm {
  transferType: EnumTourOptionTransferType;
  fromPax?: number;
  toPax?: number;
  adultPrice?: number;
  childPrice?: number;
  pricePerPax?: number;
  seasonId: number;
  priceSourceId: number;
  remark?: string;
}

export interface ISeasonWithCountry {
  countryId: number;
  priority: number;
  fromDate: Date;
  toDate: Date;
  name: string;
  id?: number;
}

export interface IHotelTabs {
  id: number;
  name: string;
}
