import { toast } from "react-toastify";
import Price from "../../commonUi/Price";
import { useAppSelector } from "../../store";
import { CartItem } from "./CartItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Fragment } from "react";
import { getAuthToken } from "../../functions/commonFx";
import cartItemStyle from "./CartItemStyle.module.scss";
import { getTotalPrice } from "../../slice/session.slice";

export const CartWrapper: React.FC<{
  showCartItems: boolean;
  showCartData?: boolean;
}> = (props) => {
  const { showCartData = true } = props;
  const [searchParams] = useSearchParams();
  const sessionSlug = searchParams.get("sessionSlug");
  const totalPrice = useAppSelector(getTotalPrice);

  const navigate = useNavigate();
  const token = getAuthToken();
  const { data: userData } = useAppSelector((state) => state.authData);
  const { moduleUrls } = useAppSelector((state) => state.siteData);
  const activeSessionDetails = useAppSelector(
    (state) => state.activitySession.activeSession
  );

  const checkout = async () => {
    navigate(`/checkout/${sessionSlug}`);
  };

  const cartDetails = async () => {
    if (activeSessionDetails?.cartItems.length === 0) {
      toast.error("Kart is empty");
      return;
    }

    if (!token || !userData) {
      navigate("/login");
      return;
    }
    navigate("/checkout/cartDetails");
  };

  return (
    <Fragment>
      {!(activeSessionDetails?.cartItems.length === 0) && showCartData && (
        <div
          className={cartItemStyle.comboWrapper}
          id={cartItemStyle.resultPageKartContainer}
        >
          {activeSessionDetails?.cartItems.map((cartItem) =>
            cartItem.tourOptions.map((singleTourOption) => (
              <CartItem
                tourOptionDetail={singleTourOption!}
                showMoreDetails={false}
                key={singleTourOption.id}
              />
            ))
          )}
        </div>
      )}

      {!(activeSessionDetails?.cartItems.length === 0) ? (
        <div
          role="button"
          onClick={props.showCartItems ? cartDetails : checkout}
          className="p-3 d-flex justify-content-between align-items-center bg-secondary text-white rounded my-3"
        >
          <div className=" d-flex ">
            <div className="fw-bold text-white">Total: </div>
            <Price amount={totalPrice} currency="₹" className="fw-bold" />
          </div>
          <div>
            <div className="small">
              {`${props.showCartItems ? " Kart Details" : "Checkout"}`}{" "}
              <i className="fa-solid fa-caret-right"></i>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3 d-flex justify-content-center align-items-center bg-secondary text-white rounded my-3">
          Kart is empty
        </div>
      )}
    </Fragment>
  );
};
