import React from "react";
import activityCardStyle from "./activityCardStyle.module.scss";

const FrontPageActivityCard: React.FC<{
  activityData: {
    image: string;
    title: string;
    // price: string;
    // days: string;
    details: string;
  };
}> = (props) => {
  return (
    <div className={activityCardStyle.packageItem}>
      <img src={props.activityData.image} className="img-fluid" alt="" />
      <div className={activityCardStyle.content}>
        {/* <div className={activityCardStyle.days}>{props.activityData.days}</div> */}
        <h6>{props.activityData.title}</h6>
        <p className="small">{props.activityData.details}</p>
        <p className="mt-2">
          {/* <span className="small me-2">From</span> */}
          {/* <span className="fw-bold">{props.activityData.price}</span> */}
        </p>
        {/* <button className="btn btn-primary rounded btn-sm w-100 mt-2">
                    Show Details
                  </button> */}
      </div>
    </div>
  );
};

export default FrontPageActivityCard;
