import React, { useEffect, useState } from "react";
import checkoutPageStyle from "./CheckoutPageStyle.module.scss";
import { useForm, useFieldArray } from "react-hook-form";
import {
  EnumPaxTitle,
  EnumPaxType,
  TBookingTourOptionPaxMapping,
  TSetBookingPassenger,
  setBookingPassengerZod,
} from "../../activitiescommonfnb/schemas/booking.schema";
import { AppControlInput } from "../../commonUi/AppInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { capitalize } from "lodash";
import { ControlledAppSelect, IOptions } from "../../commonUi/AppSelect";
// import { appAxios } from "../axios/appAxios";
import { IntlTelInputWrapper } from "../../commonUi/IntlTelInputWrapper";
import {
  IBookingPassenger,
  ISessionWithCartDetail,
} from "../../activitiescommonfnb/types/booking.interface";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  ActivitySessionActions,
  getTotalPrice,
} from "../../slice/session.slice";
import { useAppSelector } from "../../store";
import { Modal, Placeholder } from "react-bootstrap";
import { CartItem } from "../../components/cart/CartItem";
import Price from "../../commonUi/Price";
import cartItemStyle from "../../components/cart/CartItemStyle.module.scss";
import { MasterService } from "../../services/Master.service";

const PassengerFieldSkeleton = () => {
  return (
    <div>
      <div className="p-2 pb-0 mb-2">
        <Placeholder as="p" animation="glow">
          <Placeholder xs={2} size="sm" />
        </Placeholder>
      </div>
      <div className="row">
        <div className="col-lg-2 ">
          <div className="p-2 pb-0 mb-2">
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
        <div className="col-lg-5 ">
          <div className="p-2 pb-0 mb-2">
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
        <div className="col-lg-5 ">
          <div className="p-2 pb-0 mb-2">
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
        <div className="col-lg-5 ">
          <div className="p-2 pb-0 mb-2">
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
        <div className="col-lg-2 ">
          <div className="p-2 pb-0 mb-2">
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="p-2 pb-0 mb-2">
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
      </div>
    </div>
  );
};

type TFormDetails = {
  passengers: TSetBookingPassenger[];
};
interface ICountryData {
  countryName: string;
  countryCode: string;
}

const DEFAULT_COUNTRY_CODE = "IN";

const CheckoutPage: React.FC = () => {
  const {
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<TFormDetails>({
    defaultValues: {
      passengers: [],
    },
    resolver: zodResolver(
      z.object({
        passengers: setBookingPassengerZod,
      })
    ),
  });
  const [loadingSessionData, setLoadingSessionData] = useState(false);
  const [showPassengersModal, setShowPassengersModal] = useState(false);
  // const [sessionDataState, setSessDataState] = useState<ISession | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalPrice = useAppSelector(getTotalPrice);
  const params = useParams<{
    sessionSlug: string;
  }>();

  const { fields: passengerFeilds } = useFieldArray({
    control,
    name: "passengers",
  });

  const [countryDataOptions, setCountryDataOptions] = useState<Array<IOptions>>(
    []
  );

  const paxTitleOptions = [
    {
      label: "Mr.",
      value: "Mr",
    },
    {
      label: "Mrs.",
      value: "Mrs",
    },
    {
      label: "Miss.",
      value: "Miss",
    },
    {
      label: "Master.",
      value: "Master",
    },
  ];

  const getSessionDetails = async () => {
    setLoadingSessionData(true);

    const apiResponse = await activityAxios.post<
      IJSONResponse<ISessionWithCartDetail>
    >("/booking/getSessionDetailsBySessionSlug", {
      sessionSlug: params.sessionSlug,
    });

    if (apiResponse.data.success && apiResponse.data.result) {
      // setSessDataState(apiResponse.data.result);
      dispatch(
        ActivitySessionActions.setActiveSessionData(apiResponse.data.result)
      );
      if (
        apiResponse.data.result.bookingPassengers &&
        apiResponse.data.result.bookingPassengers.length
      ) {
        // reset state
        reset({
          passengers: apiResponse.data.result.bookingPassengers,
        });
      } else {
        const { adultCount, childCount, infantCount } =
          apiResponse.data.result.cartItems
            .flatMap((c) => c.tourOptions)
            .reduce(
              (data, singleTourOption) => {
                data.adultCount = Math.max(
                  data.adultCount,
                  singleTourOption.adultCount
                );

                data.childCount = Math.max(
                  data.childCount,
                  singleTourOption.childCount
                );

                data.infantCount = Math.max(
                  data.infantCount,
                  singleTourOption.infantCount
                );

                return data;
              },
              {
                adultCount: 0,
                childCount: 0,
                infantCount: 0,
              }
            );

        initilizePaxInState(adultCount, childCount, infantCount);
      }
    } else {
      toast.error(apiResponse.data.errorMessage || "Session not found.");
    }
    setLoadingSessionData(false);
  };

  const initilizePaxInState = (
    adultCount: number,
    childCount: number,
    infantCount: number
  ) => {
    const defaultPaxDetails: TSetBookingPassenger[] = [];
    let paxIndex = 0;

    const singlePaxDetail: TSetBookingPassenger = {
      email: "",
      firstName: "",
      lastName: "",
      leadPassenger: false,
      nationality: DEFAULT_COUNTRY_CODE,
      paxIndex: 0,
      paxType: EnumPaxType.ADULT,
      phoneCode: "",
      phoneNumber: "",
      title: EnumPaxTitle.MR,
    };

    for (let adultIndex = 0; adultIndex < adultCount; adultIndex++) {
      singlePaxDetail.paxIndex = paxIndex;
      singlePaxDetail.paxType = EnumPaxType.ADULT;
      singlePaxDetail.leadPassenger = adultIndex === 0;
      defaultPaxDetails.push({ ...singlePaxDetail });
      paxIndex += 1;
    }

    for (let childIndex = 0; childIndex < childCount; childIndex++) {
      singlePaxDetail.title = EnumPaxTitle.MASTER;
      singlePaxDetail.paxIndex = paxIndex;
      singlePaxDetail.paxType = EnumPaxType.CHILD;
      singlePaxDetail.leadPassenger = false;
      defaultPaxDetails.push({ ...singlePaxDetail });
      paxIndex++;
    }

    for (let infantIndex = 0; infantIndex < infantCount; infantIndex++) {
      singlePaxDetail.title = EnumPaxTitle.MASTER;
      singlePaxDetail.paxIndex = paxIndex;
      singlePaxDetail.paxType = EnumPaxType.INFANT;
      singlePaxDetail.leadPassenger = false;
      defaultPaxDetails.push({ ...singlePaxDetail });
      paxIndex++;
    }

    reset({
      passengers: defaultPaxDetails,
    });
  };

  const getCountryData = async () => {
    try {
      //removing appAxios from activities

      const countriesResponse = await MasterService.getCountries();

      if (countriesResponse.data.success) {
        const countryData = countriesResponse.data.result || [];
        const indiaCountry = countryData.splice(
          countryData.findIndex((f) => f.countryCode === DEFAULT_COUNTRY_CODE),
          1
        )[0];
        countryData.unshift(indiaCountry);
        setCountryDataOptions(
          countryData.map((c) => {
            return {
              label: c.countryName,
              value: c.countryCode,
            };
          })
        );
      }
    } catch (error) {
      // error
    }
  };

  const setPassengers = async (data: TFormDetails) => {
    const tourOptionPaxMapping: TBookingTourOptionPaxMapping = [];

    if (activeSessionData) {
      activeSessionData.cartItems
        .flatMap((cartItem) => cartItem.tourOptions)
        .forEach((bookingTourOption) => {
          data.passengers.forEach((pax) => {
            if (!bookingTourOption.tourOptionId) {
              throw new Error("Tour option not found.");
            }
            tourOptionPaxMapping.push({
              paxIndex: pax.paxIndex,
              tourOptionId: bookingTourOption.tourOptionId,
            });
          });
        });
    }

    const apiResponse = await activityAxios.post<
      IJSONResponse<IBookingPassenger[]>
    >("/booking/setPassengers", {
      passengers: data.passengers,
      tourOptionPaxMapping: tourOptionPaxMapping,
      sessionSlug: activeSessionData?.sessionSlug,
    });

    if (apiResponse.data.success && apiResponse.data.result) {
      dispatch(ActivitySessionActions.setPassangers(apiResponse.data.result));
      if (apiResponse.data.result && apiResponse.data.success) {
        setShowPassengersModal(true);
      }
    } else {
      throw new Error(apiResponse.data.errorMessage);
    }
  };

  const activeSessionData = useAppSelector(
    (state) => state.activitySession.activeSession
  );

  const createTourBooking = async () => {
    const apiRes = await activityAxios.post("/booking/book", {
      sessionSlug: activeSessionData?.sessionSlug,
    });
    if (apiRes.data.success) {
      toast.success("Booking created successfully!");
      dispatch(ActivitySessionActions.setActiveSessionData(null));
      setShowPassengersModal(false);
      navigate("/tourbookingsqueue");
    } else {
      toast.error(apiRes.data.errorMessage || "Something went wrong");
    }
  };

  useEffect(() => {
    getSessionDetails();
    getCountryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={checkoutPageStyle.checkoutPage}>
      <div className="page-float-background">
        <div className="bg-image"></div>
      </div>
      <div id={checkoutPageStyle.completeBooking}>
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div
                className={`${checkoutPageStyle.heading} text-primary bg-white px-3 py-3 mb-3 rounded`}
              >
                Complete your Booking
              </div>
              <div className={`${checkoutPageStyle.box} passengerDetails`}>
                <div className="mb-3 text-primary">
                  <h6>
                    <i className="fa-solid fa-user-group me-2 "></i>
                    Passenger Details
                  </h6>
                </div>
                <div className="form">
                  {passengerFeilds?.map((singlePax, paxIndex) => {
                    return (
                      <>
                        <div className="text-secondary small fw-bold mb-3 bg-light rounded p-3">
                          {singlePax.leadPassenger && (
                            <span className="me-1 text-muted">Lead</span>
                          )}
                          Passenger {paxIndex + 1} (
                          {capitalize(singlePax.paxType)})
                        </div>
                        <div className="row pb-3" key={singlePax.id}>
                          <ControlledAppSelect
                            id={`${singlePax.id}-title`}
                            name={`passengers.${paxIndex}.title`}
                            control={control}
                            options={paxTitleOptions}
                            containerClassName="col-lg-2 small mb-3"
                            label="Title"
                          />
                          <AppControlInput
                            id={`${singlePax.id}-firstName`}
                            label="First Name"
                            containerClassName="col-lg-5 small mb-3"
                            name={`passengers.${paxIndex}.firstName`}
                            control={control}
                          />
                          <AppControlInput
                            id={`${singlePax.id}-lastName`}
                            label="Last Name"
                            containerClassName="col-lg-5 small mb-3"
                            name={`passengers.${paxIndex}.lastName`}
                            control={control}
                          />
                          <AppControlInput
                            id={`${singlePax.id}-email`}
                            label="Email"
                            containerClassName="col-lg-4 small mb-3"
                            name={`passengers.${paxIndex}.email`}
                            control={control}
                          />
                          <ControlledAppSelect
                            id={`${singlePax.id}-nationality`}
                            name={`passengers.${paxIndex}.nationality`}
                            control={control}
                            options={countryDataOptions}
                            containerClassName="col-lg-3 small mb-3"
                            label="Nationality"
                          />
                          <IntlTelInputWrapper
                            containerClassName="col-lg-5 small mb-3"
                            label="Phone number"
                            errorMessage={
                              formState.errors?.passengers?.[paxIndex]
                                ?.phoneNumber?.message
                            }
                            defaultValue={getValues(
                              `passengers.${paxIndex}.phoneNumber`
                            )}
                            onChange={(phoneNumber, phoneCode, isValid) => {
                              setValue(
                                `passengers.${paxIndex}.phoneCode`,
                                phoneCode
                              );
                              setValue(
                                `passengers.${paxIndex}.phoneNumber`,
                                phoneNumber
                              );
                              if (!isValid) {
                                setError(`passengers.${paxIndex}.phoneNumber`, {
                                  message: "Please enter a valid number",
                                });
                              } else {
                                clearErrors(
                                  `passengers.${paxIndex}.phoneNumber`
                                );
                              }
                            }}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>

                {loadingSessionData && (
                  <>
                    <PassengerFieldSkeleton />
                  </>
                )}
              </div>
              {/* <div className={`${checkoutPageStyle.box} remarkDetails`}>
                <div className="d-flex align-items-baseline">
                  <h6>Dhow Cruise Dinner - Creek</h6>{" "}
                  <span className="small text-secondary fw-bold ms-2">
                    Without-Transfer
                  </span>
                </div>
                <textarea
                  className="form-control"
                  placeholder="Remark"
                  rows={1}
                  name=""
                  id=""
                />
              </div> */}
              <div className="my-2 text-end">
                <button
                  onClick={handleSubmit(setPassengers)}
                  className="btn btn-sm btn-primary p-2 px-5"
                >
                  Continue{" "}
                  <i className="fa-solid fa-arrow-right ms-1 small"></i>
                </button>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="text-primary bg-white p-3 mb-3 rounded fw-bold">
                Cart
              </div>
              <div className="">
                {activeSessionData?.cartItems.map((cartItem) => {
                  return (
                    <div
                      className={`${
                        cartItem.isComboTour ? `${cartItemStyle.cartItem}` : ""
                      }`}
                    >
                      {cartItem.isComboTour && (
                        <div className={`${cartItemStyle.comboHeading}`}>
                          Combo of {cartItem.tourOptions.length}
                        </div>
                      )}
                      {cartItem.tourOptions.map((singleTourOption) => (
                        <CartItem
                          tourOptionDetail={singleTourOption}
                          showMoreDetails={true}
                          key={singleTourOption.id}
                        />
                      ))}
                    </div>
                  );
                })}
              </div>
              <div
                className={`${checkoutPageStyle.box} ${checkoutPageStyle.finalPayment}`}
              >
                <div className="d-flex justify-content-between align-items-center mb-3 pb-2 border-bottom">
                  <h6 className="fw-bold">Final Payment</h6>
                  <span className="fw-bold fs-5 text-primary">
                    {" "}
                    <Price amount={totalPrice} currency="₹" />
                  </span>
                </div>
                <div className="d-flex justify-content-between my-2">
                  <span className="text-muted">Total Amount Incl. VAT :</span>

                  <span className="fw-bold text-muted">
                    {" "}
                    <Price amount={totalPrice} currency="₹" />
                  </span>
                </div>
                <div className="d-flex justify-content-between my-2">
                  <span className="text-muted">
                    Internet Handling Charges (2.2%):
                  </span>
                  <span className="fw-bold text-muted">
                    <Price amount={totalPrice} currency="₹" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showPassengersModal}
        onHide={() => {
          setShowPassengersModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Pax Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row small">
            <div className="col-1">Pax index</div>
            <div className="col-2">Pax Name</div>
            <div className="col-2">Pax Email</div>
            <div className="col-2">Phone Number</div>
            <div className="col-1">Nationality</div>
            <div className="col-2">Lead Passenger</div>
            <div className="col-2">Pax Type</div>
          </div>
          {activeSessionData?.bookingPassengers?.map((passenger) => {
            return (
              <div className="row small">
                <div className="col-1">{passenger.paxIndex}</div>
                <div className="col-2">
                  {passenger.firstName} {passenger.lastName}
                </div>
                <div className="col-2">{passenger.email}</div>
                <div className="col-2">
                  {passenger.phoneCode} {passenger.phoneNumber}
                </div>
                <div className="col-1">{passenger.nationality}</div>
                <div className="col-2">{passenger.leadPassenger}</div>
                <div className="col-2">{passenger.paxType}</div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn bg-secondary text-white"
            onClick={() => setShowPassengersModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={createTourBooking}>
            Book
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CheckoutPage;
