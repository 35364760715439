import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AppRegisterInput } from "../../../commonUi/AppInput";
import {
  hotelRoomSchema,
  THotelRoomZod,
} from "../../../activitiescommonfnb/schemas/hotel.schema";
import { activityAxios } from "../../../axios/activityAxios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const CreateOrEditHotelRoom: React.FC<{
  roomModal: boolean;
  hotelId: number | undefined;
  updatePage: () => void;
  handleRoomModal: () => void;
  isAddModal: boolean;
  selectedRoom: THotelRoomZod | undefined;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<THotelRoomZod>({
    resolver: zodResolver(hotelRoomSchema),
  });

  const onSubmit = async (data: THotelRoomZod) => {
    setLoading(true);
    const apiRes = await activityAxios.post("/hotel/createOrEditHotelRoom", {
      hotelData: data,
    });
    if (apiRes.data.success && apiRes.data.result) {
      toast.success("Room saved sucessfully");
      props.handleRoomModal();
      props.updatePage();
    } else {
      toast.error("Failed to saved room");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!props.isAddModal && props.selectedRoom) {
      Object.entries(props.selectedRoom).forEach(([key, value]) => {
        setValue(key as keyof THotelRoomZod, value);
      });
    } else if (props.hotelId) {
      reset();
      setValue("hotelId", props.hotelId);
    }
  }, [props.roomModal]);

  return (
    <>
      <Modal show={props.roomModal} onHide={props.handleRoomModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Hotel Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="form-label">Category</label>
              <AppRegisterInput
                register={register}
                name="category"
                required
                errorMessage={errors.category?.message}
                type="text"
              />
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.handleRoomModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Save"}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateOrEditHotelRoom;
