import { useEffect, useState } from "react";
import { CitySelect } from "../admin/Tours/CitySelect";
import { SingleTourListItem } from "../admin/Tours/SingleTourListItem";
import { TCity, TTour } from "../../activitiescommonfnb/schemas/master.schema";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { activityAxios } from "../../axios/activityAxios";
import { EnumUser } from "../../types/tourSearch.interface";
import { ITour } from "../../activitiescommonfnb/types/tour.interface";

export const SupplierTourOptionList: React.FC<{}> = () => {
  const [selectedCity, setSelectedCity] = useState<Required<TCity>>();
  const [tours, setTours] = useState<ITour[]>([]);
  const [loadingTours, setLoadingTours] = useState(false);

  const getTours = async (slug: string) => {
    setTours([]);
    setLoadingTours(true);
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/master/getToursByCitySlug",
      {
        citySlug: slug,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      setTours(apiRes.data.result);
    }
    setLoadingTours(false);
  };
  useEffect(() => {
    if (selectedCity) {
      getTours(selectedCity.slug);
    } else {
      setTours([]);
    }
  }, [selectedCity]);
  return (
    <div className="container">
      <h4 className="mb-4 mt-5">Supplier Tour List</h4>
      <label htmlFor="">Location</label>
      <CitySelect
        className="position-relative z-10"
        onCityChange={(city) => {
          setSelectedCity(city);
        }}
      />

      <div className="mt-3">
        {selectedCity?.name && (
          <div className="mb-3">
            <span className="text-muted">{selectedCity?.name}</span>
            <span className="text-muted">{""}</span>
            <span>
              <i className="fa-solid fa-angle-right mx-2 text-muted"></i>
            </span>
            <span className="fs-5">Tour List</span>
          </div>
        )}
        <h4>{selectedCity?.name} </h4>
        {tours.map((singleTour, index) => {
          return (
            <SingleTourListItem
              userType={EnumUser.SUPPLIER}
              getTours={() => {
                if (selectedCity) {
                  getTours(selectedCity.slug);
                }
              }}
              key={singleTour.id}
              tourDetails={singleTour}
            />
          );
        })}

        {loadingTours && <span>Loading...</span>}
      </div>

      {/* {showCreateModal && (
        <CreateOrEditTourModal
          show={showCreateModal}
          onClose={() => {
            setShowCreateModal(false);
          }}
          onSave={() => {
            setShowCreateModal(false);
            if (selectedCity) {
              getTours(selectedCity.slug);
            }
          }}
        />
      )} */}
    </div>
  );
};
