import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface IDateRange {
  from: Date | null;
  to: Date | null;
}

type TAppRangeDatePickerProps = Omit<
  ReactDatePickerProps,
  "onChange" | "selectsRange" | "startDate" | "endDate"
> & {
  label?: string;
  onChange: (dates: IDateRange) => void;
  dateRange: IDateRange;
  errorMessage?: string;
  containerClassName?: string;
};

export const AppRangeDatePicker: React.FC<TAppRangeDatePickerProps> = ({
  label,
  dateRange,
  containerClassName,
  onChange,
  errorMessage,
  disabled,
  ...datePickerprops
}) => {
  return (
    <div className={containerClassName || ""}>
      {label && (
        <label className="small" htmlFor={datePickerprops.id}>
          {label}
        </label>
      )}
      <DatePicker
        disabled={!disabled}
        dateFormat={"d MMM, yyyy"}
        startDate={dateRange.from}
        endDate={dateRange.to}
        selectsRange={true}
        onChange={(dates) => {
          onChange({
            from: dates[0],
            to: dates[1],
          });
        }}
        {...datePickerprops}
        className={`form-control ${datePickerprops.className}`}
      />
    </div>
  );
};
