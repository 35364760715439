import React from "react";
import { Offcanvas } from "react-bootstrap";
import { ActivitySearch } from "../../components/common/Search/ActivitySearch";
import { useAppDispatch } from "../../store";
import { getTourResults } from "../../slice/tourResult.slice";
import moment from "moment";

const ModifyCanvas: React.FC<{
  show: boolean;
  onHide: () => void;
  citySlug: string | undefined;
  fromDate: string | null;
  toDate: string | null;
}> = ({ show, onHide, citySlug, fromDate, toDate }) => {
  const dispatch = useAppDispatch();

  return (
    <Offcanvas show={show} onHide={onHide} placement="top">
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title className="small">
          {" "}
          <div className="text-capitalize fw-bold">{citySlug}</div>
          <div>
            {moment(fromDate).format("ll")}-{moment(toDate).format("ll")}
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ActivitySearch
          isModifySearch
          isDisabled={false}
          onSearch={(citySlug, from, to, paxInfo) => {
            dispatch(
              getTourResults({
                citySlug: citySlug,
                from: from,
                to: to,
                paxInfo: paxInfo,
              })
            );
          }}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ModifyCanvas;
