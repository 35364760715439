import { z } from "zod";

export const hotelRoomSchema = z.object({
  id: z.number().optional(),
  hotelId: z.number(),
  category: z.string(),
});

export type THotelRoomZod = z.infer<typeof hotelRoomSchema>;

export const hotelSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1),
  address: z.string().min(1),
  cityId: z.number(),
  countryId: z.number(),
  phone: z.string(),
  star: z.number(),
  email: z.string().min(1),
  rooms: z.array(hotelRoomSchema).optional(),
});

export type THotelZod = z.infer<typeof hotelSchema>;
