import React from "react";
import Table from "react-bootstrap/Table";

type PriceData = {
  pax: number;
  sharingBasis: number;
  privateBasis: number;
  ticketOnlyAdult?: number;
  ticketOnlyChild?: number;
  privateBasisRemark: string;
  sharingBasisRemark: string;
  ticketOnlyRemark: string;
  privateBasisPriceSource: string;
  sharingBasisPriceSource: string;
  ticketOnlyPriceSource: string;
};

type PriceChartProps = {
  data: PriceData[];
};

const PriceChart: React.FC<PriceChartProps> = ({ data }) => {
  const headers = [
    "Type",
    "Private Basis",
    "Sharing Basis",
    "Ticket",
    "Private Price Source",
    "Sharing Price Source",
    "Ticket Price Source",
    "Private Remark",
    "Sharing Remark",
    "Ticket Remark",
  ];
  const ticketOnlySubHeaders = ["Adult", "Child"];

  return (
    <Table responsive className="price-chart w-100">
      <thead className="bg-primary text-dark">
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              className="w-25"
              colSpan={header === "Ticket" ? ticketOnlySubHeaders.length : 1}
            >
              {header}
            </th>
          ))}
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>Price x Pax Count = Total</th>
          {ticketOnlySubHeaders.map((subHeader, index) => (
            <th key={`sub-${index}`}>{subHeader}</th>
          ))}
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="price-chart-row">
            <td>{item.pax} pax</td>
            <td>{item.privateBasis}</td>
            <td>
              <span className="text-muted">
                {item.sharingBasis} x {index + 1} ={" "}
              </span>
              {item.sharingBasis * (index + 1)}
            </td>
            <td>
              {item.ticketOnlyAdult !== undefined ? (
                <>
                  {item.ticketOnlyAdult}
                  <span className="text-muted"> / per pax</span>
                </>
              ) : (
                ""
              )}
            </td>
            <td>
              {item.ticketOnlyChild !== undefined ? (
                <>
                  {item.ticketOnlyChild}
                  <span className="text-muted"> / per pax</span>
                </>
              ) : (
                ""
              )}
            </td>
            <td>{item.privateBasisPriceSource}</td>
            <td> {item.sharingBasisPriceSource}</td>
            <td>{item.ticketOnlyPriceSource}</td>
            <td>{item.privateBasisRemark}</td>
            <td>{item.sharingBasisRemark}</td>
            <td>{item.ticketOnlyRemark}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PriceChart;
