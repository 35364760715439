import { toast } from "react-toastify";
import { TQuotationDataZod } from "../activitiescommonfnb/types/quotation.interface";
import { ITourWithPrice } from "../activitiescommonfnb/types/activitySearch.interface";
import { IUserData } from "../activitiescommonfnb/types/User.interface";

export const getQuotationDataTemplate = (
  id: number
): TQuotationDataZod & {
  staffId?: number;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  updatedAt?: Date;
} => ({
  enquiryId: id,
  quotationBody: "",
  title: "",
  visaCost: 0,
  cartItems: "",
  travelDate: new Date(),
  flightCost: 0,
  hotels: [
    {
      hotelId: null,
      checkIn: "",
      checkOut: "",
      paxConfigurationJson: [],
      roomCount: 0,
    },
  ],
  landPartCost: 0,
  markupPercentage: 0,
  showBreakup: true,
  internalRemark: "",
  HTMLData: "",
});

export const fields: Array<{
  name: keyof TQuotationDataZod;
  label: string;
  type?: string;
  required?: boolean;
}> = [
  { name: "title", label: "Title", required: true, type: "text" },
  { name: "visaCost", label: "Visa Cost", required: false, type: "number" },
  { name: "flightCost", label: "Flight Cost", required: false, type: "number" },
  {
    name: "landPartCost",
    label: "Land Part Cost",
    required: false,
    type: "number",
  },
];

export const tourSections = (tourDetail: ITourWithPrice) => [
  { title: "Whats In This Tour", content: tourDetail?.whatsInThisTour || "" },
  { title: "Tour Description", content: tourDetail?.tourDescription || "" },
  { title: "Inclusion", content: tourDetail?.tourInclusion || "" },
  {
    title: "Itinerary Description",
    content: tourDetail?.itenararyDescription || "",
  },
  {
    title: "Important Information",
    content: tourDetail?.importantInformation || "",
  },
];

export const radioOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const markupPercentageOptions = [
  { value: "", label: "Select", disabled: true },
  { value: 5, label: "5%" },
  { value: 10, label: "10%" },
  { value: 12, label: "12%" },
  { value: 15, label: "15%" },
  { value: 20, label: "20%" },
];

export const roomsOptions = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
];

export const mealPlanOptions = [
  { value: "Room Only", label: "Room Only" },
  { value: "CP (Bed & Breakfast)", label: "CP (Bed & Breakfast)" },
  { value: "MAP (Breakfast & Dinner)", label: "MAP (Breakfast & Dinner)" },
  {
    value: "AP (Breakfast & Lunch & Dinner)",
    label: "AP (Breakfast & Lunch & Dinner)",
  },
];
