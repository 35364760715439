import { z } from "zod";
import { ZodHelperService } from "../schemas/zodHelper.service";
import { IUserData } from "./User.interface";
import { Path } from "react-hook-form";
import { THotelZod } from "../schemas/hotel.schema";

export const quotationDataSchema = z.object({
  id: z.number().optional(),
  version: z.number().optional(),
  enquiryId: z.number(),
  cartItems: z.string().nullish(),
  quotationBody: z.string().min(1).trim(),
  title: z.string().min(1).trim(),
  visaCost: z.number().nullish(),
  travelDate: ZodHelperService.getZodDate(),
  flightCost: z.number().nullish(),
  hotels: z.array(
    z.object({
      hotelId: z.number().nullish(),
      checkIn: z.string().nullish(),
      checkOut: z.string().nullish(),
      roomCount: z.number().nullish(),
      paxConfigurationJson: z
        .array(
          z.object({
            roomId: z.number().nullish(),
            adultCount: z.number().nullish(),
            childCount: z.number().nullish(),
            cost: z.number().nullish(),
            mealPlan: z.string().nullish(),
          })
        )
        .nullish(),
    })
  ),
  landPartCost: z.number().nullish(),
  markupPercentage: z.number().min(1),
  showBreakup: z.boolean(),
  internalRemark: z.string().min(1).trim(),
  HTMLData: z.string().nullish(),
});

export type TQuotationDataZod = z.infer<typeof quotationDataSchema>;

export interface IQuotation extends TQuotationDataZod {
  staffId?: number;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  updatedAt?: Date;
}

export interface IQuotationVersion {
  id: number;
  quotationId: number;
  version: number;
  HTMLData: string;
  JsonData: any;
  cartItems: string | null;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITermsAndConditions {
  name: string;
  termsAndConditions: string;
}
export interface IQuotationTabs {
  id: string;
  title: string;
}

export interface IQuotationPreview {
  previewBody?: string;
  cartItems: string;
  quotationData?: IQuotation;
  versionId?: number;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  termsAndConditions?: ITermsAndConditions[];
}

export interface IQuotationDataTemplate extends TQuotationDataZod {
  staffId?: number;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  updatedAt?: Date;
}

export interface IQuotationFormRC {
  setQuotationData: React.Dispatch<React.SetStateAction<TQuotationDataZod>>;
  quotationData: TQuotationDataZod;
  quotationError?: { [key in Path<TQuotationDataZod>]: string } | null;
  hotelData: THotelZod[] | undefined;
}
